<template>
  <div>
    <div class="d-flex justify-center align-center">
      <div class="ml-2 mr-2">
        <v-card @click="openForTypeProposals('PARA DIGITAR')" class="mt-12" color="#019c50" style="width: 320px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
          <div style="padding: 16px">
            <div class="d-flex justify-center align-center mb-6">
              <div>
                <h3 style="color: #FFFFFF; font-size: 1.4em">Para Digitar</h3>
              </div>
            </div>
            <div class="mb-6" style="display: flex; justify-content: center">
              <div>
                <v-icon color="#FFFFFF" style="font-size: 9em">mdi-keyboard-variant</v-icon>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div class="ml-2 mr-2">
        <v-card @click="openOportunityProposals" class="mt-12" color="#019c50" style="width: 320px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
          <div style="padding: 16px">
            <div class="d-flex justify-center align-center mb-6">
              <div>
                <h3 style="color: #FFFFFF; font-size: 1.4em">Propostas Oportunidade</h3>
              </div>
            </div>
            <div class="mb-6" style="display: flex; justify-content: center">
              <div>
                <v-icon color="#FFFFFF" style="font-size: 9em">mdi-file-table-box-multiple-outline</v-icon>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div class="ml-2 mr-2">
        <v-card @click="openReversionProposals" class="mt-12" color="#019c50" style="width: 320px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
          <div style="padding: 16px">
            <div class="d-flex justify-center align-center mb-6">
              <div>
                <h3 style="color: #FFFFFF; font-size: 1.4em">Propostas Reversão</h3>
              </div>
            </div>
            <div class="mb-6" style="display: flex; justify-content: center">
              <div>
                <v-icon color="#FFFFFF" style="font-size: 9em">mdi-phone-return</v-icon>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FollowUpProposals",
  data: () => ({

  }),
  methods: {
    openForTypeProposals(value) {
      this.$router.push({name: 'listproposals', params: {'status': value}})
    },
    openOportunityProposals() {
      this.$router.push({name: 'listproposals', params: {'oportunity': 'true'}})
    },
    openReversionProposals() {
      this.$router.push({name: 'listproposals', params: {'reversion': 'true'}})
    },
  }
}
</script>

<style scoped>

</style>