<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
   <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
 <div v-else>
   <v-dialog
    v-model="dialogFilter"
    transition="dialog-top-transition"
    scrollable width="auto">
      <v-card class="rounded-xl">
        <v-toolbar elevation="0" color="#019c50">
          <v-card-text style="font-weight: bolder; font-size: 1.5em; color: #FFFFFF">Filtros:</v-card-text>
          <v-icon color="#FFFFFF" @click="cleanFilters">mdi-filter-off</v-icon>
        </v-toolbar>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div class="d-flex justify-center align-center">
              <div class="mt-7 ml-2" style="width: 300px;">
                <v-autocomplete dense :items="usersInstances" v-model="searchLogUser" label="Filtrar por Usuário" rounded outlined></v-autocomplete>
              </div>
              <div class="mt-7 ml-2" style="width: 300px;">
                <v-select dense :items="models" v-model="searchModel" label="Filtrar por Modelo" rounded outlined></v-select>
              </div>
              <div class="mt-7 ml-2" style="width: 300px;">
                <v-select dense :items="actions" v-model="searchAction" label="Filtrar por Ação" rounded outlined></v-select>
              </div>
            </div>
            <div class="d-flex justify-center align-center">
              <div class="mt-7 mr-10">
                <v-text-field dense rounded outlined v-model="searchId" label="Filtrar por Id"></v-text-field>
              </div>
              <div class="mt-7" style="width: 300px;">
                <v-text-field type="date" dense v-model="searchDateStart" label="Data de criação inicial" rounded outlined></v-text-field>
                <v-text-field type="date" dense v-model="searchDateEnd" label="Data de criação final" rounded outlined></v-text-field>
              </div>
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="getLogs">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mt-5">
      <StandardTitle :labeltitle="'LOGS'"></StandardTitle>
    </div>
    <div class="d-flex flex-column" style="width: 100%">
      <div class="d-flex justify-end align-center mt-7 mb-2 mr-6" >
        <span style="font-size: 0.8em; font-weight: bold">Filtros: </span>
        <v-icon color="#019c50" @click="dialogFilter = true">
          mdi-filter-menu
        </v-icon>
      </div>
      <div style="display: flex; justify-content: center;">
        <v-data-table
          style="width: 97%"
          :headers="headers"
          :items="logs"
          hide-default-footer
          disable-pagination
          @click:row="openLog"
          class="elevation-4 rounded-xl pa-2"
        >
        </v-data-table>
      </div>
      <div class="d-flex mb-8">
        <div style="width: 100%; display: flex; justify-content: flex-start;">
          <div class="mt-2 ml-6">
            <v-btn color="#019c50" style="color: #FFFFFF; text-transform: none" rounded v-if="previousPage != null" class="btn-success" @click="djangoPagination = djangoPagination - 1">
              Página Anterior
            </v-btn>
          </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: center;">
          <div class="mt-2">
            <v-btn color="#019c50" style="color: #FFFFFF; text-transform: none" rounded class="btn-success">
              {{ countClients }}
            </v-btn>
          </div>
        </div>
        <div style="width: 100%; display: flex; justify-content: flex-end;">
          <div class="mt-2 mr-6">
            <v-btn color="#019c50" style="color: #FFFFFF; text-transform: none" rounded class="btn-danger ml-2" v-if="nextPage != null" @click="djangoPagination = djangoPagination + 1">
              Próxima Página
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "ListLogs",
  components: {StandardTitle},
  data: () => ({
    pageLoading: true,
    headers: [
      {text: 'USUÁRIO', value: 'user.account_identification', sortable: false},
      {text: 'MODEL', value: 'model_reference', sortable: false},
      {text: 'AÇÃO', value: 'action', sortable: false},
      {text: 'ID OBJETO', value: 'object_id', sortable: false},
      {text: 'OBSERVAÇÃO', value: 'observation', sortable: false},
      {text: 'DATA DE CRIAÇÃO', value: 'created_at', sortable: false},
    ],
    searchId: '',
    searchLogUser: '',
    searchModel: '',
    models: ['Proposta', 'Cliente', 'Atendimento', 'Benefício', 'Informações Comuns'],
    searchAction: '',
    actions: ['ALTEROU', 'CRIOU'],
    searchDateStart: '',
    searchDateEnd: '',
    logs: [],
    usersInstances: [],
    dialogFilter: false,
    loading: false,
    previousPage: null,
    djangoPagination: 1,
    countClients: undefined,
    nextPage: null
  }),
  methods: {
    getUsers() {
      this.$http.get('contaswebcred/').then(v => {
        for (let i in v.data) {
          this.usersInstances.push(v.data[i].account_identification)
        }
      })
    },
    getLogs() {
      this.loading = true
      this.pageLoading = true
      let createDateParams = {};
      createDateParams.start_date = this.searchDateStart
      createDateParams.end_date = this.searchDateEnd
      this.$http.get(`logs/?page=${this.djangoPagination}&user=${this.searchLogUser}&model=${this.searchModel}&action=${this.searchAction}&create_date=${JSON.stringify(createDateParams)}&object_id=${this.searchId}`).then(v => {
        this.countClients = v.data.count
        this.nextPage = v.data.next
        this.previousPage = v.data.previous
        this.logs = v.data.results
        this.logs.map(v => {
          v.created_at = this.fixDataTimeInformation(v.created_at)
          return v
        })
        this.loading = false
        this.dialogFilter = false
        this.pageLoading = false
      })
    },
    cleanFilters() {
      this.searchLogUser  = ''
      this.searchModel  = ''
      this.searchAction  = ''
      this.searchDateStart  = ''
      this.searchDateEnd  = ''
      this.searchId = ''
      this.djangoPagination = 1
    },
    fixDataTimeInformation(dateTimeObj) {
      let day = dateTimeObj.slice(8, 10)
      let month = dateTimeObj.slice(5, 7)
      let year = dateTimeObj.slice(0, 4)
      let time = dateTimeObj.slice(11, 16)
      return day + '/' + month + '/' + year + ' às: ' + time
    },
    openLog(value) {
      this.$router.push({name: 'log', params: {id: value.id}})
    }
  },
  created() {
    this.getLogs()
    this.getUsers()
  },
  watch: {
    djangoPagination() {
      this.getLogs()
    },
    dialogFilter() {
      if (!this.dialogFilter) {
        this.djangoPagination = 1
      }
    },
  }
}
</script>

<style scoped>

</style>