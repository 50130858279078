<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
    <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else>
    <div class="d-flex justify-end mt-2 mr-2">
      <div class="plan pa-2" v-if="plan"
           style="border-radius: 20px; border: 1px solid rgba(0, 0, 0, 0.3); background-color: #019c50;
    color: #FFFFFF;">
        <div class="d-flex justify-space-between align-center">
          <span class="plan-title mr-3">Seu Plano:</span>
          <span class="plan-sub">{{plan.plan}}</span>
        </div>
        <div class="d-flex justify-space-between align-center">
          <span class="plan-title">Mensalidade: </span>
          <span class="plan-sub">R$ {{plan.price | fixValues}}</span>
        </div>
        <div class="d-flex justify-space-between align-center">
          <span class="plan-title mr-3">Limite de Usuários: </span>
          <span class="plan-sub">{{ plan.users_count === 1000 ? 'Sem limites' : plan.users_count + ' usuários'}}</span>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <StandardTitle :labeltitle="'Minhas Faturas'"></StandardTitle>
    </div>
    <div v-if="invoices.length > 0" class="d-flex justify-center jus align-center flex-column">
      <div v-for="item in invoices" :key="item.id">
        <v-card class="mt-7" rounded="xl" min-width="450">
          <v-card-title class="d-flex justify-center" style="background-color: #019c50; line-height: 1">
            <span style="font-size: 0.9em; color: #FFFFFF">Fatura: {{ item.id }}</span>
          </v-card-title>
          <v-card-text class="mt-3">
            <div>
              <span>Mês de referência: </span>
              <span>{{item.start_reference_date | shortDate}}</span>
            </div>
            <div>
              <span>Data de Vencimento: </span>
              <span>{{item.due_date | fixDate}}</span>
            </div>
            <div>
              <span>Valor: </span>
              <span>R$ {{item.amount | fixValues}}</span>
            </div>
            <div>
              <span>Status: </span>
              <span v-if="item.paid">Pago</span>
              <span v-else>{{ moment() > moment(item.due_date) ? 'Vencida' : 'Em aberto' }}</span>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-end" style="background-color: #c0d2cc">
            <div>
              <span class="mr-3" style="font-size: 0.8em; color: mediumblue" @click="openInvoiceObject(item.id)">Ver Fatura</span>
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-center align-center pa-4">
        <h3>Ainda não possui faturas disponíveis.</h3>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";
import moment from "moment";

export default {
  name: "ListInvoices",
  computed: {
    moment() {
      return moment
    }
  },
  components: {StandardTitle},
  data: () => ({
    invoices: [],
    plan: '',
    pageLoading: true
  }),
  methods: {
    getPlano() {
      const partnerId = this.$store.getters['getPartnerId']
      this.$http.get(`parceiros/${partnerId}/?plan=true`).then(val => {
        this.plan = val.data.plan
      })
    },
    openInvoiceObject(value) {
      this.$router.push({name: 'invoice', params: {id: value}})
    },
    getFaturas() {
      this.$http.get('faturas/').then(val => {
        this.invoices = val.data
      })
    }
  },
  async created() {
    await this.getFaturas()
    await this.getPlano()
    this.pageLoading = false
  }
}
</script>

<style scoped>
.plan-title {
  font-weight: bolder;
}
.plan-sub {
  font-size: 0.8em;
  opacity: 80%;
}
</style>