<template>
  <keep-alive max="5">
    <router-view/>
  </keep-alive>
</template>

<script>
export default {
  name: "ViewLoanProposals"
}
</script>

<style scoped>

</style>