<template>
  <div>
    <div class="mt-10 mb-10">
      <StandardTitle :labeltitle=labelTitle ></StandardTitle>
    </div>
    <div class="body-page mt-6 mb-6">
      <CreateClient></CreateClient>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle";
import CreateClient from "@/components/clients/CreateClient.vue";
export default {
  name: "AddClient",
  components: {CreateClient, StandardTitle },
  data: () => ({
    labelTitle: 'Cadastrar Clientes'
  }),
}
</script>

<style scoped>
</style>