<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
   <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else id="main">
    <v-dialog v-model="filterDialog"
    transition="dialog-bottom-transition"
    >
      <v-card class="rounded-xl">
        <v-toolbar elevation="0" color="#019c50">
          <v-card-text style="font-weight: bolder; font-size: 1.5em; color: #FFFFFF">Filtros:</v-card-text>
          <v-icon color="#FFFFFF" @click="cleanFilters">mdi-filter-off</v-icon>
        </v-toolbar>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div class="d-flex justify-center align-center">
              <div class="filter-fields mt-4">
                <div class="d-flex justify-center align-center">
                  <v-text-field class="mr-2" type="date" dense label="Data da Venda Inicial" v-model.lazy="searchForSaleDateInitial" rounded outlined></v-text-field>
                  <v-text-field type="date" dense label="Data de Venda Final" v-model="searchForSaleDateEnding" rounded outlined></v-text-field>
                </div>
                <div class="d-flex justify-center align-center">
                  <v-text-field class="mr-2" type="date" dense label="Data da Pgto Inicial" v-model.lazy="searchForPaymentDateInitial" rounded outlined></v-text-field>
                  <v-text-field type="date" dense label="Data de Pgto Final" v-model="searchForPaymentDateEnding" rounded outlined></v-text-field>
                </div>
                <div class="d-flex justify-center align-center">
                  <v-text-field class="mr-2" type="date" dense label="Completa 90 d. Inicial: " v-model="searchFor90DaysAtInitial" rounded outlined></v-text-field>
                  <v-text-field type="date" dense label="Completa 90 d. Final: " v-model="searchFor90DaysAtEnding" rounded outlined></v-text-field>
                </div>
                <div class="d-flex justify-center align-center">
                  <v-text-field dense label="Origem" v-model="searchForOrigin" rounded outlined></v-text-field>
                  <v-switch style="margin-top: -7px; margin-left: 9px" v-model="isCallCenter" inset color="rgba(1, 156, 80, 1)"></v-switch>
                </div>
                <div class="d-flex justify-center align-center">
                  <v-text-field dense label="Rentabilidade" v-model="searchForRentability" rounded outlined></v-text-field>
                  <v-switch style="margin-top: -7px; margin-left: 9px" v-model="hasRentability" inset color="rgba(1, 156, 80, 1)"></v-switch>
                  <v-icon style="margin-top: -24px; margin-left: -11px;" size="28" color="#019c50" @click="hasRentability = ''">mdi-cancel</v-icon>
                </div>
                <div class="d-flex justify-center align-center">
                  <v-text-field dense label="Comissão" v-model="searchForCommission" rounded outlined></v-text-field>
                  <v-switch style="margin-top: -7px; margin-left: 9px" v-model="hasCommission" inset color="rgba(1, 156, 80, 1)"></v-switch>
                  <v-icon style="margin-top: -24px; margin-left: -11px;" size="28" color="#019c50" @click="hasCommission = ''">mdi-cancel</v-icon>
                </div>
                <div class="d-flex justify-center align-center">
                  <v-text-field dense label="Cupons Entregues?" v-model="searchForCoupons" rounded outlined></v-text-field>
                  <v-switch style="margin-top: -7px; margin-left: 9px" v-model="deliveredCoupons" inset color="rgba(1, 156, 80, 1)"></v-switch>
                  <v-icon style="margin-top: -24px; margin-left: -11px;" size="28" color="#019c50" @click="deliveredCoupons = ''">mdi-cancel</v-icon>
                </div>
              </div>
              <div class="filter-fields mt-4">
                <v-text-field dense label="Usuário" v-model="searchForUser" rounded outlined></v-text-field>
                <v-text-field dense label="Cliente" v-model="searchForClientName" rounded outlined></v-text-field>
                <v-text-field dense v-maska
                            data-maska="###.###.###-##" label="CPF" v-model="searchForCpf" rounded outlined></v-text-field>
                <v-autocomplete dense label="Produto" v-model="searchForProduct" :items="loanProducts" rounded outlined></v-autocomplete>
                <v-combobox dense label="Promotora" v-model="searchForPromoter" :items="promoters" rounded outlined></v-combobox>
              </div>
              <div class="filter-fields mt-4">
                <v-text-field dense label="ADE" v-model="searchForAde" rounded outlined></v-text-field>
                <v-text-field dense label="Banco" v-model="searchForBank" rounded outlined></v-text-field>
                <v-combobox dense label="Status" v-model="searchForStatus" :items="statusItems" rounded outlined></v-combobox>
                <v-combobox dense label="Situação" v-model="searchForSituation" :items="arraySituationTypes" rounded outlined></v-combobox>
                <v-text-field dense label="Telefone" v-model="searchForPhone" rounded outlined></v-text-field>
              </div>
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="getProposals">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="block-buttons">
      <div class="export buttons-add">
        <v-btn @click="exportResults" rounded color="#019c50" style="color: #FFFFFF; text-transform: none">Exportar</v-btn>
        <v-progress-linear class="mt-1" style="width: 86px; height: 5px; margin-left: 5px" v-if="dialogExport" color="#019c50" indeterminate></v-progress-linear>
      </div>
      <div class="buttons-add">
        <v-btn color="#019c50" @click="addProposal" style="color: #FFFFFF; text-transform: none" rounded>
          <div>
            <span>Adicionar</span>
          </div>
          <div>
            <v-icon class="ml-2" style="font-size: 2em; color: #FFFFFF">mdi-account-plus-outline</v-icon>
          </div>
        </v-btn>
      </div>
      <div class="buttons-add">
        <v-btn color="#019c50" @click="addProposalExcel" rounded style="color: #FFFFFF; text-transform: none">
          <div>
            <span>Adicionar de arquivo</span>
          </div>
          <div>
            <v-icon class="ml-2" style="font-size: 2em; color: #FFFFFF">mdi-account-plus-outline</v-icon>
          </div>
        </v-btn>
      </div>
    </div>
    <div class="mt-1 mb-5">
      <StandardTitle :labeltitle="'Propostas'"></StandardTitle>
    </div>
    <div class="order-filter-sector">
      <div class="order">
        <span style="font-size: 0.8em; font-weight: bold">Ordenar por: </span>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="#019c50" rounded small style="color: #FFFFFF; text-transform: none" v-on="on">{{ orderTerm }}</v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" @click="changeOrder(index)" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon size="33" @click="selectedOrder = !selectedOrder" style="margin-top: 1px;
          margin-left: 3px; fill: #019c50 !important;">{{ iconOrder }}
        </v-icon>
      </div>
      <div class="filter">
        <span style="font-size: 0.8em; font-weight: bold">Filtros: </span>
        <v-icon color="#019c50" @click="filterDialog = true">
          mdi-filter-menu
        </v-icon>
      </div>
    </div>
    <div class="ml-2 mr-2 mt-2" >
      <v-data-table
        :headers="headers"
        :items="proposals"
        :items-per-page="10"
        hide-default-footer
        item-key="id"
        class="elevation-4 rounded-xl">
        <template v-slot:[`item`]="{ item }">
          <tr :class="getRowClass(item)" @click="openProposal(item)" :style="styleFirstLine">
            <td v-if="searchForStatus === 'PAGO' && searchForSituation === 'CONCLUÍDO'">{{ item.payment_day }}</td>
            <td v-else>{{ item.sale_date }}</td>
            <td>{{ item.is_call_center }}</td>
            <td>{{ item.loan_product }}</td>
            <td>{{ item.user.account_identification }}</td>
            <td>{{ item.client.name }}</td>
            <td>{{ item.client.cpf }}</td>
            <td>{{ item.ade }}</td>
            <td>{{ item.bank ? item.bank.short_name : '' }}</td>
            <td>{{ item.net_value }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.situation }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div style="display: flex">
      <div style="width: 100%; display: flex; justify-content: flex-start; padding: 10px">
        <div>
          <v-btn class="btn-sucess" color="#019c50" style="color: #FFFFFF; text-transform: none" rounded v-if="previousPage != null" @click="djangoPagination = djangoPagination - 1">
            Página Anterior
          </v-btn>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: center; padding: 10px">
        <span v-if="pages.length > 5" style="font-size: 0.7em; font-weight: bold; margin-right: 5px; margin-top: 8px">páginas: </span>
        <div v-if="pages.length > 5" class="d-flex">
          <div class="pages">
            <div @click="djangoPagination = 1;" :style="getPageFontSize(1)" class="pages-buttons mr-1">
              {{ 1 }}
            </div>
          </div>
          <div v-for="page in range(djangoPagination === pages.length ? pages.length - 6 : djangoPagination , djangoPagination === pages.length ? pages.length - 1 : djangoPagination + 6)" :key="page" class="pages">
            <div v-if="page > 1 && page < pages.length" @click="djangoPagination = page;" :style="getPageFontSize(page)" class="pages-buttons mr-1">
              {{ page }}
            </div>
          </div>
          <div class="pages">
            <div @click="djangoPagination = pages.length;" :style="getPageFontSize(pages.length)" class="pages-buttons mr-1 ml-1">
              {{ pages.length }}
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="page in range(djangoPagination, djangoPagination / 10)" :key="page" class="pages">
            <div @click="djangoPagination = page;" :style="getPageFontSize(page)" class="pages-buttons mr-1">
              {{ page }}
            </div>
          </div>
        </div>
        <div class="mr-2 ml-2">
          <v-btn class="btn-sucess" elevation="1" color="#FFFFFF" small style="color: #019c50; margin-top: 3px; font-weight: 900" rounded v-if="proposalCount">
            {{ proposalCount }}
          </v-btn>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: flex-end; padding: 10px">
        <div v-if="amountProposals !== 0" class="mr-3 button-amount">
          <v-btn class="btn-danger" elevation="1" color="#FFFFFF" rounded small style="color: #019c50; margin-top: 3px; font-weight: 900">
            {{ amountProposals }}
          </v-btn>
        </div>
        <div>
          <v-btn class="btn-danger" color="#019c50" style="color: #FFFFFF; text-transform: none" rounded v-if="nextPage != null" @click="djangoPagination = djangoPagination + 1">
            Próxima Página
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle";
import VueCookies from "vue-cookies";

export default {
  name: "ListLoanProposal",
  components: { StandardTitle },
  props: ['status', 'coupons_flag'],
  data: () => ({
    items: [
        { title: 'Data da Venda' },
        { title: 'Data de Pagamento' },
        { title: 'Cliente' },
        { title: 'Banco' },
      ],
    orderTerm: 'Data da Venda',
    djangoPagination: 1,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [],
    proposals: [],
    nextPage: null,
    previousPage: null,
    searchForCpf: '',
    searchForAde: '',
    searchForPhone: '',
    searchForStatus: '',
    filterDialog: false,
    searchForSaleDateInitial: '',
    searchForSaleDateEnding: '',
    searchForPaymentDateInitial: '',
    searchForPaymentDateEnding: '',
    searchFor90DaysAtInitial: '',
    searchFor90DaysAtEnding: '',
    searchForBank: '',
    searchForSituation: '',
    statusItems: [],
    situationItems: [],
    loanProducts: [
      'AUMENTO DE SALÁRIO', 'CARTÃO BENEFÍCIO COM SAQUE', 'CARTÃO BENEFÍCIO SEM SAQUE', 'CARTÃO COM SAQUE',
      'CARTÃO SEM SAQUE', 'CARTÃO DE CRÉDITO', 'CRÉDITO PESSOAL', 'ENERGIA', 'FGTS', 'NOVO', 'OPORTUNIDADE NOVO',
      'OPORTUNIDADE CARTÃO', 'OPORTUNIDADE CARTÃO COM SAQUE', 'PORTABILIDADE+REFIN', 'PORTABILIDADE ATAQUE', 'PORTABILIDADE PURA', 'REFIN DE PORTABILIDADE', 'REFIN DE PORT ZERADO',
      'REFINANCIAMENTO', 'REFINANCIAMENTO+AGREGRAÇÃO', 'REVERSÃO NOVO', 'REVERSÃO CARTÃO', 'REVERSÃO CARTÃO COM SAQUE',
      'SAQUE COMPLEMENTAR', 'SAQUE COMPLEMENTAR BENEFÍCIO'
    ],
    isCallCenter: null,
    searchForUser: '',
    searchForClientName: '',
    searchForProduct: '',
    searchFor90DaysAt: '',
    hasRentability: '',
    hasCommission: '',
    deliveredCoupons: '',
    clickCount: 0,
    clickTimeout: null,
    proposalCount: false,
    vendido: [],
    digitado: [],
    formalizacaoRecebida: [],
    emAndamento: [],
    pago: [],
    cancelado: [],
    redigitar: [],
    searchForPromoter: '',
    promoters: [],
    amountProposals: 0,
    selectedOrder: true,
    pages: 0,
    dialogExport: false,
    coupons: false,
    pageLoading: true
  }),
  computed: {
    iconOrder() {
      return this.selectedOrder ? '$arrow_up_animated' : '$arrow_down_animated'
    },
    searchForOrigin() {
      let origin = ''
      if (this.isCallCenter === true) {
        origin = 'CALL CENTER'
      } else if (this.isCallCenter === false) {
        origin = 'LOJA'
      }
      return origin
    },
    searchForRentability() {
      let rentability = ''
      if (this.hasRentability === true) {
        rentability = 'SIM'
      } else if (this.hasRentability === false) {
        rentability = 'NÃO'
      }
      return rentability
    },
    searchForCommission() {
      let commission = ''
      if (this.hasCommission === true) {
        commission = 'SIM'
      } else if (this.hasCommission === false) {
        commission = 'NÃO'
      }
      return commission
    },
    searchForCoupons() {
      let coupons = ''
      if (this.deliveredCoupons === true) {
        coupons = 'SIM'
      } else if (this.deliveredCoupons === false) {
        coupons = 'NÃO'
      }
      return coupons
    },
    arraySituationTypes() {
      let arraySituations = null
      for (let i in this.statusItems) {
        if (this.searchForStatus === this.statusItems[i]) {
          arraySituations = this.situationItems[i]
        }
      }
      return arraySituations
    }
  },
  methods: {
    async exportResults() {
      this.dialogExport = true
      let saleDateParams = {}
      saleDateParams.sale_date_initial = this.searchForSaleDateInitial
      saleDateParams.sale_date_ending = this.searchForSaleDateEnding
      let paymentDateParams = {}
      paymentDateParams.payment_date_initial = this.searchForPaymentDateInitial
      paymentDateParams.payment_date_ending = this.searchForPaymentDateEnding
      let ninetyDaysParams = {}
      ninetyDaysParams.ninety_days_initial = this.searchFor90DaysAtInitial
      ninetyDaysParams.ninety_days_ending = this.searchFor90DaysAtEnding
      let orderParams = {}
      orderParams.order_by = this.orderTerm
      orderParams.desc = this.selectedOrder
      await this.$http.get(`propostas/export_results_view/?cpf=${this.searchForCpf}&phone=${this.searchForPhone}&status=${this.searchForStatus}&ade=${this.searchForAde}&name=${this.searchForClientName}&sale_date=${JSON.stringify(saleDateParams)}&bank=${this.searchForBank}&situation=${this.searchForSituation}&user=${this.searchForUser}&origin=${this.searchForOrigin}&product=${this.searchForProduct}&ninety_days_at=${JSON.stringify(ninetyDaysParams)}&has_rentability=${this.searchForRentability}&payment_date=${JSON.stringify(paymentDateParams)}&promoter=${this.searchForPromoter}&has_commission=${this.searchForCommission}&coupon_delivered=${this.searchForCoupons}&order=${JSON.stringify(orderParams)}&coupons=${this.coupons}`, { responseType: 'arraybuffer' }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'propostas.xlsx');
          document.body.appendChild(link);
          link.click();
          this.dialogExport = false
        })
        .catch(error => {
          console.error('Export error:', error);
        });
    },
    getPageFontSize(page) {
      if (page === this.djangoPagination){
        return 'font-size: 1em; padding: 1px 9px 1px 9px;'
      }
      return 'font-size: 0.7em; padding: 1px 6px 1px 6px;'
    },
    changeOrder(value) {
      this.orderTerm = this.items[value].title
    },
    async getProposals() {
      this.headers = []
      this.amountProposals = 0
      this.pages = 0
      this.pageLoading = true
      this.filterDialog = false
      if (this.searchForStatus === 'PAGO' && this.searchForSituation === 'CONCLUÍDO') {
        this.headers = [
            { text: 'DATA DE PAGAMENTO', value: 'payment_day', sortable: false },
            { text: 'ORIGEM', value: 'is_call_center', sortable: false },
            { text: 'PRODUTO', value: 'loan_product', sortable: false },
            { text: 'USUÁRIO', value: 'user.account_identification', sortable: false },
            { text: 'CLIENTE', value: 'client.name', sortable: false },
            { text: 'CPF', value: 'client.cpf', sortable: false },
            { text: 'ADE', value: 'ade', sortable: false },
            { text: 'BANCO', value: 'bank.short_name' , sortable: false },
            { text: 'VALOR', value: 'net_value', sortable: false },
            { text: 'STATUS', value: 'status', sortable: false },
            { text: 'SITUAÇÃO', value: 'situation', sortable: false }
          ]
      } else {
        this.headers = [
            { text: 'DATA DA VENDA', value: 'sale_date', sortable: false },
            { text: 'ORIGEM', value: 'is_call_center', sortable: false },
            { text: 'PRODUTO', value: 'loan_product', sortable: false },
            { text: 'USUÁRIO', value: 'user.account_identification', sortable: false },
            { text: 'CLIENTE', value: 'client.name', sortable: false },
            { text: 'CPF', value: 'client.cpf', sortable: false },
            { text: 'ADE', value: 'ade', sortable: false },
            { text: 'BANCO', value: 'bank.short_name' , sortable: false },
            { text: 'VALOR', value: 'net_value', sortable: false },
            { text: 'STATUS', value: 'status', sortable: false },
            { text: 'SITUAÇÃO', value: 'situation', sortable: false }
          ]
      }
      let saleDateParams = {}
      saleDateParams.sale_date_initial = this.searchForSaleDateInitial
      saleDateParams.sale_date_ending = this.searchForSaleDateEnding
      let paymentDateParams = {}
      paymentDateParams.payment_date_initial = this.searchForPaymentDateInitial
      paymentDateParams.payment_date_ending = this.searchForPaymentDateEnding
      let ninetyDaysParams = {}
      ninetyDaysParams.ninety_days_initial = this.searchFor90DaysAtInitial
      ninetyDaysParams.ninety_days_ending = this.searchFor90DaysAtEnding
      let orderParams = {}
      orderParams.order_by = this.orderTerm
      orderParams.desc = this.selectedOrder
      await this.$http.get(
          `propostas/?page=${this.djangoPagination}&cpf=${this.searchForCpf}&phone=${this.searchForPhone}&status=${this.searchForStatus}&ade=${this.searchForAde}&name=${this.searchForClientName}&sale_date=${JSON.stringify(saleDateParams)}&bank=${this.searchForBank}&situation=${this.searchForSituation}&user=${this.searchForUser}&origin=${this.searchForOrigin}&product=${this.searchForProduct}&ninety_days_at=${JSON.stringify(ninetyDaysParams)}&has_rentability=${this.searchForRentability}&payment_date=${JSON.stringify(paymentDateParams)}&promoter=${this.searchForPromoter}&has_commission=${this.searchForCommission}&coupon_delivered=${this.searchForCoupons}&order=${JSON.stringify(orderParams)}&coupons=${this.coupons}`
      ).then(
        v => {
          this.nextPage = v.data.proposals.next
          this.previousPage = v.data.proposals.previous
          this.proposals = v.data.proposals.results
          this.proposalCount = v.data.proposals.count
          let end = this.proposalCount / 10
          this.pages = this.range(1, end)
          if (v.data.total_amount !== null) {
            this.amountProposals = this.fixAmountValues(v.data.total_amount)
          }
          
          this.proposals.map(v => {
          v.sale_date = this.fixDataTimeInformation(v.sale_date)
          if (v.payment_day) {
            v.payment_day = this.fixDataTimeInformation(v.payment_day)
          }
          v.is_call_center = v.is_call_center ? 'CALL CENTER' : 'LOJA'
          return v
          })
          this.pageLoading = false
        }
      )
    },
    range(start, end, step = 1) {
      return Array.from({ length: Math.floor((end - start) / step) + 1 }, (_, i) => start + i * step);
    },
    fixAmountValues(value) {
      if (value === 0) {
        return 'R$ 0.00'
      }
      let newValue = String(value)
      newValue = newValue.replace(/\./g, '');
      let lenghValue = newValue.length
      let returnValue = ''
      if (lenghValue === 3) {
        returnValue = `R$ ${String(newValue).slice(0, 1)}.${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 4) {
        returnValue = `R$ ${String(newValue).slice(0, 2)}.${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 5) {
        returnValue = `R$ ${String(newValue).slice(0, 3)}.${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 6) {
        returnValue = `R$ ${String(newValue).slice(0, 1)}.${String(newValue).slice(1, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 7) {
        returnValue = `R$ ${String(newValue).slice(0, 2)}.${String(newValue).slice(2, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 8) {
        returnValue = `R$ ${String(newValue).slice(0, 3)}.${String(newValue).slice(3, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 9) {
        returnValue = `R$ ${String(newValue).slice(0, 1)}.${String(newValue).slice(1, 4)}.${String(newValue).slice(4, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 10) {
        returnValue = `R$ ${String(newValue).slice(0, 2)}.${String(newValue).slice(2, 5)}.${String(newValue).slice(5, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      return returnValue
    },
    cleanFilters() {
      this.searchForCpf = ''
      this.searchForAde = ''
      this.searchForPhone = ''
      this.searchForStatus = ''
      this.searchForSaleDateInitial = ''
      this.searchForSaleDateEnding = ''
      this.searchForBank = ''
      this.searchForSituation = ''
      this.searchForUser = ''
      this.searchForClientName = ''
      this.isCallCenter = null
      this.hasCommission = ''
      this.hasRentability = ''
      this.deliveredCoupons = ''
      this.searchForProduct = ''
      this.searchFor90DaysAt = ''
      this.searchForPaymentDateInitial = ''
      this.searchForPaymentDateEnding = ''
      this.searchFor90DaysAtInitial = ''
      this.searchFor90DaysAtEnding = ''
      this.searchForPromoter = ''
      this.filterDialog = false
      this.getProposals()
    },
    getRowClass(item) {
      let classRow = '';
      if (item.payment_day) {
        let current_date = new Date();
        let expiration_date = new Date(item.deadline_date_commission)
        if (item.commission) {
          classRow = 'row-style-green'
          this.styleFirstLine = 'color: #019c50; font-weight: 900;'
        } else {
          if (current_date <= expiration_date) {
            classRow = 'row-style-yellow'
            this.styleFirstLine = 'color: #FFA500; font-weight: 900;'
          } else {
            classRow = 'row-style-red'
            this.styleFirstLine = 'color: #FF0000; font-weight: 900;'
          }
        }
      } else {
        this.styleFirstLine = ''
      }
      return classRow
    },
    getPromoters() {
      this.$http.get('promotoras').then((v) => {
        for (let i in v.data) {
          this.promoters.push(v.data[i].promotora)
        }
      })
    },
    getStatusTypes() {
      this.$http.get('status/').then(
          (v) => {
            for (let i in v.data) {
                this.statusItems.push(v.data[i].status)
            }
            this.getSituationTypes()
          }
      )
    },
    getSituationTypes() {
      this.$http.get('situacoes/').then(
          (s) => {
            for (let i in s.data) {
              if (s.data[i].status === 'VENDIDO') {
                this.vendido.push(s.data[i].situation)
              }
              if (s.data[i].status === 'DIGITADO') {
                this.digitado.push(s.data[i].situation)
              }
              if (s.data[i].status === 'FORMALIZAÇÃO RECEBIDA') {
                this.formalizacaoRecebida.push(s.data[i].situation)
              }
              if (s.data[i].status === 'EM ANDAMENTO') {
                this.emAndamento.push(s.data[i].situation)
              }
              if (s.data[i].status === 'PAGO') {
                this.pago.push(s.data[i].situation)
              }
              if (s.data[i].status === 'CANCELADO') {
                this.cancelado.push(s.data[i].situation)
              }
              if (s.data[i].status === 'REDIGITAR') {
                this.redigitar.push(s.data[i].situation)
              }
            }
            this.situationItems.push(this.formalizacaoRecebida)
            this.situationItems.push(this.emAndamento)
            this.situationItems.push(this.redigitar)
            this.situationItems.push(this.vendido)
            this.situationItems.push(this.digitado)
            this.situationItems.push(this.pago)
            this.situationItems.push(this.cancelado)
        })
    },
    fixDataTimeInformation(dateTimeObj) {
      let day = dateTimeObj.slice(8, 10)
      let month = dateTimeObj.slice(5, 7)
      let year = dateTimeObj.slice(0, 4)
      return day + '/' + month + '/' + year
    },
    openProposal(value) {
      this.clickCount++;
      clearTimeout(this.clickTimeout);
      if (this.clickCount === 2) {
        this.$router.push({name: 'show_proposal', params: {'proposal': value}})
        this.clickCount = 0
      } else {
        this.clickTimeout = setTimeout(() => {
          this.clickCount = 0;
        }, 1000);
      }
    },
    addProposal() {
      this.$router.push({name: 'addproposal'})
    },
    addProposalExcel() {
      this.$router.push({name: 'excelimport'})
    }
  },
  created() {
    this.getStatusTypes()
    this.getPromoters()
    if (this.coupons_flag) {
      VueCookies.set('coupons', true, (60 * 60 * 24) * 7)
    } else {
      VueCookies.set('coupons', false, (60 * 60 * 24) * 7)
    }
  },
  activated() {
    VueCookies.get('coupons') === 'true' ? this.coupons = true : this.coupons = false
    this.getProposals()
  },
  watch: {
    djangoPagination() {
      this.getProposals()
    },
    filterDialog() {
      if (!this.filterDialog) {
        this.djangoPagination = 1
      }
    },
    orderTerm() {
      this.getProposals()
    },
    selectedOrder() {
      this.getProposals()
    }
  }
}
</script>

<style scoped>
.block-buttons {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  margin-right: 10px;
  margin-top: 10px;
}
.buttons-add {
  padding: 10px;
}
.filter-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-bottom: -20px;
}

.order-filter-sector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter {
  margin-left: 15px;
  margin-right: 10px;
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
}

.pages-buttons {
  background-color: #019c50;
  color: #FFFFFF;
  margin-top: 2px;
  font-weight: 900;
  border-radius: 50px;
}

</style>