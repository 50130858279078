<template>
  <div>
    <v-dialog v-model="dialog"
    transition="dialog-top-transition"
    scrollable width="auto"
    >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="dialog =false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar elevation="4" outlined color="#019c50">
      <v-app-bar-nav-icon v-if="$route.name === 'menu'" color="#FFFFFF" @click="drawer = true"></v-app-bar-nav-icon>
      <v-icon @click="$router.go(-1)" class="mr-8" v-else color="#FFFFFF">mdi-arrow-left-circle</v-icon>
      <v-spacer></v-spacer>
      <div>
        <span style="font-size: 2em; font-weight: bolder; color: white;">WebCred</span>
      </div>
      <v-spacer></v-spacer>
      <div v-if="user !== ''" class="d-flex justify-center align-center" style="position: absolute; right: 4%">
        <div class=" ml-3">
          <v-img class="rounded-circle" :src="userPhoto"
            height="40"
            width="40"></v-img>
        </div>
        <div class="mb-1 ml-2 mr-3">
          <v-toolbar-title style="color: white">{{ user }}</v-toolbar-title>
        </div>
      </div>
      <v-btn v-on:click="logout" icon>
        <v-icon color="#FFFFFF">mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer class="rounded-r-xl"
                         v-model="drawer"

                         absolute
                         temporary
    >
        <v-list nav>
        <v-list-item-group v-model="group" active-class="deep-orange--text text--accent-4">
          <div class="drawer-item">
            <div>
              <v-list-item @click="drawer = false" style="margin-top: 10px;">
                <v-icon color="#019c50">mdi-window-close</v-icon>
              </v-list-item>
            </div>
          </div>
          <div class="drawer-items">
            <div>
              <v-list-item @click="openDashboard">
                <v-list-item-icon>
                  <v-icon color="#019c50">mdi-monitor-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-title color="black">Dashboard</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openClients">
                <v-list-item-icon>
                  <v-icon color="#019c50">mdi-phone-forward-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title color="black">Ativo</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openProposals">
                <v-list-item-icon>
                  <v-icon color="#019c50">mdi-currency-usd</v-icon>
                </v-list-item-icon>
                <v-list-item-title color="black">Propostas</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openCustomerServices">
                <v-list-item-icon>
                  <v-icon color="#019c50">mdi-phone-in-talk</v-icon>
                </v-list-item-icon>
                <v-list-item-title color="black">Receptivo</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="['Desenvolvedor', 'Administrador'].includes(userInfo.user_category)" @click="openSystemLogs">
                <v-list-item-icon>
                  <v-icon color="#019c50">mdi-math-log</v-icon>
                </v-list-item-icon>
                <v-list-item-title color="black">Logs</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="['Desenvolvedor', 'Administrador'].includes(userInfo.user_category)" @click="openUsersWebcred">
                <v-list-item-icon>
                  <v-icon color="#019c50">mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-title color="black">Usuários</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="['Desenvolvedor', 'Administrador'].includes(userInfo.user_category)" @click="openParameters">
                <v-list-item-icon>
                  <v-icon color="#019c50">mdi-cogs</v-icon>
                </v-list-item-icon>
                <v-list-item-title color="black">Parâmetros</v-list-item-title>
              </v-list-item>
            </div>
          </div>
        </v-list-item-group>
      </v-list>
      <div class="d-flex justify-center align-center flex-column pa-5">
        <div>
          <v-img class="rounded-circle" :src="logo"
            height="60"
            width="60"></v-img>
        </div>
        <span @click="goToHome" style="color: #019c50; font-size: 1.3em; font-weight: 900; width: 300px;text-align-last: center;">{{ capitalizedUserPartner }}</span>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "HeaderApplication",
  computed: {
    capitalizedUserPartner() {
      if (typeof this.userPartner !== 'string') {
        return this.userPartner;
      }
      if (this.userPartner.includes(" ")) {
        let string_splited = this.userPartner.split(" ")
        let compose_partner_name = ''
        for (let i in string_splited) {
          compose_partner_name = compose_partner_name + string_splited[i].charAt(0).toUpperCase() + string_splited[i].slice(1).toLowerCase();
          if (i !== string_splited.length -1) {
            compose_partner_name = compose_partner_name + " "
          }
        }
        return compose_partner_name
      }
      return this.userPartner.charAt(0).toUpperCase() + this.userPartner.slice(1).toLowerCase();
    },
    user() {
      return this.$store.getters['getUser']
    }
  },
  data: () => ({
    drawer: false,
    group: null,
    userPhoto: null,
    userPartner: null,
    logo: null,
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    loading: false,
    userInfo: ''
  }),
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$router.push('/')
      }
    }
  },
  methods: {
    openParameters() {
      this.$router.push({name: 'menuparameters'})
    },
    logout() {
      this.$http.post('v1/api_logout').then(v => {
        this.dialog = true
        this.dialogTitle = 'Sucesso!'
        this.dialogMessage = v.data.msg
      })
    },
    openUsersWebcred() {
      this.$router.push({name: 'users'})
    },
    openSystemLogs() {
      this.$router.push({name: 'logs'})
    },
    openDashboard() {
      this.$router.push({name: 'dashboard'})
    },
    openClients() {
      this.$router.push({name: 'listclients'})
    },
    openProposals() {
      this.$router.push({name: 'listproposals'})
    },
    openCustomerServices() {
      this.$router.push({name: 'consultant_home'})
    },
    goToHome() {
      this.$router.push({name: 'menu'})
    },
    get_webcred_user(){
      this.$http.get(`contaswebcred/get_user/`).then(v => {
        this.userInfo = v.data.user
        this.userPhoto = v.data.user.user_photo
        this.userPartner = v.data.user.system_partner.partner
        this.logo = v.data.user.system_partner.logo
      })
    }
  },
  created() {
    this.get_webcred_user()
    const isActive = this.$store.getters['getPartnerActive']
    if (isActive === false) {
      this.$router.push({name: 'login'})
    }
  }
}
</script>

<style scoped>

.drawer-item {
  margin-left: 5px;
}

.drawer-items {
  display: flex;
  margin-left: 25px;
  margin-top: 5px;
}

</style>