<template>
  <div>
    <div class="mt-6 mb-6">
      <StandardTitle :labeltitle="'Cadastrar Cliente e Benefício'"></StandardTitle>
    </div>
    <div>
      <AddClientAndBenefit></AddClientAndBenefit>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";
import AddClientAndBenefit from "@/components/proposals/AddClientAndBenefit.vue";

export default {
  name: "ProposalClientBenefit",
  components: {AddClientAndBenefit, StandardTitle},
}
</script>

<style scoped>

</style>