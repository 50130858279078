<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
   <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else id="main">
    <v-dialog
    v-model="dialogFilter"
    transition="dialog-top-transition"
    scrollable width="auto">
      <v-card class="rounded-xl">
        <v-toolbar elevation="0" color="#019c50">
          <v-card-text style="font-weight: bolder; font-size: 1.5em; color: #FFFFFF">Filtros:</v-card-text>
          <v-icon color="#FFFFFF" @click="cleanFilters">mdi-filter-off</v-icon>
        </v-toolbar>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div class="filter-fields mt-10">
              <div class="mr-1" style="width: 300px;">
                <v-select dense v-model="searchClientBase" :items="baseInfo" label="Base" rounded outlined></v-select>
              </div>
              <div class="mr-1" style="width: 300px;">
                <v-text-field dense v-model="searchClientName" label="Nome" rounded outlined></v-text-field>
              </div>
              <div class="mr-1" style="width: 250px;">
                <v-text-field dense v-model="searchClientCpf" v-maska
                                    data-maska="###.###.###-##" label="CPF" rounded outlined></v-text-field>
              </div>
              <div class="mr-1" style="width: 200px;">
                <v-text-field dense v-model="searchClientPhone" label="Telefone" rounded outlined></v-text-field>
              </div>
            </div>
            <div class="filter-fields">
              <div class="mr-1" style="width: 220px;">
                <v-text-field dense v-model="searchClientBenefit" label="Benefício" rounded outlined></v-text-field>
              </div>
              <div class="mr-1" style="width: 220px;">
                <v-text-field dense v-model="searchClientSpecies" label="Espécie" rounded outlined></v-text-field>
              </div>
              <div class="mr-1" style="width: 220px;">
                <v-text-field dense v-model="searchClientForUf" label="UF" rounded outlined></v-text-field>
              </div>
            </div>
            <div class="filter-fields">
              <div class="mr-1" style="width: 300px;">
                <v-text-field type="date" dense v-model="searchClientBirthdayStart" label="Data de Nascimento Inicial" rounded outlined></v-text-field>
                <v-text-field type="date" dense v-model="searchClientBirthdayEnd" label="Data de Nascimento Final" rounded outlined></v-text-field>
              </div>
              <div class="mr-1" style="width: 300px;">
                <v-text-field dense v-model="searchClientSalaryStart" label="Salário Inicial" rounded outlined></v-text-field>
                <v-text-field dense v-model="searchClientSalaryEnd" label="Salário Final" rounded outlined></v-text-field>
              </div>
              <div class="mr-1" style="width: 300px;">
                <v-text-field type="date" dense v-model="searchClientGrantDateStart" label="Data de Concessão Inicial" rounded outlined></v-text-field>
                <v-text-field type="date" dense v-model="searchClientGrantDateEnd" label="Data de Concessão Final" rounded outlined></v-text-field>
              </div>
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="getClients">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="block-buttons">
      <div class="export buttons-add">
        <v-btn @click="exportResults" rounded color="#019c50" style="color: #FFFFFF; text-transform: none">Exportar</v-btn>
        <v-progress-linear class="mt-1" style="width: 88px; margin-left: 5px; height: 5px" v-if="dialogExport" color="#019c50" indeterminate></v-progress-linear>
      </div>
      <div class="buttons-add">
        <v-btn color="#019c50" style="color: #FFFFFF; text-transform: none" @click="addClient" rounded>
          <div>
            <h5>Adicionar</h5>
          </div>
          <div>
            <v-icon class="ml-2" style="font-size: 2em; color: #FFFFFF">mdi-account-plus-outline</v-icon>
          </div>
        </v-btn>
      </div>
      <div class="buttons-add">
        <v-btn color="#019c50" style="color: #FFFFFF; text-transform: none" @click="addClientExcel" rounded>
          <div>
            <h5>Adicionar de arquivo</h5>
          </div>
          <div>
            <v-icon class="ml-2" style="font-size: 2em; color: #FFFFFF">mdi-account-plus-outline</v-icon>
          </div>
        </v-btn>
      </div>
    </div>
    <div class="mt-1 mb-6">
      <StandardTitle :labeltitle="'Clientes'"></StandardTitle>
    </div>
    <div class="d-flex justify-end align-center mb-2 mr-3" >
      <span style="font-size: 0.8em; font-weight: bold">Filtros: </span>
      <v-icon color="#019c50" @click="dialogFilter = true">
        mdi-filter-menu
      </v-icon>
    </div>
    <div class="ml-2 mr-2">
      <v-data-table
          :headers="headers"
          :items="clients"
          :items-per-page="10"
          hide-default-footer
          class="elevation-4 rounded-xl"
          @click:row="openClient"
      >
      </v-data-table>
    </div>
    <div class="data-table-bottom mb-8">
      <div style="width: 100%; display: flex; justify-content: flex-start; padding: 10px">
        <div class="mt-2">
          <v-btn color="#019c50" style="color: #FFFFFF; text-transform: none" rounded v-if="previousPage != null" class="btn-success" @click="djangoPagination = djangoPagination - 1">
            Página Anterior
          </v-btn>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: center; padding: 10px">
        <div class="mt-2">
          <v-btn color="#019c50" style="color: #FFFFFF; text-transform: none" rounded class="btn-success">
            {{ countClients }}
          </v-btn>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: flex-end; padding: 10px">
        <div class="mt-2">
          <v-btn color="#019c50" style="color: #FFFFFF; text-transform: none" rounded class="btn-danger ml-2" v-if="nextPage != null" @click="djangoPagination = djangoPagination + 1">
            Próxima Página
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle";
export default {
  name: "ListClients",
  components: {StandardTitle},
  data: () => ({
    pageLoading: true,
    nextPage: null,
    previousPage: null,
    djangoPagination: 1,
    headers: [
      {text: 'CLIENTE', value: 'name', sortable: false},
      {text: 'CPF', value: 'cpf', sortable: false},
      {text: 'ENDEREÇO', value: 'street_avenue_platter', sortable: false},
      {text: 'CIDADE', value: 'city', sortable: false},
      {text: 'ESTADO', value: 'state', sortable: false},
      {text: 'TELEFONE', value: 'phone', sortable: false},
    ],
    clients: [],
    baseInfo: [],
    countClients: undefined,
    dialogExport: false,
    loading: false,
    dialogFilter: false,
    searchClientCpf: '',
    searchClientName: '',
    searchClientBenefit: '',
    searchClientPhone: '',
    searchClientBase: '',
    searchClientSpecies: '',
    searchClientBirthdayStart: '',
    searchClientBirthdayEnd: '',
    searchClientForUf: '',
    searchClientSalaryStart: '',
    searchClientSalaryEnd: '',
    searchClientGrantDateStart: '',
    searchClientGrantDateEnd: ''
  }),
  methods: {
    async getClients() {
      this.loading = true
      this.pageLoading = true
      let birthdayParams = {};
      birthdayParams.birthday_start = this.searchClientBirthdayStart
      birthdayParams.birthday_end = this.searchClientBirthdayEnd
      let salaryParams = {};
      salaryParams.salary_start = this.searchClientSalaryStart
      salaryParams.salary_end = this.searchClientSalaryEnd
      let grantDateParams = {};
      grantDateParams.grant_date_start = this.searchClientGrantDateStart
      grantDateParams.grant_date_end = this.searchClientGrantDateEnd
      await this.$http.get(`clientes/?page=${this.djangoPagination}&cpf=${this.searchClientCpf}&phone=${this.searchClientPhone}&benefit=${this.searchClientBenefit}&base=${this.searchClientBase}&name=${this.searchClientName}&species=${this.searchClientSpecies}&birthday=${JSON.stringify(birthdayParams)}&salary=${JSON.stringify(salaryParams)}&grant_date=${JSON.stringify(grantDateParams)}&state=${this.searchClientForUf}`
      ).then(
        v => {
          this.countClients = v.data.count
          this.nextPage = v.data.next
          this.previousPage = v.data.previous
          this.clients = v.data.results
          this.loading = false
          this.dialogFilter = false
          this.pageLoading = false
        },
      )
    },
    getBases() {
      this.$http.get('utilitarios/').then(
        (v) => {
          let basesInDb = v.data
          for (let i in basesInDb) {
            this.baseInfo.push(basesInDb[i].bases)
          }
        }
      )
    },
    async exportResults() {
      this.dialogExport = true
      let birthdayParams = {};
      birthdayParams.birthday_start = this.searchClientBirthdayStart
      birthdayParams.birthday_end = this.searchClientBirthdayEnd
      let salaryParams = {};
      salaryParams.salary_start = this.searchClientSalaryStart
      salaryParams.salary_end = this.searchClientSalaryEnd
      let grantDateParams = {};
      grantDateParams.grant_date_start = this.searchClientGrantDateStart
      grantDateParams.grant_date_end = this.searchClientGrantDateEnd
      await this.$http.get(`clientes/export_client_results_view/?cpf=${this.searchClientCpf}&phone=${this.searchClientPhone}&benefit=${this.searchClientBenefit}&base=${this.searchClientBase}&name=${this.searchClientName}&species=${this.searchClientSpecies}&birthday=${JSON.stringify(birthdayParams)}&salary=${JSON.stringify(salaryParams)}&grant_date=${JSON.stringify(grantDateParams)}&state=${this.searchClientForUf}`, { responseType: 'arraybuffer' }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'clientes.xlsx');
          document.body.appendChild(link);
          link.click()
          this.dialogExport = false
        })
        .catch(error => {
          console.error('Export error:', error);
        });
    },
    openClient(value) {
      this.$router.push({name: 'search_response', params: {'client': value}})
    },
    addClient() {
      this.$router.push({name: 'addclients'})
    },
    addClientExcel() {
      this.$router.push({name: 'excelimport'})
    },
    cleanFilters() {
      this.searchClientCpf = ''
      this.searchClientName = ''
      this.searchClientBenefit = ''
      this.searchClientPhone = ''
      this.searchClientBase = ''
      this.searchClientSpecies = ''
      this.searchClientBirthdayStart = ''
      this.searchClientBirthdayEnd = ''
      this.searchClientForUf = ''
      this.searchClientSalaryStart = ''
      this.searchClientSalaryEnd = ''
      this.searchClientGrantDateStart = ''
      this.searchClientGrantDateEnd = ''
      this.djangoPagination = 1
      this.getClients()
    }
  },
  created() {
    this.getBases()
    this.getClients()
  },
  watch: {
    djangoPagination() {
      this.getClients()
    },
    dialogFilter() {
      if (!this.dialogFilter) {
        this.djangoPagination = 1
      }
    },
  }
}
</script>

<style scoped>
.block-buttons {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  margin-right: 10px;
  margin-top: 10px;
}
.buttons-add {
  padding: 10px;
}
.data-table-bottom {
  display: flex;
}
.filter-fields {
  display: flex;
  justify-content: center;
}
</style>