<template>
  <div class="d-flex justify-center align-center flex-column">
    <v-dialog v-model="dialog"
    transition="dialog-top-transition"
    scrollable width="auto"
    persistent
    >
      <v-card class="rounded-xl">
        <v-card-title>Atenção: </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div class="d-flex justify-center align-center" style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialog = false; loading = false;">
            Sair
          </v-btn>
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="goToAddClient">
            Cadastrar Cliente
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPhone"
    transition="dialog-top-transition"
    scrollable width="auto"
    >
      <v-card class="rounded-xl">
        <v-card-title>Telefones: </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <v-select style="margin-top: 42px;" dense label="Telefone" v-model="selectedPhone" :items="clientPhones"
                             outlined rounded></v-select>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="phoneUsed = selectedPhone; dialogPhone = false">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInvalid"
    transition="dialog-top-transition"
    scrollable width="auto"
    persistent
    >
      <v-card class="rounded-xl">
        <v-card-title>Atenção: </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessageInvalid }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialogInvalid = false; btnLoading = false; ">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProposal"
    transition="dialog-top-transition"
    scrollable width="auto"
    >
      <v-card class="rounded-xl">
        <v-card-title> {{ dialogProposalTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogProposalMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialogProposal = false;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="form" v-model="valid">
      <v-card class="pa-8 rounded-xl mb-5" elevation="10">
        <div v-if="selectPermission" style="width: max-content">
          <div class="d-flex justify-center align-center loja-callcenter-decision-block">
            <span>Proposta da:</span>
            <input v-model="loja" class="inputradio ml-3" type="radio" value="loja"/>
            <span class="ml-1">Loja</span>
            <input v-model="callcenter" class="inputradio ml-3" type="radio" value="callcenter"/>
            <span class="ml-1">Call Center</span>
          </div>
        </div>
        <div v-if="mainSector" class="card-content">
          <div class="cpf-section">
            <div class="mr-3" style="font-weight: bolder">Buscar Cliente:</div>
            <div>
              <v-text-field dense label="CPF" v-model="cpfTerm" outlined rounded v-maska :rules="cpfRules"
                            data-maska="###.###.###-##"></v-text-field>
            </div>
            <div class="ml-3">
              <v-btn small rounded elevation="4" color="#019c50" style="color: #FFFFFF; text-transform: none" :loading="loading" @click="catchClient">Buscar
              </v-btn>
            </div>
          </div>
          <div class="common-info-section">
            <div style="width: 400px; margin-right: 10px;">
              <v-text-field dense label="Nome Completo" :rules="fullNameRules" v-model="fullName" outlined
                            rounded></v-text-field>
            </div>
            <div class="d-flex justify-center align-content-center">
              <v-text-field label="Telefone" v-model="phoneUsed" v-maska :rules="phoneUsedRules" dense outlined rounded
                            data-maska="['(##) #####-####', '(##) ####-####']"></v-text-field>
              <div>
                <v-icon @click="dialogPhone = true" class="mb-7" size="40" color="#019c50">mdi-phone</v-icon>
              </div>
            </div>
          </div>
          <div class="radio-section">
            <div class="mr-4">Possui Representate Legal?</div>
            <div class="ml-4">
              <v-radio-group rounded outlined dense v-model="isRepresentative">
                <v-radio
                    label="Sim"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="Não"
                    :value="false"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
          <div class="representative-section" v-if="isRepresentative">
            <div style="width: 400px; margin-right: 10px;">
              <v-text-field rounded dense label="Nome Representante" outlined :rules="representativeNameRules"
                            v-model="nameRepresentative"></v-text-field>
            </div>
            <div>
              <v-text-field rounded dense label="CPF Representante" outlined :rules="representativeCpfRules"
                            v-model="cpfRepresentative"></v-text-field>
            </div>
          </div>
          <v-divider class="mt-4"></v-divider>
        </div>

        <div class="d-flex justify-center align-center mt-4">
          <span>Informações complementares</span>
        </div>
        <div class="d-flex justify-center align-center">
          <div class="ml-3 mr-3 mt-4" style="width: 80%">
            <div class="d-flex justify-center align-center">
              <v-select class="mr-2" dense label="Produto" :items="loanTypes" v-model="loanType"
                        :rules="loanTypeRules" outlined rounded></v-select>
               <v-select class="ml-2" dense label="Formalização" :items="formalizationWays"
                        v-model="formalization" :rules="formalizationRules" outlined rounded></v-select>
            </div>
            <div class="d-flex justify-center align-center">
              <v-select v-if="sectorFive" class="mr-2" dense label="Benefício" :items="benefits" v-model="benefit" outlined
                        rounded></v-select>
              <v-text-field dense label="Valor Líquido" v-model="productNetValue" outlined rounded
              v-maska data-maska="R$ #.#,##"
                            data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              <v-text-field class="ml-2" dense label="Valor Bruto"  v-model="productValue" outlined rounded v-maska data-maska="R$ #.#,##"
                            data-maska-tokens="#:[0-9]:multiple"></v-text-field>
            </div>
            <div class="d-flex justify-center align-center">
              <v-autocomplete dense label="Banco Proposta" :rules="loanBankRules"
                              v-model="loanBank" :items="banks" outlined rounded></v-autocomplete>
            </div>
            <div v-if="sectorThree" class="d-flex justify-center align-center">
              <v-autocomplete class="mr-2" dense label="Banco" v-model="clientBankName" :items="banks" outlined
                              rounded></v-autocomplete>
              <v-text-field class="ml-2 mr-2" dense label="Agência" v-maska data-maska="*-*"
                            data-maska-tokens="*:[0-9]:multiple"
                            v-model="bankAgency" outlined rounded></v-text-field>
              <v-text-field class="ml-2" dense label="Conta" v-model="bankAccount" v-maska data-maska="*-*"
                            data-maska-tokens="*:[0-9]:multiple"
                            outlined rounded></v-text-field>
            </div>
            <div v-if="sectorFour" class="d-flex justify-center align-center">
              <v-text-field class="mr-2" dense label="Valor Parcela" v-maska data-maska="R$ #.#,##"
                            data-maska-tokens="#:[0-9]:multiple" v-model="portionValue" outlined rounded></v-text-field>
              <v-text-field class="ml-2" dense label="Prazo Parcela" v-model="portionDeadline" outlined rounded
                            v-maska data-maska="##"></v-text-field>
            </div>
            <div v-if="sectorTwo" class="d-flex justify-center align-center">
              <v-text-field class="mr-2" dense label="Qtd Parcelas Pagas" v-model="qtdPortionPaid" outlined rounded
                            v-maska data-maska="##"></v-text-field>
              <v-text-field class="ml-2" dense label="Saldo Devedor Parcela" v-maska data-maska="R$ #.#,##"
                            data-maska-tokens="#:[0-9]:multiple" v-model="portionDebt" outlined rounded></v-text-field>
            </div>
            <div v-if="sectorTwo" class="d-flex justify-center align-center">
              <v-autocomplete dense label="Banco Parcela"
                              v-model="portionBank" :items="banks" outlined rounded></v-autocomplete>
              <v-text-field class="ml-2 mr-2" dense label="Inicio Desconto" v-model="startDiscount" outlined
                            rounded></v-text-field>
              <v-text-field class="ml-2" dense label="Final Desconto" v-model="endDiscount" outlined
                            rounded></v-text-field>
            </div>
            <div v-if="sectorOne" class="d-flex justify-center align-center" style="margin-top: -30px">
              <div>
                <span class="mr-4">Tem Seguro?</span>
              </div>
              <div>
                <v-radio-group rounded outlined dense v-model="hasInsurance">
                  <v-radio
                      label="Sim"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      label="Não"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div v-if="hasInsurance" style="margin-left: 15px; margin-top: 27px;">
                <v-text-field rounded outlined dense label="Valor Seguro" v-maska data-maska="R$ #.#,##"
                              :rules="insuranceValueRules" data-maska-tokens="#:[0-9]:multiple"
                              v-model="insuranceValue"></v-text-field>
              </div>
            </div>
            <v-textarea dense label="Observação" v-model="observation" outlined rounded></v-textarea>
          </div>
        </div>
        <div class="d-flex justify-center align-center">
          <v-btn class="mr-1" rounded small color="#019c50" style="color: #FFFFFF; text-transform: none"
                 @click="submitLoanProposalData(true)" :loading="btnLoading">
            Adicionar Produto
          </v-btn>
          <v-btn class="ml-1" rounded small color="#019c50" style="color: #FFFFFF; text-transform: none"
                 @click="submitLoanProposalData(false)" :loading="btnLoading">
            Salvar
          </v-btn>
        </div>
      </v-card>
    </v-form>
  </div>
</template>

<script>

export default {
  name: "CreateProposal",
  props: ['proposal_with_client'],
  data: () => ({
    currencyRules: [
      value => !!value || 'Valor é obrigatório',
      value => /^[0-9]{1,3}(?:\.[0-9]{3})*(?:,[0-9]{2})?$/.test(value) || 'Formato de moeda inválido',
    ],
    loading: false,
    valid: false,
    cpfTerm: '',
    cpfRules: [
      v => !!v || 'C.P.F. é obrigatório',
    ],
    fullName: '',
    fullNameRules: [
      v => !!v || 'Nome é obrigatório',
    ],
    userName: '',
    phoneUsedRules: [
      v => !!v || 'Telefone é obrigatório',
    ],
    phoneUsed: '',
    clientPhones: [],
    isRepresentative: false,
    nameRepresentative: '',
    representativeNameRules: [
      v => !!v || 'Nome Representante é obrigatório',
    ],
    cpfRepresentative: '',
    representativeCpfRules: [
      v => !!v || 'C.P.F. Representante é obrigatório',
    ],
    loanType: '',
    loanTypeRules: [
      v => !!v || 'Produto é obrigatório',
    ],
    benefit: '',
    formalization: '',
    formalizationRules: [
      v => !!v || 'Formalização é obrigatório',
    ],
    clientBankName: '',
    productNetValue: '',
    productValue: '',
    bankAgency: '',
    bankAccount: '',
    portionValue: '',
    portionDeadline: '',
    portionBank: '',
    qtdPortionPaid: '',
    startDiscount: '',
    endDiscount: '',
    portionDebt: '',
    observation: '',
    loanBank: '',
    loanBankRules: [
      v => !!v || 'Banco é obrigatório',
    ],
    hasInsurance: false,
    insuranceValue: '',
    insuranceValueRules: [
      v => !!v || 'Valor do seguro é obrigatório',
    ],
    benefits: [],
    banks: [],
    formalizationWays: [
      'FÍSICO', 'DIGITAL', 'FIGITAL'
    ],
    loanTypes: [
      'AUMENTO DE SALÁRIO', 'CARTÃO BENEFÍCIO COM SAQUE', 'CARTÃO BENEFÍCIO SEM SAQUE', 'CARTÃO COM SAQUE',
      'CARTÃO SEM SAQUE', 'CARTÃO DE CRÉDITO', 'CRÉDITO PESSOAL', 'ENERGIA', 'FGTS', 'NOVO', 'OPORTUNIDADE NOVO',
      'OPORTUNIDADE CARTÃO', 'OPORTUNIDADE CARTÃO COM SAQUE', 'PORTABILIDADE ATAQUE', 'PORTABILIDADE+REFIN', 'PORTABILIDADE PURA', 'REFIN DE PORTABILIDADE', 'REFIN DE PORT ZERADO',
      'REFINANCIAMENTO', 'REFINANCIAMENTO+AGREGRAÇÃO', 'REVERSÃO NOVO', 'REVERSÃO CARTÃO', 'REVERSÃO CARTÃO COM SAQUE',
      'SAQUE COMPLEMENTAR', 'SAQUE COMPLEMENTAR BENEFÍCIO'
    ],
    sectorOne: false,
    sectorTwo: false,
    sectorThree: false,
    sectorFour: false,
    sectorFive: false,
    mainSector: true,
    layoutProducts: {
      'one': [
        'CARTÃO BENEFÍCIO COM SAQUE',
        'CARTÃO COM SAQUE',
        'CRÉDITO PESSOAL',
        'FGTS'
      ],
      'two': [
        'PORTABILIDADE ATAQUE',
        'PORTABILIDADE+REFIN',
        'PORTABILIDADE PURA',
        'REFIN DE PORTABILIDADE',
        'REFIN DE PORT ZERADO'
      ],
      'three': [
        'AUMENTO DE SALÁRIO',
        'CARTÃO BENEFÍCIO COM SAQUE',
        'CARTÃO COM SAQUE',
        'CRÉDITO PESSOAL',
        'ENERGIA',
        'FGTS',
        'NOVO',
        'PORTABILIDADE+REFIN',
        'PORTABILIDADE ATAQUE',
        'PORTABILIDADE PURA',
        'REFIN DE PORTABILIDADE',
        'REFIN DE PORT ZERADO',
        'REFINANCIAMENTO',
        'REFINANCIAMENTO+AGREGRAÇÃO',
        'REVERSÃO CARTÃO COM SAQUE',
        'REVERSÃO NOVO',
        'SAQUE COMPLEMENTAR',
        'SAQUE COMPLEMENTAR BENEFÍCIO',
      ],
      'four': [
        'AUMENTO DE SALÁRIO',
        'CARTÃO BENEFÍCIO COM SAQUE',
        'CARTÃO BENEFÍCIO SEM SAQUE',
        'CARTÃO COM SAQUE',
        'CARTÃO DE CRÉDITO',
        'CARTÃO SEM SAQUE',
        'CRÉDITO PESSOAL',
        'ENERGIA',
        'NOVO',
        'PORTABILIDADE+REFIN',
        'PORTABILIDADE ATAQUE',
        'PORTABILIDADE PURA',
        'REFIN DE PORTABILIDADE',
        'REFIN DE PORT ZERADO',
        'REFINANCIAMENTO',
        'REFINANCIAMENTO+AGREGRAÇÃO',
        'REVERSÃO CARTÃO',
        'REVERSÃO CARTÃO COM SAQUE',
        'REVERSÃO NOVO'
      ],
      'five': [
        'AUMENTO DE SALÁRIO',
        'CARTÃO BENEFÍCIO COM SAQUE',
        'CARTÃO BENEFÍCIO SEM SAQUE',
        'CARTÃO COM SAQUE',
        'CARTÃO SEM SAQUE',
        'NOVO',
        'PORTABILIDADE+REFIN',
        'PORTABILIDADE ATAQUE',
        'PORTABILIDADE PURA',
        'REFIN DE PORTABILIDADE',
        'REFIN DE PORT ZERADO',
        'REFINANCIAMENTO',
        'REFINANCIAMENTO+AGREGRAÇÃO',
        'REVERSÃO NOVO',
        'REVERSÃO CARTÃO',
        'REVERSÃO CARTÃO COM SAQUE',
        'SAQUE COMPLEMENTAR',
        'SAQUE COMPLEMENTAR BENEFÍCIO',
        'SEGURO PRESTAMISTA',
        'SEGURO SINDNAPE'
      ],
    },
    clientObject: null,
    benefitInstance: null,
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    dialogProposal: false,
    dialogProposalTitle: '',
    dialogProposalMessage: '',
    btnLoading: false,
    dialogInvalid: false,
    dialogMessageInvalid: '',
    selectedPhone: '',
    dialogPhone: false,
    loja: 'loja',
    callcenter: false,
    selectPermission: false
  }),
  computed: {
    isCallCenter() {
      return this.loja ? this.loja : this.callcenter
    },
  },
  methods: {
    async originSelectPermission() {
      await this.$http.get('contaswebcred/origin_select_permission/').then((v) => {
        if (v.data.is_allowed) {
          this.selectPermission = v.data.is_allowed
        }
      })
    },
    async catchClient() {
      this.loading = true
      this.clientPhones = []
      await this.$http.post('clientes/client_to_proposal/', {
        cpf: this.cpfTerm
      }).then(v => {
        if (v.data.message) {
          this.dialog = true
          this.dialogMessage = v.data.message
        } else {
          this.clientObject = v.data.client
          this.fullName = this.clientObject.name
          if (this.clientObject.cliente !== null) {
            for (let i in this.clientObject.cliente) {
              this.benefits.push(this.clientObject.cliente[i].benefit)
            }
          this.$http.get(`telefones/?cliente_id=${this.clientObject.id}`).then((t) => {
            for (let i in t.data) {
              this.clientPhones.push(t.data[i].numero)
            }
          })
          this.loading = false
          }
        }
      }).catch(v => {
        this.dialog = true
        this.dialogMessage = `${v.response.data.error} Contate o administrador do sistema`
      })
    },
    getBanks() {
      if (!this.proposal) {
        this.$http.get('bancos/').then(v => {
          for (let i in v.data) {
            this.banks.push(`${v.data[i].code_number} - ${v.data[i].bank_name}`)
          }
        })
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    submitLoanProposalData(value) {
      this.validate()
      this.btnLoading = true
      if (this.valid) {
        let params = {}
        if (value) {
          params.next_product = true
        }
        params.cpf = this.cpfTerm
        params.full_name = this.fullName
        params.user_name = this.userName
        params.phone_used = this.phoneUsed
        params.is_representative = this.isRepresentative
        params.name_representative = this.nameRepresentative
        params.cpf_representative = this.cpfRepresentative
        params.loan_product = this.loanType
        params.benefit = this.benefit
        params.formalization = this.formalization
        params.client_bank = this.clientBankName
        params.net_value = this.productNetValue
        params.product_value = this.productValue
        params.bank_agency = this.bankAgency
        params.bank_account = this.bankAccount
        params.portion_value = this.portionValue
        params.portion_deadline = this.portionDeadline
        params.portion_bank = this.portionBank
        params.qtd_portion_paid = this.qtdPortionPaid
        params.start_discount = this.startDiscount
        params.end_discount = this.endDiscount
        params.portion_debt = this.portionDebt
        params.observation = this.observation
        params.loan_bank = this.loanBank
        params.has_insurance = this.hasInsurance
        params.insurance_value = this.insuranceValue
        params.is_call_center = this.isCallCenter
        this.$http.post('propostas/insert_proposal/', {params}).then(v => {
          if (v.data.message === 'repeat') {
            this.loanType = ''
            this.benefit = ''
            this.formalization = ''
            this.clientBankName = ''
            this.productValue = ''
            this.bankAgency = ''
            this.bankAccount = ''
            this.portionValue = ''
            this.portionDeadline = ''
            this.portionBank = ''
            this.qtdPortionPaid = ''
            this.startDiscount = ''
            this.endDiscount = ''
            this.portionDebt = ''
            this.observation = ''
            this.loanBank = ''
            this.hasInsurance = false
            this.insuranceValue = ''
            this.dialogProposal = true
            this.dialogProposalTitle = 'Sucesso!'
            this.dialogProposalMessage = 'Digite as informações do próximo produto.'
            this.btnLoading = false
          } else if (v.data.message === 'finish') {
            this.dialogProposal = true
            this.dialogProposalTitle = 'Sucesso!'
            this.dialogProposalMessage = 'Processo de digitação de proposta finalizado com sucesso!'
            this.btnLoading = false
            setTimeout(function() {
              location.reload();
            }, 1500);
          } else {
            this.dialogProposal = true
            this.dialogProposalTitle = 'Atenção!'
            this.dialogProposalMessage = v.data.message
            this.btnLoading = false
          }
        })
      } else {
        this.dialogInvalid = true
        this.dialogMessageInvalid = 'Preencha corretamente as informações!'
        this.loading = false
      }
    },
    goToAddClient() {
      this.loading = true
      this.$router.push({name: 'addclientandbenefit'})
      this.loading = false
    },
  },
  created() {
    this.originSelectPermission()
    if (this.$route.params.cpf) {
      this.cpfTerm = this.$route.params.cpf
      this.catchClient()
    }
    if (this.proposal_with_client) {
      this.fullName = this.proposal_with_client.name
      this.cpfTerm = this.proposal_with_client.cpf
      for (let i in this.proposal_with_client.cliente) {
        this.benefits.push(this.proposal_with_client.cliente[i].benefit)
      }
    }
    this.userName = this.$store.state.user
    this.getBanks()
  },
  watch: {
    loja() {
      if (this.loja !== false) {
        this.callcenter = false
      }
    },
    callcenter() {
      if (this.callcenter !== false) {
        this.loja = false
      }
    },
    loanType() {
      this.sectorOne = false
      this.sectorTwo = false
      this.sectorThree = false
      this.sectorFour = false
      this.sectorFive = false
      let prod = this.loanType
      for (let i in this.layoutProducts) {
        let sectors = this.layoutProducts[i]
        for (let p in sectors) {
          if (prod === sectors[p]) {
            if (i === 'one') {
              this.sectorOne = true
            }
            if (i === 'two') {
              this.sectorTwo = true
            }
            if (i === 'three') {
              this.sectorThree = true
            }
            if (i === 'four') {
              this.sectorFour = true
            }
            if (i === 'five') {
              this.sectorFive = true
            }
          }
        }
      }
    },
  }
}

</script>

<style scoped>
.card-content {
  width: auto;
}

.cpf-section {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.common-info-section {
  display: flex;
  justify-content: center;
}

.radio-section {
  display: flex;
  justify-content: center;
  margin-top: -25px;
  margin-bottom: -15px;
  align-items: center;
}

.representative-section {
  display: flex;
  justify-content: center;
}

.loja-callcenter-decision-block {
  margin-top: -15px;
  margin-bottom: 15px;
  background-color: #019c50;
  color: #FFFFFF;
  padding: 4px 10px;
  border-radius: 50px;
  font-size: 0.7em;
  font-weight: 900;
}

</style>