<template>
  <div>
    <v-dialog v-model="dialogDate" scrollable width="auto" transition="dialog-top-transition">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogDateTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: auto; width: auto">
          <v-date-picker locale="pt-br" class="pa-4" color="#019c50" v-model="picker"></v-date-picker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="closeDialog(picker, info)">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mt-5">
      <StandardTitle :labeltitle="'AGENDAMENTOS'"></StandardTitle>
    </div>
    <div class="d-flex justify-center align-center mt-7">
      <div v-if="userId" style="width: 300px;">
        <v-autocomplete dense :items="usersInstances" v-model="searchSchedulesForUser" label="Filtrar por Usuário" rounded outlined></v-autocomplete>
      </div>
      <div class="d-flex justify-center align-content-center ml-3" style="width: 180px">
        <v-text-field dense label="Data Inicial" :value="startDate" @change="updateStartDate" outlined rounded v-maska
                        data-maska="##/##/####"></v-text-field>
        <v-icon @click="changeModelDate('Data Inicial', 'start_date')" class="mb-7" size="40"
                color="#019c50" >mdi-calendar-edit
        </v-icon>
      </div>
      <div class="d-flex justify-center align-content-center" style="width: 180px">
        <v-text-field dense label="Data Final" :value="endDate" @change="updateEndDate" outlined rounded v-maska
                        data-maska="##/##/####"></v-text-field>
        <v-icon @click="changeModelDate('Data Final', 'end_date')" class="mb-7" size="40"
                color="#019c50" >mdi-calendar-edit
        </v-icon>
      </div>
      <div class="mr-2 ml-2">
        <v-text-field dense v-maska data-maska="###.###.###-##" label="CPF"
                      v-model="searchForCpf" rounded outlined></v-text-field>
      </div>
      <div>
        <v-text-field dense label="Nome" v-model="searchForClientName" rounded outlined></v-text-field>
      </div>
      <v-icon @click="cleanFilters" color="#019c50" class="mb-7 ml-3" size="40">mdi-filter-off</v-icon>
    </div>
    <div class="mb-5" style="display: flex; justify-content: center">
      <v-data-table
          style="width: 1200px"
          dense
          :headers="headers"
          :items="schedulings"
          hide-default-footer
          disable-pagination
          @click:row="openClient"
          class="elevation-4 rounded-xl pa-2"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "ShowSchedulings",
  components: {StandardTitle},
  data: () => ({
    headers: [
      {text: 'USUÁRIO', value: 'user.account_identification', sortable: false},
      {text: 'BASE', value: 'client.base', sortable: false},
      {text: 'CLIENTE', value: 'client.name', sortable: false},
      {text: 'CPF', value: 'client.cpf', sortable: false},
      {text: 'DATA AGENDAMENTO', value: 'scheduling', sortable: false},
      {text: 'OBSERVAÇÃO', value: 'observation', sortable: false},
    ],
    searchSchedulesForUser: '',
    schedulings: [],
    usersInstances: [],
    userId: 0,
    dialogDate: false,
    dialogDateTitle: '',
    startDate: '',
    endDate: '',
    picker: new Date().toISOString().substr(0, 10),
    info: '',
    dialogMessage: false,
    searchForCpf: '',
    searchForClientName: '',
    clickCount: 0,
    clickTimeout: null,
    client: '',
  }),
  methods: {
    changeModelDate(title, value) {
      this.info = value
      this.dialogDateTitle = title
      this.dialogDate = true
    },
    closeDialog(item, value) {
      this.dialogDate = false
      if (value === 'start_date') {
        this.startDate = this.fixDataInformation(item)
        this.getScheduleClients()
      }
      if (value === 'end_date') {
        this.endDate = this.fixDataInformation(item)
        this.getScheduleClients()
      }
    },
    updateStartDate(event) {
      this.startDate = event
      this.getScheduleClients()
    },
    updateEndDate(event) {
      this.endDate = event
      this.getScheduleClients()
    },
    getUser() {
      this.$http.get('contaswebcred/get_user/').then((v) => {
        this.userId = [1, 2, 10].includes(v.data.user.id)
      })
    },
    getUsers() {
      this.$http.get('contaswebcred/').then(v => {
        for (let i in v.data) {
          this.usersInstances.push(v.data[i].account_identification)
        }
      })
    },
    cleanFilters() {
      this.searchSchedulesForUser = ''
      this.searchForClientName = ''
      this.searchForCpf = ''
      this.startDate = ''
      this.endDate = ''
    },
    getScheduleClients() {
      let dateParams = {};
      dateParams.start_date = this.startDate
      dateParams.end_date = this.endDate
      this.$http.get(`agendamentos/?scheduled=${this.searchSchedulesForUser}&name=${this.searchForClientName}&cpf=${this.searchForCpf}&interval=${JSON.stringify(dateParams)}`).then(v => {
        this.schedulings = v.data
        this.schedulings.map(s => {
          s.scheduling = this.fixDataInformation(s.scheduling)
          return s
        })
      })
    },
    fixDataInformation(dateTimeObj) {
      let day = dateTimeObj.slice(8, 10)
      let month = dateTimeObj.slice(5, 7)
      let year = dateTimeObj.slice(0, 4)
      return day + '/' + month + '/' + year
    },
    async openClient(value) {
      this.clickCount++;
      clearTimeout(this.clickTimeout);
      if (this.clickCount === 2) {
        await this.$http.get(`clientes/specific_client_scheduling/?client_id=${value.client.id}`).then(v => {
          this.client = v.data.client
        })
        await this.$router.push({name: 'search_response', params: {'client': this.client}})
        this.clickCount = 0
      } else {
        this.clickTimeout = setTimeout(() => {
          this.clickCount = 0;
        }, 1000);
      }
    },
  },
  created() {
    this.getScheduleClients()
    this.getUsers()
    this.getUser()
  },
  watch: {
    searchSchedulesForUser() {
      this.getScheduleClients()
    },
    searchForClientName() {
      this.getScheduleClients()
    },
    searchForCpf() {
      this.getScheduleClients()
    }
  }
}
</script>

<style scoped>

</style>