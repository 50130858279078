<template>
  <div>
    <div class="d-flex" v-if="showGrafics">
      <apexchart :key="chartKey" width="480" type="bar" :options="options" :series="series"></apexchart>
      <v-text-field class="rounded-lg ml-1" style="width: 60px" @blur="patchBusinessDays" dense outlined v-model="businessDays"></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectionGrafics",
  data: () => ({
    showGrafics: true,
    goalId: undefined,
    businessDays: 0,
    options: {
      colors: ['#0fe17e', '#04b260', '#019c50'],
      chart: {
        id: 'vuechart-example'
      },
      xaxis: {
        categories: ['Grafico de Projeção']
      }
    },
    series: [
      {
        name: 'Vendido até hoje',
        data: []
      },
      {
        name: 'Projeção de venda',
        data: []
      },
      {
        name: 'Meta do mês',
        data: []
      }
    ],
    chartKey: 0
  }),
  methods: {
    patchBusinessDays() {
      if (this.goalId) {
        this.$http.patch(`metas/${this.goalId}/`, {
          business_days: this.businessDays
        }).then(() => {
          this.getGraphicInformation()
        })
      }
    },
    getGraphicInformation() {
      this.$http.get(`metas/get_goals/`).then(val => {
        if (val.data.errormessage) {
          this.showGrafics = false
        }
        this.goalId = val.data.id
        this.series[0].data = [val.data.sold_to_date]
        this.series[1].data = [parseFloat(val.data.projection).toFixed(2)]
        this.series[2].data = [val.data.goal]
        this.businessDays = val.data.business_days
        this.chartKey++
      })
    }
  },
  mounted() {
    this.getGraphicInformation()
  }
}
</script>

<style scoped>

</style>