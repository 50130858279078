<template>
  <div>
    <div class="mt-6 mb-6">
      <StandardTitle :labeltitle="'Digitar Proposta'"></StandardTitle>
    </div>
    <div>
      <CreateProposal :proposal_with_client="_cliente"></CreateProposal>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";
import CreateProposal from "@/components/proposals/CreateProposal.vue";

export default {
  name: "WriteProposal",
  props: ['cliente'],
  components: {CreateProposal, StandardTitle},
  computed: {
    _cliente: {
      get() {
        return JSON.parse(localStorage.getItem('cliente'));
      },
      set(val) {
        localStorage.setItem('cliente', JSON.stringify(val));
      }
    }
  },
  created() {
    if (this.cliente) {
      this._cliente = this.cliente
    }
  }
}
</script>

<style scoped>

</style>