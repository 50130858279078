<template>
  <div class="d-flex justify-center align-center flex-column">
    <v-dialog v-model="dialog" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3" style="height: auto; width: auto">
          {{ dialogMessage }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDate" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogDateTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: auto; width: auto">
          <v-date-picker locale="pt-br" class="pa-4" color="#019c50" v-model="picker"></v-date-picker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="closeDialog(picker)">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="clientScheduling === 0 || changeScheduling === true" class="d-flex justify-center align-center">
      <span class="scheduling">Agendar: </span>
      <div class="d-flex justify-center align-content-center" style="width: 220px">
        <v-text-field dense label="Data" v-model="schedulingDate" outlined rounded v-maska data-maska="##/##/####"></v-text-field>
        <v-icon @click="changeModelDate('Agendar para: ', 'scheduling')" class="mb-7" size="40"
                color="#019c50">mdi-calendar-edit
        </v-icon>
      </div>
      <v-textarea class="ml-2" height="80" v-model="observation" outlined dense rounded label="Observação"></v-textarea>
      <v-icon @click="submitScheduling" class="mb-7 ml-2" size="40" color="#019c50">mdi-check-circle</v-icon>
    </div>
    <div v-else class="d-flex justify-center align-center mt-2">
      <span class="scheduling">Cliente agendado para: {{ infoScheduledClient }} </span>
      <v-icon @click="changeScheduling = true; schedulingDate = ''; observation = '';" class="mb-7" size="40" color="#019c50">mdi-pencil</v-icon>
    </div>
    <div v-if="client_info.client_promo" style="margin-top: -10px;">
      <div v-if="client_info.client_promo.coupons > 0 || client_info.client_promo.delivery_coupons > 0" class="d-flex justify-center align-center">
        <span>Cupons: </span>
        <div class="coupons-delivered">
          <span>{{ client_info.client_promo.delivery_coupons }}</span>
        </div>
        <div class="coupons">
          <span>{{ client_info.client_promo.coupons }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center align-center mt-4 ml-2 mr-2" style="width: 70%">
      <v-text-field class="mr-2" label="Base" v-model="baseName" outlined rounded></v-text-field>
      <v-text-field class="ml-2" label="Nome Completo" v-model="fullName" outlined rounded ></v-text-field>
    </div>
    <div class="d-flex justify-center align-center ml-2 mr-2" style="width: 60%">
      <v-text-field class="mr-2" label="CPF" v-model="clientCpf" outlined rounded></v-text-field>
      <v-text-field class="ml-2" label="Data de Nascimento" v-model="birthday" outlined rounded></v-text-field>
    </div>
    <div class="blocks ml-2 mr-2" style="width: 100%">
      <div>
        <v-row>
          <v-col>
            <v-text-field label="Endereço" v-model="clientAdress" outlined rounded style="width: 400px"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="Cidade" v-model="clientCity" outlined rounded style="width: 200px"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="UF" v-model="clientState" outlined rounded style="width: 150px"></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="blocks ml-2 mr-2" style="width: 100%">
      <div>
        <v-textarea label="Telefones" height="80" v-model="phoneNumbers" outlined rounded style="width: 380px"></v-textarea>
      </div>
    </div>
    <div v-if="!client_info.is_siape" class="mb-5" style="display: flex; justify-content: center">
      <v-data-table
          :headers="headersInss"
          :items="benefits"
          :items-per-page="5"
          hide-default-footer
          class="elevation-4 rounded-xl"
      >
      </v-data-table>
    </div>
    <div v-else class="d-flex justify-center align-center mb-5">
      <v-data-table
          :headers="headersSiape"
          :items="benefits"
          :items-per-page="5"
          hide-default-footer
          class="elevation-4 rounded-xl"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldsClientes",
  props: {
    client_info: Object
  },
  data: () => ({
    schedulingDate: '',
    picker: new Date().toISOString().substr(0, 10),
    dialogDate: false,
    dialog: false,
    changeScheduling: false,
    observation: '',

    dialogTitle: '',
    dialogMessage: '',
    dialogDateTitle: '',
    baseName: '',
    fullName: '',
    clientCpf: '',
    clientAdress: '',
    clientCity: '',
    clientState: '',
    birthday: '',
    phoneNumbers: '',

    loanValue: '',
    cardMargin: '',
    cardValue: '',
    cardBnefitValue: '',
    benefit: '',
    especies: '',

    headersInss: [
      {text: 'BENEFÍCIO', value: 'benefit', sortable: false},
      {text: 'ESPÉCIE', value: 'species', sortable: false},
      {text: 'VALOR EMPRÉSTIMO', value: 'loan_amount', sortable: false},
      {text: 'VALOR CARTÃO', value: 'card_value', sortable: false},
      {text: 'VALOR CARTÃO BENEFÍCIO', value: 'card_value_benefit', sortable: false},
    ],
    headersSiape: [
      {text: 'MATRÍCULA', value: 'registration', sortable: false},
      {text: 'UPAG', value: 'upag', sortable: false},
      {text: 'ORGÃO', value: 'public_agency', sortable: false},
      {text: 'VALOR DO EMPRÉSTIMO', value: 'loan_amount', sortable: false},
      {text: 'VALOR CARTÃO', value: 'card_value', sortable: false},
    ],
    benefits: [],
    clientScheduling: undefined,
    infoClient: ''
  }),
  computed: {
    infoScheduledClient() {
      if (this.infoClient !== '') {
        return this.fixDataTimeInformation(this.infoClient.schedule_client[this.infoClient.schedule_client.length -1].scheduling)
      }
      return this.fixDataTimeInformation(this.client_info.schedule_client[this.client_info.schedule_client.length -1].scheduling)
    }
  },
  methods: {
    async submitScheduling() {
      let schedulingParams = {}
      schedulingParams.client_id = this.client_info.id
      schedulingParams.date = this.schedulingDate
      schedulingParams.observation = this.observation
      await this.$http.post('agendamentos/scheduling_client/', {
        params: schedulingParams
      }).then(v => {
        let title = v.data.message === 'Cliente agendado com sucesso!' ? 'Sucesso!' : 'Atenção'
        this.dialog = true
        this.dialogTitle = title
        this.dialogMessage = v.data.message
        if (this.dialogTitle === 'Sucesso!') {
          this.$http.get(`clientes/specific_client_scheduling/?client_id=${this.client_info.id}`).then((v) => {
            this.infoClient = v.data.client
            this.clientScheduling = this.infoClient.schedule_client.length
            this.changeScheduling = false
          })
        }
      })
    },
    changeModelDate(title, value) {
      this.info = value
      this.dialogDateTitle = title
      this.dialogDate = true
    },
    closeDialog(item) {
      this.schedulingDate = this.fixDataTimeInformation(item)
      this.dialogDate = false
    },
    fixDataTimeInformation(dateTimeObj) {
      let day = dateTimeObj.slice(8, 10)
      let month = dateTimeObj.slice(5, 7)
      let year = dateTimeObj.slice(0, 4)
      return day + '/' + month + '/' + year
    },
    setClientInfo(clientInfo) {
      this.clientScheduling = clientInfo.schedule_client.length
      this.baseName = clientInfo.base
      this.fullName = clientInfo.name
      this.clientCpf = clientInfo.cpf
      this.clientAdress = clientInfo.street_avenue_platter
      this.clientCity = clientInfo.city
      this.clientState = clientInfo.state
      this.birthday = clientInfo.birthday
      this.phoneNumbers = clientInfo.phone

      this.benefits = clientInfo.cliente
    }
  },
  created() {
    this.setClientInfo(this.client_info)
  }
}
</script>

<style scoped>
.blocks {
  display: flex;
  justify-content: center;
  justify-self: center;
}

.scheduling {
  margin-top: -30px;
  background-color: #019c50;
  padding: 7px 14px;
  margin-right: 5px;
  border-radius: 50px;
  color: #FFFFFF;
  font-weight: 900;
}

.coupons-delivered {
  background: rgb(1, 156, 80);
  padding: 8px 15px;
  border-radius: 70px;
  margin-left: 10px;
  color: #FFFFFF;
  font-weight: 900;
}

.coupons {
  background: red;
  padding: 8px 15px;
  border-radius: 70px;
  margin-left: 10px;
  color: #FFFFFF;
  font-weight: 900;
}
</style>