import Vue from 'vue'
import Vuex from 'vuex'
import axios from "@/plugins/axios";
import router from "@/router/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: undefined,
    csrfToken: undefined,
    partnerId: undefined,
    partnerActive: undefined
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getCsrfToken(state) {
      return state.csrfToken
    },
    getPartnerId(state) {
      return state.partnerId
    },
    getPartnerActive(state) {
      return state.partnerActive
    }
  },
  mutations: {
    commitUser(state, payload) {
      state.user = payload
    },
    commitCsrfToken(state, payload) {
      state.csrfToken = payload
    },
    commitPartnerId(state, payload) {
      state.partnerId = payload
    },
    commitPartnerActive(state, payload) {
      state.partnerActive = payload
    }
  },
  actions: {
    async setUser({commit}) {
      let userConsultantList = ['Guilherme Almeida', 'Gleyse Santos', 'Joice Mayara', 'Kauane Karen']
      return new Promise(function (resolve) {
        axios.get('send_current_user').then(
            v => {
              commit('commitUser', v.data.user_info)
              commit('commitCsrfToken', v.data.csrf_token)
              commit('commitPartnerId', v.data.partner_id)
              commit('commitPartnerActive', v.data.is_active)
              if (router.currentRoute.name === 'login') {
                if (userConsultantList.includes(v.data.user_info)) {
                  resolve(router.push({name: 'consultant_home'}))
                } else {
                  resolve(router.push({name: 'menu'}))
                }
              } else {
                resolve()
              }
            }
        ).catch(() => {
          if (router.currentRoute.name !== 'login') {
            resolve(router.push({name: 'login'}))
          } else {
            resolve()
          }
        })
      })
    },
    setCsrfToken({commit}, payload) {
      commit('commitCsrfToken', payload)
    },
    setPartnerId({commit}, payload) {
      commit('commitPartnerId', payload)
    },
    setPartnerActive({commit}, payload) {
      commit('commitPartnerActive', payload)
    }
  },
})
