<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
    <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else>
    <v-dialog v-model="dialog" transition="dialog-top-transition" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialogTitle === 'Sucesso!' ? $router.go(-1) : dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mt-5">
      <StandardTitle :labeltitle="'Criar Meta'"></StandardTitle>
    </div>
    <div class="d-flex justify-center flex-column">
      <div class="d-flex justify-center align-center mt-4" style="margin-bottom: -25px">
        <h3 class="mb-6 mr-2 titulo rounded-xl elevation-2">Mês</h3>
        <v-select class="mr-2 rounded-xl" label="Mês" style="max-width: 80px" dense outlined :items="meses" v-model="mes"></v-select>
        <v-select class="rounded-xl" label="Ano" style="max-width: 100px" dense outlined :items="anos" v-model="ano"></v-select>
      </div>
      <div class="d-flex justify-center mt-4">
        <v-text-field label="Valor" class="rounded-xl" v-money="money" v-model="value" style="max-width: 249px" dense outlined></v-text-field>
      </div>
      <div class="d-flex justify-center" style="margin-top: -10px">
        <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="submitMeta">
          Salvar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";
import {VMoney} from 'v-money'

export default {
  name: "CreateMeta",
  directives: {money: VMoney},
  components: {StandardTitle},
  data: () => ({
    pageLoading: true,
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    meses: Array.from({ length: 12 }, (_, index) => index + 1),
    anos: Array.from({ length: 50 }, (_, index) => 2024 + index),
    mes: undefined,
    ano: undefined,
    value: '',
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      suffix: ' ',
      precision: 2,
      masked: false /* doesn't work with directive */
    },
  }),
  methods: {
    submitMeta() {
      this.pageLoading = true
      const partnerId = this.$store.state.partnerId
      const month = this.ano + '-' + this.mes + '-' + 1
      this.$http.post('metas/', {
        partner_id: partnerId,
        target_month: month,
        value: this.value
      }).then(val => {
        if (val.data.errormessage) {
          this.dialog = true
          this.dialogTitle = 'Ops!'
          this.dialogMessage = val.data.errormessage
          this.pageLoading = false
          return
        }
        this.dialog = true
        this.dialogTitle = 'Sucesso!'
        this.dialogMessage = val.data.message
        this.pageLoading = false
      }).catch(() => {
        this.dialog = true
        this.dialogTitle = 'Ops!'
        this.dialogMessage = 'Algo deu errado, contate o adm.'
        this.pageLoading = false
      })
    }
  },
  created() {
    this.pageLoading = false
  }
}
</script>

<style scoped>
.titulo {
  background-color: #019c50;
  padding: 5px 11px;
  color: #FFFFFF
}
</style>