<template>
  <div>
    <v-dialog v-model="dialog"
    transition="dialog-top-transition"
    scrollable width="auto"
    persistent
    >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="$router.push({name: 'listclients'}); dialog = false; loading = true">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="input-all-client">
      <div style="width: 50%">
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Base: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="baseName"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="fullName"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>CPF: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientCpf"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Logradouro: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientAdress"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Numero: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="#"
                            data-maska-tokens="#:[0-9]:multiple"
                            dense v-model="clientAdressNumber"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Bairro: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientNeighbor"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Complemento: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientComplement"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Cidade: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientCity"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>UF: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientState"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>CEP: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="##.###-###"
                            dense v-model="clientPostCode"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Endereços Antigos: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-textarea rounded outlined dense v-model="clientOldAdresses"></v-textarea>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Data de Nascimento: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="##/##/####"
                            dense v-model="birthday"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome do Pai: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="fatherName"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome da Mãe: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="motherName"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>RG: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="registrationId"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Telefones: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-textarea height="100" rounded outlined dense v-model="phoneNumbers"></v-textarea>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Email: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientEmail"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="input-all-client mt-2 mb-4">
          <div>
            <v-btn rounded color="#019c50" :loading="btnLoading" @click="changeClient" style="color: #FFFFFF; text-transform: none">Alterar</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="input-all-client mt-4 mb-6" style="margin-bottom: -18px">
      <h2>Benefícios/Matrículas</h2>
    </div>
    <div class="input-all-client mt-2">
      <div v-for="benefit in benefits" :key="benefit.id">
        <div class="d-flex justify-center align-center mb-5">
          <div v-if="!client_info.is_siape">
            <v-btn x-large rounded @click="openBenefit(benefit.id)" color="#019c50"
                   style="color: #FFFFFF; font-size: 1.2em;"
            > {{ benefit.benefit }}
            </v-btn>
          </div>
          <div v-else>
            <v-btn x-large rounded @click="openBenefit(benefit.id)" color="#019c50"
                   style="color: #FFFFFF; font-size: 1.2em;"
            > {{ benefit.registration }}
            </v-btn>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Salário: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.salary"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Margem: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.margin"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Valor Empréstimo: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.loan_amount"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Margem Cartão: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.card_margin"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Valor Cartão: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.card_value"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Data de Concessão: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.grant_date"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Espécie: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.species"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Data de Recebimento: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.receiving_date"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Banco: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.bank"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Agência: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.bank_agency"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Conta: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.bank_account"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Margem Cartão Benefício:</h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.card_margin_benefit"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Valor Cartão Benefício:</h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.card_value_benefit"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>UF Benefício: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.benefit_uf"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Quantidade de Empréstimos: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.loan_proposal_quantity"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Bloqueio Empréstimo: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.loan_lock"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Valor Parcela: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.portion_value"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Prazo Parcela: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.portion_deadline"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Banco Parcela: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.portion_bank"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Quantidade de Parcelas Pagas: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.number_of_portion_paid"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Início Desconto: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.start_discount"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Final Desconto: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.end_discount"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Saldo Devedor Parcela: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.portion_debit_balance"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Representante Legal:</h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.name_legal_representative"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>CPF Representante Legal:</h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.cpf_legal_representative"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Matrícula: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.registration"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Orgão Público: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.public_agency"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Instituição: </h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.institution"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>UPAG:</h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.upag"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="input-all-client mt-2 mr-6 ml-6" style="margin-bottom: -18px">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1" cols="4" md="7">
                <h3>Situação Funcional:</h3>
              </v-col>
              <v-col cols="4" md="5">
                <v-text-field rounded outlined dense v-model="benefit.functional_status"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AllFieldsClientes",
  props: {
    client_info: Object
  },
  data: () => ({
    baseName: '',
    fullName: '',
    clientCpf: '',
    clientAdress: '',
    clientAdressNumber: '',
    clientNeighbor: '',
    clientComplement: '',
    clientCity: '',
    clientState: '',
    clientPostCode: '',
    clientOldAdresses: '',
    birthday: '',
    fatherName: '',
    motherName: '',
    registrationId: '',
    phoneNumbers: '',
    clientEmail: '',
    idClient: '',

    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    loading: false,
    btnLoading: false,
  }),
  methods: {
    setClientInfo(clientInfo) {
      this.idClient = clientInfo.id
      this.baseName = clientInfo.base
      this.fullName = clientInfo.name
      this.clientCpf = clientInfo.cpf
      this.clientAdress = clientInfo.street_avenue_platter
      this.clientAdressNumber = clientInfo.number
      this.clientNeighbor = clientInfo.neighbor
      this.clientComplement = clientInfo.complement
      this.clientCity = clientInfo.city
      this.clientState = clientInfo.state
      this.clientPostCode = clientInfo.post_code
      this.clientOldAdresses = clientInfo.old_adresses
      this.birthday = clientInfo.birthday
      this.fatherName = clientInfo.father_name
      this.motherName = clientInfo.mother_name
      this.registrationId = clientInfo.registry
      this.clientEmail = clientInfo.email
      this.phoneNumbers = clientInfo.phone
      this.is_siape = clientInfo.is_siape

      this.benefits = clientInfo.cliente
    },
    openBenefit(idBenefit) {
      this.$http.get(`beneficios/?id=${idBenefit}`).then((v) => {
        this.$router.push({name: 'change_benefit', params: {'benefitInfo': v.data}})
      })
    },
    changeClient() {
      this.btnLoading = true
      this.$http.post('clientes/update_client_instance/', {
        id_client: this.idClient,
        base: this.baseName,
        name: this.fullName,
        cpf: this.clientCpf,
        birthday: this.birthday,
        father_name: this.fatherName,
        mother_name: this.motherName,
        registry: this.registrationId,
        street_avenue_platter: this.clientAdress,
        number: this.clientAdressNumber,
        neighbor: this.clientNeighbor,
        complement: this.clientComplement,
        city: this.clientCity,
        state: this.clientState,
        post_code: this.clientPostCode,
        email: this.clientEmail,
        phone: this.phoneNumbers,
        is_siape: this.is_siape
      }).then((v) => {
        this.dialog = true
        this.dialogTitle = 'Sucesso!'
        this.dialogMessage = v.data.message
      }).catch((v) => {
        this.dialog = true
        this.dialogTitle = 'Erro!'
        this.dialogMessage = `${v.response.data.error} Contate o administrador do sistema`
      })
      this.btnLoading = false
    }
  },
  created() {
    this.setClientInfo(this.client_info)
  }
}
</script>

<style scoped>

.input-all-client {
  display: flex;
  justify-content: center;
}
.space-between-fields {
  margin-bottom: -28px;
}
</style>