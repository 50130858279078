<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
     <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else>
    <v-dialog v-model="dialog" transition="dialog-top-transition" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialogTitle === 'Sucesso!' ? $router.go(-1) : dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mt-8">
      <StandardTitle :labeltitle="'Informações do Parceiro'"></StandardTitle>
    </div>
    <div class="d-flex justify-center align-center">
      <div class="title-proposal rounded-pill">
        <span>Parceiro Webcred id: {{$attrs.id}}</span>
      </div>
    </div>
    <div class="d-flex justify-center align-center flex-column">
      <div class="mt-5">
        <v-text-field rounded outlined dense label="Nome da Empresa" style="min-width: 420px" v-model="companyName"></v-text-field>
      </div>
      <div>
        <v-text-field rounded outlined dense label="CNPJ da Empresa" style="min-width: 420px" v-model="companyCnpj"></v-text-field>
      </div>
      <div>
        <v-text-field rounded outlined dense label="Endereço" @blur="atualizarWebcredPartner('partner_adress', companyAdress)" style="min-width: 420px" v-model="companyAdress"></v-text-field>
      </div>
      <div>
        <v-text-field rounded outlined dense label="Cidade" style="min-width: 420px" @blur="atualizarWebcredPartner('partner_city', companyCity)" v-model="companyCity"></v-text-field>
      </div>
      <div>
        <v-select rounded outlined dense label="Estado" style="min-width: 420px" @blur="atualizarWebcredPartner('partner_state', companyState)" v-model="companyState" :items="states"></v-select>
      </div>
      <div>
        <v-text-field rounded outlined dense label="Telefone" v-maska data-maska="['(##) #####-####', '(##) ####-####']" @blur="atualizarWebcredPartner('partner_phone', phoneNumber)" style="min-width: 420px" v-model="phoneNumber"></v-text-field>
      </div>
      <div>
        <v-text-field rounded outlined dense label="Responsável" style="min-width: 420px" v-model="responsible"></v-text-field>
      </div>
      <div v-if="showPhoto" style="transform: translateY(-10px)">
        <v-img class="rounded-circle" :src="photoToShow"
          height="200"
          width="200"></v-img>
        <v-icon style="transform: translateY(-59px);" @click="photo = null; showPhoto = false" size="40" color="#019c50">mdi-pencil-circle</v-icon>
      </div>
      <div v-else class="d-flex">
        <v-file-input v dense rounded outlined label="Foto" style="min-width: 350px" v-model="photo"></v-file-input>
        <v-icon style="transform: translateY(-15px);" size="40" color="#019c50" @click="atualizarFoto('logo', photo)">mdi-send-variant</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "ShowPartner",
  components: {StandardTitle},
  data: () => ({
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    companyName: '',
    companyCnpj: '',
    companyAdress: '',
    companyCity: '',
    companyState: '',
    states: [
      "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE",
      "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO",
    ],
    phoneNumber: '',
    responsible: '',
    photo: null,
    photoToShow: '',
    showPhoto: true,
    pageLoading: true
  }),
  methods: {
    atualizarWebcredPartner(field, value) {
      this.$http.patch(`parceiros/${this.$attrs.id}/`, {
        [field]: value
      }).then(val => {
        this.definirVariaveis(val)
      })
    },
    definirVariaveis(val) {
      this.companyName = val.data.partner
      this.companyCnpj = val.data.partner_cnpj
      this.companyAdress = val.data.partner_adress
      this.companyCity = val.data.partner_city
      this.companyState = val.data.partner_state
      this.phoneNumber = val.data.partner_phone
      this.responsible = val.data.responsible
      this.photoToShow = val.data.logo
      if (this.photoToShow) {
        this.showPhoto = true
      }
      this.pageLoading = false
    },
    atualizarFoto(field, value) {
      const formData = new FormData();
      formData.append(field, value);
      this.$http.patch(`parceiros/${this.$attrs.id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(val => {
        this.definirVariaveis(val)
      })
    },
    getWebcredPartner() {
      this.$http.get(`parceiros/${this.$attrs.id}`).then(val => {
        this.definirVariaveis(val)
      })
    }
  },
  created() {
    this.getWebcredPartner()
  }
}
</script>

<style scoped>
.title-proposal {
  font-size: 1.3em;
  font-weight: 900;
  background-color: rgb(1, 156, 80);
  height: 50px;
  margin-top: 30px;
  padding-top: 10px;
  padding-left: 19px;
  padding-right: 19px;
  color: #FFFFFF;
}
</style>