<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
     <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else>
    <v-dialog v-model="dialog" transition="dialog-top-transition" persistent scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="display: flex; justify-content: center; text-align: justify;">
              <div style="font-size: 1.2em; padding: 10px; margin-top: 15px; width: 400px; text-align-last: center;">
                {{ dialogMessage }}
              </div>
            </div>
            <div v-if="dialogTitle !== 'Redefinição de senha'" class="d-flex justify-center">
              <span>{{ userName }}</span>
            </div>
            <div v-else class="d-flex justify-center align-center flex-column">
              <div class="mb-3" style="width: 300px">
                <v-otp-input length="6" v-model="code"></v-otp-input>
              </div>
              <v-form v-model="formValid" ref="form" @submit.prevent :lazy-validation="false">
                <v-text-field v-model="password" :rules="passwordRules" dense rounded outlined label="Senha" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>
                <v-text-field v-model="confirmPassword" :rules="[...passwordRules, v => v === password || 'As senhas não correspondem']" dense rounded outlined label="Confirmar Senha" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showConfirmPassword ? 'text' : 'password'" @click:append="showConfirmPassword = !showConfirmPassword"></v-text-field>
              </v-form>
              <div v-for="(rule, index) in passwordArrayRules" :key="index" style="margin-top: -10px">
                <span style="font-weight: bolder; font-size: 0.7em; opacity: 80%">*</span>
                <span style="font-size: 0.7em; opacity: 80%">{{ rule }}</span>
              </div>
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="dialogTitle === 'Atenção!'" class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#fe0000" :loading="loading" variant="text" @click="dialog = false;">
            Não
          </v-btn>
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="_deleteUser(); dialog = false; loading = true;">
            Sim
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="dialogTitle === 'Sucesso!'" class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="$router.push({name: 'users'})">
            Ok
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="changePassword();">
            Redefinir Senha
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mt-5 mb-5">
      <StandardTitle :labeltitle="'Usuário'"></StandardTitle>
    </div>
    <div class="d-flex justify-center align-center flex-column mt-6">
      <div style="width: 400px">
        <div class="d-flex justify-center mb-6">
          <span class="title-proposal rounded-pill">Usuário: {{ $attrs.id }}</span>
          <v-icon @click="deleteUser" size="30" color="#fe0000">mdi-delete</v-icon>
        </div>
        <div>
          <v-text-field dense rounded outlined v-model="userName" label="Usuário"></v-text-field>
          <div class="d-flex justify-center align-center link-alterar-senha">
            <span>alterar senha</span>
            <v-icon @click="requestChangePassword" size="20" color="#019c50">mdi-send-circle</v-icon>
          </div>
          <v-select dense rounded outlined v-model="category" @blur="atualizarWebcredUser('user_category', category)" :items="categories" label="Perfil"></v-select>
          <v-text-field dense rounded outlined v-model="accountName" label="Nome da Conta"></v-text-field>
          <v-text-field dense rounded outlined v-model="accountEmail" @blur="atualizarWebcredUser('email', accountEmail)" label="Email"></v-text-field>
          <div v-if="!showPhoto" class="d-flex">
            <v-file-input v dense rounded outlined label="Foto" v-model="photo"></v-file-input>
            <v-icon style="transform: translateY(-15px);" size="40" color="#019c50" @click="atualizarFoto('user_photo', photo)">mdi-send-variant</v-icon>
          </div>
        </div>
      </div>
      <div v-if="showPhoto" style="transform: translateY(-10px)">
        <v-img class="rounded-circle" :src="photoToShow"
          height="200"
          width="200"></v-img>
        <v-icon style="transform: translateY(-59px);" @click="photo = null; showPhoto = false" size="40" color="#019c50">mdi-pencil-circle</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "WebcredUser",
  components: {StandardTitle},
  data: () => ({
    webcredUserInstance: undefined,
    userId: 0,
    userName: '',
    categories: ['Administrador', 'Supervisor', 'Consultor'],
    category: '',
    accountName: '',
    accountEmail: '',
    photo: null,
    photoToShow: '',
    showPhoto: false,
    loading: false,
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    code: '',
    password: '',
    confirmPassword: '',
    passwordRules: [
      v => !!v || 'As senhas são obrigatórias',
      v => (v && v.length >= 8) || 'A senha deve ter pelo menos 8 caracteres',
      v => /[!@#$%^&*(),.?":{}|<>]/.test(v) || 'A senha deve conter pelo menos um caractere especial',
      v => /[a-z]/.test(v) && /[A-Z]/.test(v) || 'A senha deve conter pelo menos uma letra maiúscula e uma letra minúscula',
      v => /\d/.test(v) || 'A senha deve conter pelo menos um número',
      v => !/\s/.test(v) || 'A senha não pode conter espaços em branco',
    ],
    passwordArrayRules: [
      'A senha deve ter pelo menos 8 caracteres',
      'A senha deve conter pelo menos um caractere especial',
      'A senha deve conter pelo menos uma letra maiúscula e uma letra minúscula',
      'A senha deve conter pelo menos um número',
      'A senha não pode conter espaços em branco'
    ],
    showPassword: false,
    showConfirmPassword: false,
    pageLoading: false,
    formValid: false
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    changePassword() {
      this.dialog = false
      this.pageLoading = true
      this.$http.post('contaswebcred/change_user_password/', {
        user_id: this.userId,
        code: this.code,
        password: this.password,
        confirm_password: this.confirmPassword
      }).then(val => {
        if (val.data.errormessage) {
          this.dialog = true
          this.dialogMessage = val.data.errormessage
          this.dialogTitle = 'Ops!'
          this.pageLoading = false
          return
        }
        if (val.data.message) {
          this.dialog = true
          this.dialogMessage = val.data.message
          this.dialogTitle = 'Sucesso!'
          this.pageLoading = false
          return
        }
      }).catch(val => {
        console.error(val.data)
      })
    },
    requestChangePassword() {
      this.pageLoading = true
      this.$http.post('contaswebcred/request_password_change/', {
        user_id: this.userId
      }).then(val => {
        if (val.data.success) {
          this.dialog = true
          this.dialogTitle = 'Redefinição de senha'
          this.dialogMessage = 'Para redefinir sua senha, verifique seu e-mail e insira o código de identificação fornecido. ' +
              'Preencha o código e a nova senha desejada para a conta. Obrigado!'
          this.pageLoading = false
        }
      })
    },
    deleteUser() {
      setTimeout(function() {}, 1300);
      this.dialog = true
      this.dialogTitle = 'Atenção!'
      this.dialogMessage = 'Essa ação excluirá o usuário: '
    },
    _deleteUser() {
      setTimeout(function() {}, 1300);
      this.$http.delete(`contaswebcred/${this.userId}/`).then(val => {
        this.dialog = true
        this.dialogTitle = 'Sucesso!'
        this.dialogMessage = val.data
        this.loading = false
      }).catch(val => {
        console.error(val.data.error)
      })
    },
    definirVariaveis(val) {
      this.userName = val.system_user.username
      this.category = val.user_category
      this.accountName = val.account_identification
      this.photoToShow =  val.user_photo ? val.user_photo : undefined
      this.accountEmail = val.email
      if (this.photoToShow) {
        this.showPhoto = true
      }
    },
    atualizarWebcredUser(field, value) {
      this.$http.patch(`contaswebcred/${this.userId}/`, {
        [field]: value
      }).then(val => {
        this.definirVariaveis(val.data)
      })
    },
    atualizarFoto(field, value) {
      const formData = new FormData();
      formData.append(field, value);
      this.$http.patch(`contaswebcred/${this.userId}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(val => {
        this.definirVariaveis(val.data)
      })
    },
    async getWebcredUser() {
      this.userId = this.$attrs.id
      await this.$http.get(`contaswebcred/${this.userId}/`).then(val => {
        this.webcredUserInstance = val.data
        this.userName = this.webcredUserInstance.system_user.username
        this.category = this.webcredUserInstance.user_category
        this.accountName = this.webcredUserInstance.system_user.first_name
        this.accountEmail = this.webcredUserInstance.email
        this.photoToShow = this.webcredUserInstance.user_photo
        if (this.photoToShow) {
          this.showPhoto = true
        }
      })
    },
  },
  activated() {
    this.getWebcredUser()
  },
  deactivated() {
    this.$destroy()
  }
}
</script>

<style scoped>
.title-proposal {
  font-size: 1.3em;
  font-weight: 900;
  color: white;
  background-color: rgb(1, 156, 80);
  height: 50px;
  padding-top: 10px;
  padding-left: 19px;
  padding-right: 19px;
}

.link-alterar-senha {
  margin-top: -27px;
  font-size: 0.8em;
  color: blue;
  margin-bottom: 13px;
}
</style>