<template>
  <v-layout class="d-flex justify-center align-center" wrap>
      <v-flex xs6 sm4 md6 lg3>
          <div class="standard-sub-title standard-title d-flex justify-space-around">
              <slot>{{ labeltitle }}</slot>
          </div>
      </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "StandardTitle",
  props: {
    labeltitle: String
  }
}
</script>

<style scoped>
.standard-title {
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}
.standard-sub-title {
  font-size: 1.5em;
}
</style>