<template>
  <div style="display: flex; justify-content: center">
    <v-dialog v-model="dialog"
    transition="dialog-top-transition"
    scrollable width="auto"
    >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="$router.push({name: 'listclients'}); dialog = false; loading = true;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <div class="d-flex justify-center align-center">
        <div>
          <v-text-field class="ml-2 mr-2"
                        style="width: 150px"
                        rounded dense label="Salário"
                        :rules="currencyRules"
                        outlined v-model="salary"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" :rules="currencyRules"
                        style="width: 150px" rounded dense label="Margem" outlined v-model="margin"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" :rules="currencyRules"
                        style="width: 150px" rounded dense label="Valor Empréstimo"
                        outlined v-model="loanAmount"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" :rules="currencyRules"
                        style="width: 150px" rounded
                        dense label="Margem Cartão" outlined v-model="cardMargin"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" :rules="currencyRules"
                        style="width: 150px" rounded
                        dense label="Valor Cartão" outlined v-model="cardValue"></v-text-field>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div>
          <v-text-field class="ml-2 mr-2"
                        v-maska
                        data-maska="##/##/####"
                        style="width: 180px" rounded dense label="Data de Concessão" outlined v-model="grantDate"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 100px" rounded dense label="Espécie" outlined v-model="species"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2"
                        style="width: 220px" rounded dense
                        v-maska
                        data-maska="##/##/####"
                        label="Data de Recebimento" outlined v-model="receivingDate"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" :rules="currencyRules"
                        style="width: 270px" rounded
                        dense label="Margem Cartão Benefício" outlined v-model="cardMarginBenefit"></v-text-field>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div>
          <v-text-field class="ml-2 mr-2" :rules="currencyRules"
                        style="width: 270px" rounded
                        dense label="Valor Cartão Benefício" outlined v-model="cardValueBenefit"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 270px" rounded dense label="Banco" outlined v-model="bank"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 120px" rounded dense label="Agência" outlined v-model="bankAgency"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 110px" rounded dense label="Conta" outlined v-model="bankAccount"></v-text-field>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div>
          <v-text-field class="ml-2 mr-2"
                        style="width: 150px"
                        v-maska
                        data-maska="@@"
                        data-maska-tokens="@:[A-Z]"
                        rounded dense label="UF Benefício" outlined v-model="benefitUf"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2"
                        style="width: 280px" rounded
                        v-maska
                        data-maska="#"
                        data-maska-tokens="#:[0-9]:multiple"
                        dense label="Quantidade de Empréstimos" outlined
                      v-model="loanProposalQuantity"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 220px" rounded dense label="Bloqueio Empréstimo" outlined v-model="loanLock"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" :rules="currencyRules"
                        style="width: 170px" rounded
                        dense label="Valor Parcela" outlined v-model="portionValue"></v-text-field>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div>
          <v-text-field class="ml-2 mr-2"
                        style="width: 170px" rounded
                        dense label="Prazo Parcela" outlined v-model="portionDeadline"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 170px" rounded dense label="Banco Parcela" outlined v-model="portionBank"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 230px" rounded dense label="Qtd de Parcelas Pagas" outlined
                        v-model="numberOfPortionPaid"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" :rules="currencyRules"
                        style="width: 230px" rounded
                        dense label="Saldo Devedor Parcela" outlined v-model="portionDebitBalance"></v-text-field>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div>
          <v-text-field class="ml-2 mr-2"
                        style="width: 180px" rounded
                        v-maska
                        data-maska="##/##/####"
                        dense label="Início Desconto" outlined v-model="startDiscount"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2"
                        style="width: 170px" rounded
                        v-maska
                        data-maska="##/##/####"
                        dense label="Final Desconto" outlined v-model="endDiscount"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 230px" rounded dense label="Representante Legal" outlined v-model="nameLegalRepresentative"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 250px" rounded dense label="CPF Representante Legal" outlined
                        v-model="cpfLegalRepresentative"></v-text-field>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 130px" rounded dense label="Matrícula" outlined v-model="registration"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 170px" rounded dense label="Orgão Público" outlined v-model="publicAgency"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 170px" rounded dense label="Instituição" outlined v-model="institution"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 120px" rounded dense label="UPAG" outlined v-model="upag"></v-text-field>
        </div>
        <div>
          <v-text-field class="ml-2 mr-2" style="width: 200px" rounded dense label="Situação Funcional" outlined v-model="functionalStatus"></v-text-field>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
      <div>
        <v-btn rounded @click="changeBenefit" :loading="btnLoading" color="#019c50" style="color: #FFFFFF; text-transform: none">Alterar</v-btn>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldsBenefit",
  props: {
    benefitInformation: Object
  },
  methods: {
    adjustBenefitInformation(benefitStats){
      this.bank = benefitStats.bank
      this.bankAccount = benefitStats.bank_account
      this.bankAgency = benefitStats.bank_agency
      this.benefit = benefitStats.benefit
      this.benefitUf = benefitStats.benefit_uf
      this.cardMargin = benefitStats.card_margin
      this.cardMarginBenefit = benefitStats.card_margin_benefit
      this.cardValue = benefitStats.card_value
      this.cardValueBenefit = benefitStats.card_value_benefit
      this.cpfLegalRepresentative = benefitStats.cpf_legal_representative
      this.endDiscount = benefitStats.end_discount
      this.functionalStatus = benefitStats.functional_status
      this.grantDate = benefitStats.grant_date
      this.idBenefit = benefitStats.id
      this.institution = benefitStats.institution
      this.loanAmount = benefitStats.loan_amount
      this.loanLock = benefitStats.loan_lock
      this.loanProposalQuantity = benefitStats.loan_proposal_quantity
      this.margin = benefitStats.margin
      this.nameLegalRepresentative = benefitStats.name_legal_representative
      this.numberOfPortionPaid = benefitStats.number_of_portion_paid
      this.portionBank = benefitStats.portion_bank
      this.portionDeadline = benefitStats.portion_deadline
      this.portionDebitBalance = benefitStats.portion_debit_balance
      this.portionValue = benefitStats.portion_value
      this.publicAgency = benefitStats.public_agency
      this.receivingDate = benefitStats.receiving_date
      this.registration = benefitStats.registration
      this.salary = benefitStats.salary
      this.species = benefitStats.species
      this.startDiscount = benefitStats.start_discount
      this.upag = benefitStats.upag
    },
    changeBenefit() {
      this.btnLoading = true
      this.$http.post('beneficios/update_benefit_instance/', {
        bank: this.bank,
        bank_account: this.bankAccount,
        bank_agency: this.bankAgency,
        benefit: this.benefit,
        benefit_uf: this.benefitUf,
        card_margin: this.cardMargin,
        card_margin_benefit: this.cardMarginBenefit,
        card_value: this.cardValue,
        card_value_benefit: this.cardValueBenefit,
        cpf_legal_representative: this.cpfLegalRepresentative,
        end_discount: this.endDiscount,
        functional_status: this.functionalStatus,
        grant_date: this.grantDate,
        id_benefit: this.idBenefit,
        institution: this.institution,
        loan_amount: this.loanAmount,
        loan_lock: this.loanLock,
        loan_proposal_quantity: this.loanProposalQuantity,
        margin: this.margin,
        name_legal_representative: this.nameLegalRepresentative,
        number_of_portion_paid: this.numberOfPortionPaid,
        portion_bank: this.portionBank,
        portion_deadline: this.portionDeadline,
        portion_debit_balance: this.portionDebitBalance,
        portion_value: this.portionValue,
        public_agency: this.publicAgency,
        receiving_date: this.receivingDate,
        registration: this.registration,
        salary: this.salary,
        species: this.species,
        start_discount: this.startDiscount,
        upag: this.upag,
      }).then((v) => {
        this.dialog = true
        this.dialogTitle = 'Sucesso!'
        this.dialogMessage = v.data.message
      }).catch((v) => {
        this.dialog = true
        this.dialogTitle = 'Erro!'
        this.dialogMessage = `${v.response.data.error} Contate o administrador do sistema`
      })
    },
  },
  data: () => ({
    currencyRules: [
      value => /^[0-9]{1,3}(?:\.[0-9]{3})*(?:,[0-9]{2})?$/.test(value) || 'Formato de moeda inválido',
    ],
    bank: '',
    bankAccount: '',
    bankAgency: '',
    benefit: '',
    benefitUf: '',
    cardMargin: '',
    cardMarginBenefit: '',
    cardValue: '',
    cardValueBenefit: '',
    cpfLegalRepresentative: '',
    endDiscount: '',
    functionalStatus: '',
    grantDate: '',
    idBenefit: '',
    institution: '',
    loanAmount: '',
    loanLock: '',
    loanProposalQuantity: '',
    margin: '',
    nameLegalRepresentative: '',
    numberOfPortionPaid: '',
    portionBank: '',
    portionDeadline: '',
    portionDebitBalance: '',
    portionValue: '',
    publicAgency: '',
    receivingDate: '',
    registration: '',
    salary: '',
    species: '',
    startDiscount: '',
    upag: '',

    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    loading: false,
    btnLoading: false
  }),
  created() {
    this.adjustBenefitInformation(this.benefitInformation)
  },
}
</script>

<style scoped>
</style>