import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import pt from 'vuetify/es5/locale/pt';
import arrow_up from "@/assets/svgs/arrow_up.vue";
import arrow_down from "@/assets/svgs/arrow_down.vue";
import client_icon from "@/assets/svgs/client_icon.vue";
import proposal_icon from "@/assets/svgs/proposal_icon.vue";

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdiSvg',  || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      arrow_up_animated: {
        component: arrow_up
      },
      arrow_down_animated: {
        component: arrow_down
      },
      client_icon: {
        component: client_icon
      },
      proposal_icon: {
        component: proposal_icon
      }
    }
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
})
