<template>
  <div>
    <div class="d-flex justify-center align-center">
      <div class="d-flex justify-center align-center">
        <div class="ml-2 mr-2">
          <v-card @click="openServices" class="mt-12" color="#019c50" style="width: 320px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
            <div style="padding: 16px">
              <div class="d-flex justify-center align-center mb-6">
                <div>
                  <h3 style="color: #FFFFFF; font-size: 1.4em">Atendimentos Call Center </h3>
                </div>
              </div>
              <div class="mb-6" style="display: flex; justify-content: center">
                <div>
                  <v-icon color="#FFFFFF" style="font-size: 9em">mdi-headset</v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div class="ml-2 mr-2">
          <v-card @click="followUpProposal" class="mt-12" color="#019c50" style="width: 320px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
            <div style="padding: 16px">
              <div class="d-flex justify-center align-center mb-6">
                <div>
                  <h3 style="color: #FFFFFF; font-size: 1.4em">Acompanhamento</h3>
                </div>
              </div>
              <div class="mb-6" style="display: flex; justify-content: center">
                <div>
                  <v-icon color="#FFFFFF" style="font-size: 9em">mdi-poll</v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="d-flex justify-center align-center">
        <div class="ml-2 mr-2">
          <v-card @click="openSchedulings" class="mt-12" color="#019c50" style="width: 320px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
            <div id="myDiv" ref="myDiv" @animationstart="playAudio" v-show="showNotificationDiv" class="notification rounded-xl elevation-3 shake justify-end">
              <div>{{ scheduled }}</div>
            </div>
            <div style="padding: 16px">
              <div class="d-flex justify-center align-center mb-6">
                <div>
                  <h3 style="color: #FFFFFF; font-size: 1.4em">Agendamentos</h3>
                </div>
              </div>
              <div class="mb-6" style="display: flex; justify-content: center">
                <div>
                  <v-icon color="#FFFFFF" style="font-size: 9em">mdi-calendar-clock</v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center align-center">
      <div class="ml-2 mr-2">
        <v-card @click="openCouponsProposals" class="mt-6" color="#019c50" style="width: 320px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
          <div style="padding: 16px">
            <div class="d-flex justify-center align-center mb-6">
              <div>
                <h3 style="color: #FFFFFF; font-size: 1.4em">Gerenciar Cupons</h3>
              </div>
            </div>
            <div class="mb-6" style="display: flex; justify-content: center">
              <div>
                <v-icon color="#FFFFFF" style="font-size: 9em">mdi-ticket-account</v-icon>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>

import life_up from '@/assets/soundeffects/life_up.wav';
import { Howl } from 'howler';

export default {
  name: "ViewMenu",
  components: {
  },
  data: () => ({
    services: null,
    scheduled: 0,
    notification: life_up,
    sound: null,
    dialog: true,
    dialogTitle: 'Bem-vindo!',
    dialogMessage: '',
    banner: null
  }),
  computed: {
    showNotificationDiv() {
      return this.scheduled > 0
    }
  },
  methods: {
    openCouponsProposals() {
      this.$router.push({name: 'listproposals', params: {coupons_flag: true}})
    },
    openServices() {
      this.$router.push({name: 'showservices'})
    },
    followUpProposal() {
      this.$router.push({name: 'follow_up'})
    },
    openSchedulings() {
      this.$router.push({name: 'showschedulings'})
    },
    getScheduledClients() {
      this.$http.get('agendamentos/get_count_daily_scheduling/').then((v) => {
        this.scheduled = v.data.count
      })
    },
    playAudio() {
      this.sound.play();
    },
  },
  created() {
    this.getScheduledClients()
  },
  mounted() {
    this.sound = new Howl({
      src: [life_up],
    });
  },
}
</script>

<style scoped>
.notification {
  position: absolute;
  background-color: #FF0000;
  padding: 3px 10px;
  color: #FFFFFF;
  font-size: 1em;
  font-weight: 900;
  margin-top: -13px;
  margin-left: 10px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(3px);
  }
  20% {
    transform: translateX(-3px);
  }
  30% {
    transform: translateX(0px);
  }
  40% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(0px);
  }
  70% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(-3px);
  }
  90% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.7s;
}

</style>