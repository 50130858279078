<template>
  <div>
    <v-snackbar v-model="snackbar" position="relative" timeout="-1">
      <div class="text-subtitle-1 pb-2">Aviso Importante: Licença de Uso Expirada</div>
      <p>
        {{ snackBarText }}
      </p>
      <div class="d-flex flex-column">
        <span>Atenciosamente:</span>
        <span>Kevin Tecnologia Sistemas</span>
      </div>
    </v-snackbar>
    <v-dialog v-model="dialog"
    transition="dialog-top-transition"
    scrollable width="auto"
    >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="login-box">
      <div class="login">
        <h2 style="margin-top: 30px">Login</h2>
        <v-icon style="color: #019c50; font-size: 150px; margin-top: 30px;">mdi-account-circle</v-icon>
      </div>
      <div class="login-box-all">
        <div class="user-box">
          <input @keyup.enter="login" v-model="user" type="text">
          <label>Usuário</label>
        </div>
        <div class="user-box">
          <input @keyup.enter="login" v-model="password" type="password">
          <label>Senha</label>
        </div>
      </div>
      <div class="button-form">
        <v-btn style="text-transform: none" :loading="loading" id="submit" @click="login()">Entrar</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      user: '',
      password: '',
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      loading: false,
      snackbar: false,
      snackBarText:
          'Prezado usuário,\n' +
          '\n' +
          'Gostaríamos de informar que a licença de uso do software expirou. Isso significa que o acesso às funcionalidades do programa está temporariamente suspenso até que a licença seja renovada.\n' +
          '\n' +
          'Por favor, entre em contato com o administrador do sistema o mais rápido possível para resolver essa questão. Eles estarão disponíveis para fornecer orientações sobre como proceder para renovar a licença e restaurar o acesso ao software.\n' +
          '\n' +
          'Agradecemos pela sua atenção e colaboração nesta questão.\n'
    }
  },
  computed: {},
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$store.dispatch('setUser')
      }
    }
  },
  methods: {
    login() {
      this.loading = true
      this.$http.post('v1/api_login',
  {'user_id': this.user, 'password': this.password}).then(v => {
        this.dialog = true
        this.dialogTitle = 'Sucesso!'
        this.dialogMessage = v.data.msg
      }).catch((v) => {
        this.dialog = true
        this.dialogTitle = 'Erro!'
        this.dialogMessage = `${v.response.data.error} Contate o administrador do sistema`
      })
      this.loading = false
    }
  },
  created() {
    const isActive = this.$store.getters['getPartnerActive']
    if (isActive === false) {
      this.snackbar = true
    }
  }
}
</script>

<style scoped>
.logo {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  color: #019c50;
  font-size: 50px;
}

.login-box-all {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  justify-self: center;
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: whitesmoke;
  box-shadow: 0 15px 25px #019c50;
  border-radius: 10px;
}

.login {
  margin: 0 0 30px;
  padding: 0;
  color: #019c50;
  text-align: center;
}

.login-box .user-box input {
  position: relative;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: black;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: relative;
  left: 0;
  top: -60px;
  padding: 10px 0;
  font-size: 16px;
  color: black;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -85px;
  left: 0;
  color: black;
  font-size: 12px;
}

#submit {
  padding: 10px 20px;
  color: black;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  letter-spacing: 4px;
  border: 1px solid black;
  margin: auto;
}

#submit:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #019c50;
  border-radius: 5px;
  box-shadow: 0 0 5px #019c50, 0 0 25px #019c50, 0 0 50px #019c50, 0 0 100px #019c50;
}

.button-form {
  display: flex;
}

</style>