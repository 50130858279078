import axios from 'axios';
import Vue from "vue";
import store from "@/store/store";

const isProd = process.env.NODE_ENV === 'production'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = isProd === true ? 'https://webcred.kevintecnologiasistemas.com.br/api' : 'http://127.0.0.1:8000/api';
axios.interceptors.request.use(config => {
    config.headers["x-csrftoken"] = store.getters['getCsrfToken'];
    return config
}, error => Promise.reject(error));

Vue.use({
        install(Vue) {
            Vue.prototype.$http = axios.create({
                baseURL: isProd === true ? 'https://webcred.kevintecnologiasistemas.com.br/api' : 'http://127.0.0.1:8000/api',
                //baseURL: 'http://0.0.0.0:8000/api',
                headers: {
                    "Content-Type": "application/json",
                },
            });
            Vue.prototype.$http.interceptors.request.use(config => {
                config.headers["x-csrftoken"] = store.getters['getCsrfToken'];
                return config
            }, error => Promise.reject(error));
        }
    }
)
export default axios
