<template>
  <div>
    <div class="mt-6">
      <StandardTitle :labeltitle="'Informações do Benefício'"></StandardTitle>
    </div>
    <div class="mt-6">
      <FieldsBenefit :benefitInformation="_benefit"></FieldsBenefit>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";
import FieldsBenefit from "@/components/benefits/FieldsBenefit.vue";

export default {
  name: "EditBenefits",
  components: {FieldsBenefit, StandardTitle},
  props: {
    benefitInfo: Array
  },
  computed: {
    _benefit: {
      get() {
        return JSON.parse(localStorage.getItem('benefitInfo'));
      },
      set(val) {
        localStorage.setItem('benefitInfo', JSON.stringify(val));
      }
    }
  },
  created() {
    if (this.benefitInfo) {
      this._benefit = this.benefitInfo[0]
    }
  }
}
</script>

<style scoped>

</style>