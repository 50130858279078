import VueRouter from 'vue-router'
import ViewMenu from "@/views/main_menu/ViewMenu";
import LoginPage from "@/views/login/LoginPage";
import ViewClients from "@/views/clients/ViewClients";
import AddClient from "@/components/clients/AddClient";
import Vue from "vue";
import ListClients from "@/views/clients/ListClients";
import ViewLoanProposals from "@/views/loanProposal/ViewLoanProposals";
import ListLoanProposal from "@/views/loanProposal/ListLoanProposal";
import AddProposal from "@/components/proposals/AddProposal";
import ExcelDownloadDemo from "@/components/templates/ExcelDownloadDemo";
import ViewCustomerServices from "@/views/customer_service/ViewCustomerServices.vue";
import CustomerServicesScreen from "@/views/customer_service/CustomerServicesScreen.vue";
import ShowClientToConsultant from "@/components/customer/ShowClientToConsultant.vue";
import ViewBenefits from "@/views/benefits/ViewBenefits.vue";
import EditBenefits from "@/components/benefits/EditBenefits.vue";
import ShowServices from "@/components/main_menu/ShowServices.vue";
import ScreenMenu from "@/views/main_menu/ScreenMenu.vue";
import EditProposals from "@/components/proposals/EditProposals.vue";
import WriteProposal from "@/components/proposals/WriteProposal.vue";
import FollowUpProposals from "@/views/loanProposal/FollowUpProposals.vue";
import DashBoard from "@/components/main_menu/DashBoard.vue";
import ProposalClientBenefit from "@/components/proposals/ProposalClientBenefit.vue";
import ShowSchedulings from "@/components/main_menu/ShowSchedulings.vue";
import ViewLogs from "@/views/logs/ViewLogs.vue";
import ListLogs from "@/components/logs/ListLogs.vue";
import FieldsLogs from "@/components/logs/FieldsLogs.vue";
import ViewWebcredUser from "@/views/webcreduser/ViewWebcredUser.vue";
import ListWebcredUser from "@/views/webcreduser/ListWebcredUser.vue";
import WebcredUser from "@/components/webcredusers/WebcredUser.vue";
import CreateWebcredUser from "@/components/webcredusers/CreateWebcredUser.vue";
import ViewParameters from "@/components/systemparameters/ViewParameters.vue";
import ListMetas from "@/components/systemparameters/metas/ListMetas.vue";
import CreateMeta from "@/components/systemparameters/metas/CreateMeta.vue";
import ParametersMenu from "@/components/systemparameters/ParametersMenu.vue";
import FieldsMetas from "@/components/systemparameters/metas/FieldsMetas.vue";
import ShowPartner from "@/components/systemparameters/webcredpartner/ShowPartner.vue";
import ViewInvoices from "@/components/systemparameters/invoices/ViewInvoices.vue";
import ListInvoices from "@/components/systemparameters/invoices/ListInvoices.vue";
import EditInvoice from "@/components/systemparameters/invoices/EditInvoice.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/home',
    name: 'home',
    component: ScreenMenu,
    children: [
      {
        path: 'menu',
        name: 'menu',
        props: true,
        component: ViewMenu
      },
      {
        path: 'atendimentos-de-hoje',
        name: 'showservices',
        props: true,
        component: ShowServices
      },
      {
        path: 'agendamentos',
        name: 'showschedulings',
        props: true,
        component: ShowSchedulings
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        props: true,
        component: DashBoard
      }
    ]
  },
  {
    path: '/clientes',
    name: 'viewclientes',
    component: ViewClients,
    children: [
      {
        path: 'adicionarclientes',
        name: 'addclients',
        props: true,
        component: AddClient
      },
      {
        path: 'listarclientes',
        name: 'listclients',
        props: true,
        component: ListClients
      },
      {
        path: 'cadastrar-para-proposta',
        name: 'addclientandbenefit',
        props: true,
        component: ProposalClientBenefit
      }
    ]
  },
  {
    path: '/usuarios',
    name: 'webcredusers',
    component: ViewWebcredUser,
    children: [
      {
        path: 'listarusuarios',
        name: 'users',
        props: true,
        component: ListWebcredUser,
      },
      {
        path: 'usuario/:id',
        name: 'user',
        props: true,
        component: WebcredUser
      },
      {
        path: 'criarusuario',
        name: 'createuser',
        props: true,
        component: CreateWebcredUser
      }
    ]
  },
  {
    path: '/propostas',
    name: 'proposals',
    component: ViewLoanProposals,
    children: [
      {
        path: 'acompanhamento',
        name: 'follow_up',
        props: true,
        component: FollowUpProposals
      },
      {
        path: 'listarpropostas',
        name: 'listproposals',
        props: true,
        component: ListLoanProposal
      },
      {
        path: 'proposta',
        name: 'show_proposal',
        props: true,
        component: EditProposals
      },
      {
        path: 'adicionarpropostas',
        name: 'addproposal',
        props: true,
        component: AddProposal
      },
      {
        path: 'digitarproposta',
        name: 'writeproposal',
        props: true,
        component: WriteProposal
      }
    ]
  },
  {
    path: '/atendimento',
    name: 'customer_services',
    component: ViewCustomerServices,
    children: [
      {
        path: 'home_consultor',
        name: 'consultant_home',
        props: true,
        component: CustomerServicesScreen
      },
      {
        path: 'cliente',
        name: 'search_response',
        props: true,
          component: ShowClientToConsultant
      },
      {
        path: 'dados_cliente',
        name: 'client_all_data',
        props: true,
        component: ShowClientToConsultant
      }
    ]
  },
    {
    path: '/beneficios',
    name: 'benefits',
    component: ViewBenefits,
    children: [
      {
        path: 'alterar_beneficio',
        name: 'change_benefit',
        props: true,
        component: EditBenefits
      }
    ]
  },
  {
    path: '/parametros',
    name: 'parameters',
    component: ViewParameters,
    children: [
      {
        path: 'menuparametros',
        name: 'menuparameters',
        props: true,
        component: ParametersMenu
      },
      {
        path: 'metas',
        name: 'metas',
        props: true,
        component: ListMetas
      },
      {
        path: 'meta/:id',
        name: 'meta',
        props: true,
        component: FieldsMetas
      },
      {
        path: 'cadastrarmeta',
        name: 'createmeta',
        props: true,
        component: CreateMeta
      },
      {
        path: 'informacoes/:id',
        name: 'partnerinfo',
        props: true,
        component: ShowPartner
      },
      {
        path: 'viewfaturas',
        name: 'invoices',
        component: ViewInvoices,
        children: [
          {
            path: 'faturas',
            name: 'partnerinvoices',
            props: true,
            component: ListInvoices
          },
          {
            path: 'fatura/:id',
            name: 'invoice',
            props: true,
            component: EditInvoice
          }
        ]
      }
    ]
  },
  {
  path: '/systemlogs',
  name: 'systemlogs',
  component: ViewLogs,
  children: [
      {
        path: '/logs',
        name: 'logs',
        props: true,
        component: ListLogs
      },
      {
        path: '/log/:id',
        name: 'log',
        props: true,
        component: FieldsLogs
      }
    ]
  },
  {
    path: '/excelimport',
    name: 'excelimport',
    component: ExcelDownloadDemo
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
