<template>
  <div>
    <v-dialog v-model="dialog"
    transition="dialog-top-transition"
    scrollable width="auto"
    persistent
    >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="dialog = false;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="display: flex; justify-content: center; flex-direction: row;">
      <div style="width: 75%">
        <div class="mt-6">
          <StandardTitle :labeltitle="'Atendimento'"></StandardTitle>
        </div>
        <div style="display: flex; justify-content: center; justify-items: center; justify-self: center;">
          <div style="width: 100%; display: flex; justify-content: center;">
            <v-card class="mt-6" style="width: 350px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
              <div style="padding: 16px">
                <div>
                  <h3>Pesquisar Cliente Por: </h3>
                </div>
                <div>
                  <template>
                    <v-container
                        class="px-0"
                        fluid
                    >
                      <v-radio-group class="ml-4" v-model="searchParam">
                        <v-radio
                            label="CPF"
                            value="CPF"
                        ></v-radio>
                        <v-radio
                            label="Telefone"
                            value="Telefone"
                        ></v-radio>
                        <v-radio
                            label="Benefício/Matrícula"
                            value="Benefício"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </template>
                </div>
                <div v-if="searchParam === 'CPF'">
                  <v-text-field v-maska data-maska="###.###.###-##" rounded outlined :label="`${this.searchParam}`" v-model="searchInfo"></v-text-field>
                </div>
                <div v-else>
                  <v-text-field v-maska data-maska="#" data-maska-tokens="#:[0-9]:multiple" rounded outlined :label="`${this.searchParam}`" v-model="searchInfo"></v-text-field>
                </div>
                <div class="mb-3" style="display: flex; justify-content: center">
                  <v-btn color="#019c50" style="color: white; text-transform: none" rounded @click="searchMethod">Pesquisar</v-btn>
                </div>
              </div>
            </v-card>
          </div>
          <div style="width: 100%; display: flex; justify-content: center;">
            <v-card class="mt-6" style="width: 350px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
              <div style="padding: 16px">
                <div>
                  <h3>Propostas: </h3>
                </div>
                <div style="display: flex; justify-content: center">
                  <div style="padding: 50px">
                    <v-btn @click="addProposal" color="#019c50" style="color: white; text-transform: none" rounded x-large>Cadastrar proposta</v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
      <div v-if="hasDailySales" style="width: 25%">
        <div style="padding: 16px">
          <v-card class="pa-3" style="box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
            <div class="mt-1 mb-3">
              <div class="d-flex justify-center align-center">
                <h3>Vendas do dia: </h3>
              </div>
              <div class="sale-info d-flex justify-start align-center mt-3 rounded-xl" v-for="sales in dailySales" :key="sales.id">
                <div>
                  <span class="ml-2">Venda:</span>
                  <span class="ml-2"> {{sales.loan_value}}</span>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "CustomerServicesScreen",
  components: {StandardTitle},
  data: () => ({
    searchParam: 'CPF',
    searchInfo: '',
    hasDailySales: false,
    dailySales: [],
    clientInstance: {},
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    loading: false,
    btnLoading: false
  }),
  methods: {
    getDailySales() {
      this.$http.get('propostas/get_daily_sales/').then(v => {
        this.dailySales = v.data.daily_sales
        if (this.dailySales && this.dailySales.length > 0) {
          this.hasDailySales = true
        }
      })
    },
    searchMethod() {
      this.$http.post('clientes/specific_client/', {
        search_param: this.searchParam,
        search_info: this.searchInfo
      }).then(v => {
        if (v.data.message) {
          this.dialog = true
          this.dialogTitle = 'Atenção!'
          this.dialogMessage = v.data.message
        } else {
          this.clientInstance = v.data.client
          this.$router.push({name: 'search_response', params: {'client': this.clientInstance}})
        }
      })
    },
    addProposal() {
      this.$router.push({name: 'addproposal'})
    },
  },
  created() {
    this.getDailySales()
  },
  watch: {
    searchParam() {
      this.searchInfo =  ''
    }
  }
}
</script>

<style scoped>
.sale-info {
  background-color: #019c50;
  color: #FFFFFF;
  font-weight: 800;
  padding: 6px;
}
</style>