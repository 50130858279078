<template>
  <div>
    <div class="d-flex justify-center align-center">
      <div class="d-flex justify-center align-center">
        <div class="ml-2 mr-2" @click="openMetas">
          <v-card class="mt-12" color="#019c50" style="width: 200px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
            <div style="padding: 16px">
              <div class="d-flex justify-center align-center mb-6">
                <div>
                  <h3 style="color: #FFFFFF; font-size: 1.4em">Metas</h3>
                </div>
              </div>
              <div class="mb-6" style="display: flex; justify-content: center">
                <div>
                  <v-icon color="#FFFFFF" style="font-size: 5em">mdi-chart-box</v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="ml-2 mr-2" @click="openCompanyInfo">
          <v-card class="mt-12" color="#019c50" style="width: 200px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
            <div style="padding: 16px">
              <div class="d-flex justify-center align-center mb-6">
                <div>
                  <h3 style="color: #FFFFFF; font-size: 1.4em">Dados Empresa</h3>
                </div>
              </div>
              <div class="mb-6" style="display: flex; justify-content: center">
                <div>
                  <v-icon color="#FFFFFF" style="font-size: 5em">mdi-card-account-details</v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="ml-2 mr-2" @click="openFaturas">
          <v-card class="mt-12" color="#019c50" style="width: 200px; box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
            <div style="padding: 16px">
              <div class="d-flex justify-center align-center mb-6">
                <div>
                  <h3 style="color: #FFFFFF; font-size: 1.4em">Planos e Faturas</h3>
                </div>
              </div>
              <div class="mb-6" style="display: flex; justify-content: center">
                <div>
                  <v-icon color="#FFFFFF" style="font-size: 5em">mdi-bank-outline</v-icon>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParametersMenu",
  methods: {
    openMetas() {
      this.$router.push({name: 'metas'})
    },
    openCompanyInfo() {
      const partnerId = this.$store.getters['getPartnerId']
      this.$router.push({name: 'partnerinfo', params: {id: partnerId}})
    },
    openFaturas() {
      this.$router.push({name: 'partnerinvoices'})
    }
  }
}
</script>

<style scoped>

</style>