<template>
  <div>
    <div class="top-page mt-6 mb-6">
      <StandardTitle :labeltitle=labelTitle></StandardTitle>
    </div>
    <div class="body-page">
      <CreateProposal></CreateProposal>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle";
import CreateProposal from "@/components/proposals/CreateProposal.vue";
export default {
  name: "AddProposal",
  components: {CreateProposal, StandardTitle},
  data: () => ({
    labelTitle: 'Cadastrar Proposta'
  })
}
</script>

<style scoped>
.top-page{
  display: flex;
  justify-content: center;
}

</style>