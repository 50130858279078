<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
   <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else class="d-flex flex-column">
    <v-dialog v-model="dialog" transition="dialog-top-transition" scrollable width="350" persistent >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="dialogTitle !== 'Planilhas'" style="height: auto; width: auto">
          <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
            {{ dialogMessage }}
          </div>
        </v-card-text>
        <v-card-text class="mt-3" v-else style="height: auto; width: auto">
          <div class="d-flex justify-center align-center flex-column">
            <a @click="downloadModelFile(1)">baixar modelo clientes</a>
            <v-icon size="100" color="#019c50">$client_icon</v-icon>
            <a class="mt-4" @click="downloadModelFile(2)">baixar modelo propostas</a>
            <v-icon size="100" color="#019c50">$proposal_icon</v-icon>

          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="dialog = false;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex justify-end align-center mt-2 mr-2">
      <v-btn rounded color="#019c50" style="color: white; text-transform: none" @click="openSpreadSheetsModels">Modelos de planilhas</v-btn>
    </div>
    <div style="display: flex; justify-content: center;">
      <v-card class="mt-9" style="box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);">
        <div style="flex-direction: column; padding: 36px">
          <div class="d-flex" style="margin-top: -15px">
            <h3>O que deseja importar? </h3>
            <span class="ml-2 validation" v-if="dataFromPlanner === 'propostas'">Validação: {{ validation }}</span>
          </div>
          <div>
            <template>
              <v-container
                  class="px-0"
                  fluid
              >
                <v-radio-group v-model="dataFromPlanner">
                  <v-radio
                      label="Clientes"
                      :value="'clientes'"
                  ></v-radio>
                  <v-radio
                      label="Propostas"
                      :value="'propostas'"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </template>
          </div>
          <div>
            <input class="mb-2" type="file" @change="onFileChange"/>
          </div>
          <div v-if="sending">
            <v-progress-linear color="#019c50" indeterminate></v-progress-linear>
          </div>
          <div class="mt-2">
            <v-btn rounded color="#019c50" style="color: white; text-transform: none" @click="sendFile">Enviar</v-btn>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>

export default {
  data: () => ({
    dataFromPlanner: null,
    requestPath: null,
    jsonFile: null,
    sending: false,
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    loading: false,
    validation: 0,
    pageLoading: true
  }),
  methods: {
    downloadModelFile(value) {
      this.pageLoading = true
      this.$http.post(`download_model_files`, {file: value}, {
        responseType: 'blob',
      }).then(val => {
        const url = window.URL.createObjectURL(new Blob([val.data]))
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'arquivo.xlsx');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        this.pageLoading = false
        this.dialog = false
      })
    },
    openSpreadSheetsModels() {
      this.dialog = true
      this.dialogTitle = 'Planilhas'
    },
    getLastValidation() {
      this.$http.get('propostas/get_last_validation/').then(val => {
        this.validation = val.data.validation
      })
    },
    onFileChange(e) {
      this.jsonFile = e.target.files ? e.target.files[0] : null;
    },
    sendFile() {
      if (this.dataFromPlanner) {
        if (this.dataFromPlanner === 'clientes') {
          this.requestPath = 'import_client_from_excel'
        }
        if (this.dataFromPlanner === 'propostas') {
          this.requestPath = 'import_loan_proposal_from_excel'
        }
        if (this.jsonFile !== null) {
          this.sending = true
          let data = new FormData();
          data.append('file', this.jsonFile);
          this.$http.post(`${this.requestPath}`, data, {headers: {
                    'Content-Type': 'multipart/form-data'
                }}).then(v => {
            this.dialog = true
            this.dialogTitle = 'Sucesso!'
            this.dialogMessage = v.data.msg
            this.sending = false
          })
        } else {
          this.dialog = true
          this.dialogTitle = 'Atenção!'
          this.dialogMessage = 'É necessário fazer o upload de um arquivo excel válido para iniciar a importação!'
        }
      } else {
        this.dialog = true
        this.dialogTitle = 'Atenção!'
        this.dialogMessage = 'Selecione uma opção.'
      }
    }
  },
  created() {
    this.pageLoading = false
    this.getLastValidation()
  }
}
</script>

<style scoped>
.validation {
  background-color: #019c50;
  padding: 5px 15px;
  border-radius: 20px;
  color: #FFFFFF;
  font-weight: 900;
}
</style>