<template>
  <div class="input-all-client">
    <v-dialog v-model="dialog"
    transition="dialog-top-transition"
    scrollable width="auto"
    >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div class="d-flex justify-center align-center" style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn v-if="dialogTitle = 'Adicionado!'" style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="backToProposal">
            Ok
          </v-btn>
          <v-btn v-else style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="width: 50%">
      <v-card class="mt-4 mb-10 rounded-xl" elevation="4">
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-12 ml-10">
              <h3 style="margin-left: 100px;">Cadastrar: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <div class="mb-3 mt-3" style="margin-top: -15px; margin-left: -25px">
                <v-radio-group rounded outlined dense v-model="payloadDescription">
                  <v-radio
                      label="Cliente"
                      :value="0"
                  ></v-radio>
                  <v-radio
                      label="Benefício"
                      :value="1"
                  ></v-radio>
                  <v-radio
                      label="Cliente e Benefício"
                      :value="2"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <div v-if="payloadDescription === 1">
        <v-card class="pa-8 rounded-xl mb-5" elevation="4">
          <v-form v-model="formValid">
            <div class="card-content">
              <div class="cpf-section">
                <div class="mr-3" style="font-weight: bolder">Buscar Cliente:</div>
                <div class="mt-3">
                  <v-text-field dense label="CPF" v-model="cpfTerm" outlined rounded v-maska :rules="cpfRules"
                                data-maska="###.###.###-##"></v-text-field>
                </div>
                <div class="ml-3">
                  <v-btn small rounded elevation="4" :loading="loading" color="#019c50" style="color: #FFFFFF; text-transform: none" @click="catchClient">Buscar
                  </v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </v-card>
      </div>
      <div v-if="payloadDescription === 0 || payloadDescription === 2">
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Base: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="baseName" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-4">
              <h3>Cliente Siape? </h3>
            </v-col>
            <v-col cols="4" md="6">
              <div class="mb-3" style="margin-top: -15px">
                <v-radio-group rounded outlined dense v-model="isSiape">
                  <v-radio
                      label="Sim"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      label="Não"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="fullName" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>CPF: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="###.###.###-##"
                            dense v-model="clientCpf"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Logradouro: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="clientAdress" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Numero: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="#"
                            data-maska-tokens="#:[0-9]:multiple"
                            dense v-model="clientAdressNumber"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Bairro: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="clientNeighbor" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Complemento: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="clientComplement" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Cidade: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="clientCity" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>UF: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="clientState" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>CEP: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="##.###-###"
                            dense v-model="clientPostCode"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Data de Nascimento: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field type="date" rounded outlined
                            dense v-model="birthday"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome do Pai: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="fatherName" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome da Mãe: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="motherName" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>RG: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="registrationId"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Telefones: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-textarea height="100" rounded outlined dense v-model="phoneNumbers"></v-textarea>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Email: </h3>
            </v-col>
            <v-col cols="4" md="6">
              <v-text-field rounded outlined dense v-model="clientEmail"></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="showCatchedClient" class="d-flex justify-center align-center">
        <div class="benefit-client">
          <span>{{ benefitClient }}</span>
        </div>
      </div>
      <div v-if="payloadDescription === 1 || payloadDescription === 2">
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Benefício: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="benefit" v-maska
                        data-maska="#"
                        data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Salário: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="salary" v-maska data-maska="R$ #.#,##"
                      data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Margem: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="margin" v-maska data-maska="R$ #.#,##"
                      data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Valor Empréstimo: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="loanAmount" v-maska data-maska="R$ #.#,##"
                      data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Margem Cartão: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="cardMargin" v-maska data-maska="R$ #.#,##"
                      data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Valor Cartão: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="cardValue" v-maska data-maska="R$ #.#,##"
                      data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Data de Concessão: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field type="date" rounded outlined dense v-model="grantDate"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Espécie: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="species"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Data de Recebimento: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field type="date" rounded outlined dense v-model="receivingDate"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Banco: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-autocomplete rounded outlined dense v-model="bank" :items="allBanks"></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Agência: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="bankAgency"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Conta: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="bankAccount"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Margem Cartão Benefício:</h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="cardMarginBenefit" v-maska data-maska="R$ #.#,##"
                      data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Valor Cartão Benefício:</h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="cardValueBenefit" v-maska data-maska="R$ #.#,##"
                      data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>UF Benefício: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="benefitUf"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Quantidade de Empréstimos: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="loanProposalQuantity"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Bloqueio Empréstimo: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="loanLock"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Valor Parcela: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="portionValue" v-maska data-maska="R$ #.#,##"
                      data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Prazo Parcela: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field type="date" rounded outlined dense v-model="portionDeadline"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Banco Parcela: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-autocomplete rounded outlined dense v-model="portionBank" :items="allBanks"></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Quantidade de Parcelas Pagas: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="numberOfPortionPaid"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Início Desconto: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field type="date" rounded outlined dense v-model="startDiscount"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Final Desconto: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field type="date" rounded outlined dense v-model="endDiscount"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Saldo Devedor Parcela: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="portionDebitBalance" v-maska
                        data-maska="R$ #.#,##"
                        data-maska-tokens="#:[0-9]:multiple"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Representante Legal:</h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="nameLegalRepresentative"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>CPF Representante Legal:</h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="cpfLegalRepresentative"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Matrícula: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="registration"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Orgão Público: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="publicAgency"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Instituição: </h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="institution"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>UPAG:</h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="upag"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <div style="width: 100%">
            <v-row>
              <v-col class="mt-1">
                <h3>Situação Funcional:</h3>
              </v-col>
              <v-col cols="4" md="6">
                <v-text-field rounded outlined dense v-model="functionalStatus"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center align-center mt-4 mb-5">
        <v-btn color="#019c50" :loading="loading" rounded style="color: #FFFFFF; text-transform: none" @click="saveClientAndBenefit">
          Salvar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AddClientAndBenefit",
  data: () => ({
    loading: false,
    payloadDescription: '',
    cpfTerm: '',
    cpfRules: [
      v => !!v || 'C.P.F. é obrigatório!',
    ],
    dialog: false,
    dialogAlert: false,
    dialogMessage: '',
    dialogTitle: '',
    formValid: false,
    showCatchedClient: false,
    benefitClient: '',
    allBanks: [],
    cpf: null,

    baseName: '',
    isSiape: false,
    fullName: '',
    clientCpf: '',
    clientAdress: '',
    clientAdressNumber: '',
    clientNeighbor: '',
    clientComplement: '',
    clientCity: '',
    clientState: '',
    clientPostCode: '',
    birthday: '',
    fatherName: '',
    motherName: '',
    registrationId: '',
    phoneNumbers: '',
    clientEmail: '',

    clientId: '',
    benefit: '',
    salary: '',
    margin: '',
    loanAmount: '',
    cardMargin: '',
    cardValue: '',
    grantDate: '',
    species: '',
    receivingDate: '',
    bank: '',
    bankAgency: '',
    bankAccount: '',
    cardMarginBenefit: '',
    cardValueBenefit: '',
    benefitUf: '',
    loanProposalQuantity: '',
    loanLock: '',
    portionValue: '',
    portionDeadline: '',
    portionBank: '',
    numberOfPortionPaid: '',
    startDiscount: '',
    endDiscount: '',
    portionDebitBalance: '',
    nameLegalRepresentative: '',
    cpfLegalRepresentative: '',
    registration: '',
    publicAgency: '',
    institution: '',
    upag: '',
    functionalStatus: ''
  }),
  methods: {
    async saveClientAndBenefit() {
      this.loading = true
      let params_client = {}
      let params_benefit = {}
      if (this.payloadDescription === 0 || this.payloadDescription === 2) {
        params_client.base = this.baseName
        params_client.is_siape = this.isSiape
        params_client.name = this.fullName
        params_client.cpf = this.clientCpf
        params_client.street_avenue_platter = this.clientAdress
        params_client.number = this.clientAdressNumber
        params_client.neighbor = this.clientNeighbor
        params_client.complement = this.clientComplement
        params_client.city = this.clientCity
        params_client.state = this.clientState
        params_client.post_code = this.clientPostCode
        params_client.birthday = this.birthday
        params_client.father_name = this.fatherName
        params_client.mother_name = this.motherName
        params_client.registry = this.registrationId
        params_client.phone = this.phoneNumbers
        params_client.email = this.clientEmail
        await this.$http.post('clientes/create_new_client/', {params_client}).then(
            (v) => {
              if (this.payloadDescription === 0) {
                if (v.data.errormessage) {
                  this.dialog = true
                  this.dialogTitle = 'Ops!'
                  this.dialogMessage = v.data.errormessage
                  this.loading = false
                  return
                }
                if (v.data.message) {
                  this.cpf = this.clientCpf
                  this.dialog = true
                  this.dialogTitle = 'Adicionado!'
                  this.dialogMessage = v.data.message
                  this.loading = false
                }
              }
            }
        )
      }
      if (this.payloadDescription === 1 || this.payloadDescription === 2) {
        params_benefit.client = this.clientId
        params_benefit.cpf = this.clientCpf
        params_benefit.benefit = this.benefit
        params_benefit.salary = this.salary
        params_benefit.margin = this.margin
        params_benefit.loan_amount = this.loanAmount
        params_benefit.card_margin = this.cardMargin
        params_benefit.card_value = this.cardValue
        params_benefit.grant_date = this.grantDate
        params_benefit.species = this.species
        params_benefit.receiving_date = this.receivingDate
        params_benefit.bank = this.bank
        params_benefit.bank_agency = this.bankAgency
        params_benefit.bank_account = this.bankAccount
        params_benefit.card_margin_benefit = this.cardMarginBenefit
        params_benefit.card_value_benefit = this.cardValueBenefit
        params_benefit.benefit_uf = this.benefitUf
        params_benefit.loan_proposal_quantity = this.loanProposalQuantity
        params_benefit.loan_lock = this.loanLock
        params_benefit.portion_value = this.portionValue
        params_benefit.portion_deadline = this.portionDeadline
        params_benefit.portion_bank = this.portionBank
        params_benefit.number_of_portion_paid = this.numberOfPortionPaid
        params_benefit.start_discount = this.startDiscount
        params_benefit.end_discount = this.endDiscount
        params_benefit.portion_debit_balance = this.portionDebitBalance
        params_benefit.name_legal_representative = this.nameLegalRepresentative
        params_benefit.cpf_legal_representative = this.cpfLegalRepresentative
        params_benefit.registration = this.registration
        params_benefit.public_agency = this.publicAgency
        params_benefit.institution = this.institution
        params_benefit.upag = this.upag
        params_benefit.functional_status = this.functionalStatus
        await this.$http.post('beneficios/create_benefit/', {params_benefit}).then(
            (v) => {
              if (this.payloadDescription === 1) {
                this.cpf = this.cpfTerm
                this.dialog = true
                this.dialogTitle = 'Adicionado!'
                this.dialogMessage = v.data.message
                this.loading = false
              }
            }
        )
      }
      if (this.payloadDescription === '') {
        this.dialog = true
        this.dialogTitle = 'Atenção!'
        this.dialogMessage = 'Você precisa informar o que será cadastrado!'
        this.loading = false
      }
      if (this.payloadDescription === 2) {
        this.dialog = true
        this.cpf = this.clientCpf
        this.dialogTitle = 'Adicionado!'
        this.dialogMessage = 'Cliente e Benefício adicionados com sucesso!'
        this.loading = false
      }
    },
    catchClient() {
      this.loading = true
      if (this.formValid) {
        this.$http.post('clientes/client_to_proposal/', {
          cpf: this.cpfTerm
        }).then(v => {
          if (v.data.message) {
            this.dialog = true
            this.dialogTitle = 'Atenção'
            this.dialogMessage = v.data.message
            this.loading = false
          } else {
            this.showCatchedClient = true
            this.benefitClient = v.data.client.name
            this.clientId = v.data.client.id
            this.loading = false
          }
        }).catch(v => {
          this.dialog = true
          this.dialogTitle = 'Atenção'
          this.dialogMessage = `${v.response.data.error} Contate o administrador do sistema`
        })
      } else {
        this.dialog = true
        this.dialogTitle = 'Atenção'
        this.dialogMessage = 'Não é possível prosseguir sem digitar o C.P.F.!'
      }
    },
    convertToUpperCase() {
      this.baseName = this.baseName.toUpperCase();
      this.fullName = this.fullName.toUpperCase();
      this.clientAdress = this.clientAdress.toUpperCase();
      this.clientNeighbor = this.clientNeighbor.toUpperCase();
      this.clientComplement = this.clientComplement.toUpperCase();
      this.clientCity = this.clientCity.toUpperCase();
      this.clientState = this.clientState.toUpperCase();
      this.fatherName = this.fatherName.toUpperCase();
      this.motherName = this.motherName.toUpperCase();
    },
    getBanks() {
      this.$http.get('bancos/').then(v => {
        for (let i in v.data) {
          this.allBanks.push(`${v.data[i].code_number} - ${v.data[i].bank_name}`)
        }
      })
    },
    backToProposal() {
      this.loading =true
      this.dialog = false
      if (this.cpf !== null) {
        this.$router.push({name: 'addproposal', params: {'cpf': this.cpf}})
      }
      this.loading = false
    }
  },
  created() {
    this.getBanks()
  }
}
</script>

<style scoped>
.input-all-client {
  display: flex;
  justify-content: center;
}

.space-between-fields {
  margin-bottom: -28px;
}

.benefit-client {
  background: #019c50;
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  border-radius: 50px;
  color: #FFFFFF;
  font-size: 1.3em;
  font-weight: 700;
}
</style>
