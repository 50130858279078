<template>
  <div>
    <v-card class="custom-card pa-5 rounded-xl" elevation="3" color="#019c50" height="auto" width="auto">
      <div v-for="sale in dailySales" :key="sale.user_name" class="d-flex pa-1">
        <div class="d-flex justify-start align-center mr-4" style="width: max-content">
          <v-img class="rounded-xl"
            height="50"
            width="50"
            :src="sale.user_photo">
          </v-img>
        </div>
        <div class="d-flex justify-start align-center ml-8" style="font-weight: 900; color:#FFFFFF; width: max-content; font-size: 1.3em; text-align-last: start;">
          <span>{{ sale.amount_value }}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SalesRanking",
  props: ['dailySales'],
  data: () => ({
  }),
  methods: {
  },
  created() {
  }
}
</script>

<style scoped>
.custom-card {
    background-image: linear-gradient(to bottom, #019c50, #008933)
}
</style>