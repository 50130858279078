<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
     <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else>
    <v-dialog v-model="dialogUpdate"
    transition="dialog-top-transition"
    scrollable width="auto"
    >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitleUpdate }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn v-if="dialogTitleUpdate === 'Sucesso!'" style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialogUpdate = false; $router.go(-1);">
            Fechar e voltar
          </v-btn>
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialogUpdate = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: auto; width: auto">
          <v-radio-group v-model="param" column>
            <div v-for="item in dataCollectionIterable" :key="item">
              <v-radio :label="item" :value="item"></v-radio>
            </div>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="closeDialog(param, info)">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogBank" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogBankTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: auto; width: auto">
          <v-autocomplete class="mt-12" style="width: 450px" label="Banco" v-model="param" :items="banks" outlined
                          rounded></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="closeDialog(param, info)">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDate" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogDateTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: auto; width: auto">
          <v-date-picker locale="pt-br" class="pa-4" color="#019c50" v-model="picker"></v-date-picker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="closeDialog(picker, info)">
            Selecionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogDeleteTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: auto; width: auto">
          <div style="width: 350px" class="mt-5">
            <div class="d-flex justify-center">
              <span style="text-align-last: center">{{ dialogDeleteText }}</span>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="dialogDeleteTitle === 'Atenção!'" class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#fe0000" variant="text" @click="dialogDelete = false;">
            Voltar
          </v-btn>
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="_deleteProposal();">
            Apagar
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else class="d-flex justify-center align-center">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#fe0000" variant="text" @click="dialogDeleteTitle === 'Sucesso!' ? $router.push({name: 'listproposals'}) : dialogDelete = false;">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex justify-center align-center">
      <div class="title-proposal rounded-pill">
        <span style="color: #FFFFFF">Proposta: {{ proposal.id ? proposal.id : proposalUpdated.id }}</span>
      </div>
      <v-icon class="mt-7" @click="deleteProposal" size="30" color="#fe0000">mdi-delete</v-icon>
    </div>
    <div class="d-flex justify-center align-center flex-column mt-8">
      <v-form ref="form" v-model="valid">
        <div>
          <v-row>
            <v-col style="margin-right: -22px;">
              <div class="d-flex justify-center align-content-center" style="width: 220px">
                <v-text-field dense label="Data da Venda" v-model="saleDate" outlined rounded
                              v-maska data-maska="##/##/####"></v-text-field>
                <v-icon @click="changeModelDate('Data da Venda', 'sale_date')" class="mb-7" size="40" color="#019c50">
                  mdi-calendar-edit
                </v-icon>
              </div>
            </v-col>
            <v-col style="margin-right: -16px;">
              <v-text-field style="width: 420px" dense label="Nome Completo" v-model="fullName" outlined rounded></v-text-field>
            </v-col>
            <v-col style="margin-right: -16px;">
              <v-text-field style="width: 170px" dense label="CPF" v-model="cpfInfo" outlined rounded v-maska
                        data-maska="###.###.###-##"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field style="width: 181px" dense label="Telefone" v-model="phoneUsed" outlined rounded></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col style="margin-right: -16px;">
              <div style="width: 450px" class="d-flex justify-center align-content-center">
                <v-text-field dense label="Banco Proposta" v-model="bankName" outlined rounded></v-text-field>
                <v-icon @click="changeModelBank('Bancos', 'loan_bank')" class="mb-7" size="40" color="#019c50">
                  mdi-pencil-circle
                </v-icon>
              </div>
            </v-col>
            <v-col style="margin-right: -14px;">
              <v-select style="width: 220px" dense label="Usuário" @blur="patchUser" v-model="userName" :items="users" outlined rounded></v-select>
            </v-col>
            <v-col style="margin-right: -15px;">
              <div style="width: 185px" class="d-flex justify-center align-content-center">
                <v-text-field dense label="Formalização" v-model="collectWay" outlined rounded></v-text-field>
                <v-icon @click="changeModel('Formalização', 'formalization')" class="mb-7" size="40" color="#019c50">
                  mdi-pencil-circle
                </v-icon>
              </div>
            </v-col>
            <v-col>
              <div style="width: 190px" class="d-flex justify-center align-content-center">
                <v-text-field class="mr-2" dense label="Seguro" v-model="insurance" outlined rounded></v-text-field>
                <v-switch style="margin-top: 6px;" v-model="hasInsurance" color="rgba(1, 156, 80, 1)" inset></v-switch>
              </div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col style="margin-right: -22px;">
              <div class="d-flex justify-center align-content-center" style="width: 400px">
                <v-text-field dense label="Produto" v-model="loanType" outlined rounded></v-text-field>
                <v-icon @click="changeModel('Produtos', 'product')" class="mb-7" size="40" color="#019c50">mdi-pencil-circle
                </v-icon>
              </div>
            </v-col>
            <v-col style="margin-right: -16px;">
              <v-text-field style="width: 190px" dense label="Valor Bruto" v-model="loanValue" :rules="currencyRules" hint="Ex: 1.200,00" outlined rounded></v-text-field>
            </v-col>
            <v-col style="margin-right: -16px;">
              <v-text-field style="width: 190px" dense label="Valor Líquido" v-model="netValue" :rules="currencyRules" hint="Ex: 1.200,00" outlined rounded></v-text-field>
            </v-col>
            <v-col style="margin-right: -22px;">
              <div class="d-flex justify-center align-content-center" style="width: 245px">
                <v-text-field dense label="Data de Pagamento" v-model="paymentDate" outlined rounded></v-text-field>
                <v-icon @click="changeModelDate('Data de Pagamento', 'payment_date')" class="mb-7" size="40"
                        color="#019c50">mdi-calendar-edit
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col style="margin-right: -22px;">
              <div class="d-flex justify-center align-content-center" style="width: 300px">
                <v-text-field dense label="Status" v-model="loanStatus" :rules="statusRules" outlined rounded></v-text-field>
                <v-icon @click="changeModel('Status', 'status')" class="mb-7" size="40" color="#019c50">mdi-pencil-circle
                </v-icon>
              </div>
            </v-col>
            <v-col style="margin-right: -22px;">
              <div class="d-flex justify-center align-content-center" style="width: 380px">
                <v-text-field dense label="Situação" v-model="situation" :rules="situationRules" outlined rounded></v-text-field>
                <v-icon @click="changeModel('Situações', 'situation')" class="mb-7" size="40" color="#019c50">
                  mdi-pencil-circle
                </v-icon>
              </div>
            </v-col>
            <v-col>
              <div class="d-flex justify-center align-content-center" style="width: 270px">
                <v-text-field dense label="Completa 90 dias em" v-model="complete90DaysIn" outlined rounded
                              v-maska data-maska="##/##/####"></v-text-field>
                <v-icon @click="changeModelDate('Completa 90 dias em:', 'sale_nine_o')" class="mb-7" size="40"
                        color="#019c50">mdi-calendar-edit
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col style="margin-right: -16px;">
              <v-text-field style="width: 470px" dense label="Acompanhamento" v-model="followUp" outlined rounded></v-text-field>
            </v-col>
            <v-col>
              <v-text-field style="width: 470px" dense label="Tratativa" v-model="loanTreatment" outlined rounded></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col style="margin-right: -16px">
              <div class="d-flex justify-center align-content-center" style="width: 433px">
                <v-text-field rounded dense label="Banco Cliente" outlined v-model="clientBank"></v-text-field>
                <v-icon @click="changeModelBank('Bancos', 'client_bank')" class="mb-7" size="40" color="#019c50">
                  mdi-pencil-circle
                </v-icon>
              </div>
            </v-col>
            <v-col style="margin-right: -16px">
              <v-text-field style="width: 120px" rounded dense label="Agência" outlined v-model="bankAgency"></v-text-field>
            </v-col>
            <v-col style="margin-right: -16px">
              <v-text-field style="width: 120px" rounded dense label="Conta" outlined v-model="bankAccount"></v-text-field>
            </v-col>
            <v-col>
              <div class="d-flex justify-center align-content-center" style="width: 250px">
                <v-text-field dense label="Data de Concessão" v-model="grantDate" outlined rounded></v-text-field>
                <v-icon @click="changeModelDate('Data de Concessão', 'grant_date')" class="mb-7" size="40"
                        color="#019c50">mdi-calendar-edit
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col style="margin-right: -16px">
              <div class="d-flex justify-center align-content-center" style="width: 443px">
                <v-text-field rounded dense label="Banco Parcela" outlined v-model="portionBank"></v-text-field>
                <v-icon @click="changeModelBank('Bancos', 'portion_bank')" class="mb-7" size="40" color="#019c50">
                  mdi-pencil-circle
                </v-icon>
              </div>
            </v-col>
            <v-col style="margin-right: -16px">
              <v-text-field style="width: 230px" dense label="Bloqueio Empréstimo" v-model="loanLock" outlined rounded></v-text-field>
            </v-col>
            <v-col style="margin-right: -16px;">
              <v-text-field style="width: 140px" dense label="ADE" v-model="loanAde" outlined rounded></v-text-field>
            </v-col>
            <v-col>
              <v-text-field style="width: 110px" dense label="Espécie" v-model="species" outlined rounded></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col style="margin-right: -16px">
              <v-text-field rounded style="width: 197px" dense :rules="currencyRules" hint="Ex: 1.200,00"
                            label="Valor Parcela" outlined v-model="portionValue"></v-text-field>
            </v-col>
            <div class="d-flex justify-center align-content-center">
              <v-col style="margin-right: -16px">
                <v-text-field rounded dense label="Prazo Parcela"
                          outlined v-model="portionDeadline"></v-text-field>
              </v-col>
              <v-icon @click="changeModelDate('Data de Concessão', 'grant_date')" class="mb-7 ml-1" size="40"
                      color="#019c50">mdi-calendar-edit
              </v-icon>
            </div>
            <v-col style="margin-right: -16px">
              <v-text-field style="width: 235px" rounded dense label="Qtd de Parcelas Pagas" outlined
                        v-model="numberOfPortionPaid"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field style="width: 230px" rounded
                        dense label="Saldo Devedor Parcela" outlined v-model="portionDebitBalance"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-content-center" style="width: 195px">
            <v-text-field class="mr-2" dense label="Representante" v-model="representative" outlined rounded></v-text-field>
            <v-switch style="margin-top: 5px;" v-model="isRepresentative" color="rgba(1, 156, 80, 1)" inset></v-switch>
          </div>
          <div class="mr-2" v-if="isRepresentative">
            <v-text-field rounded dense label="Nome Representante" outlined
                          v-model="nameRepresentative"></v-text-field>
          </div>
          <div v-if="isRepresentative">
            <v-text-field rounded dense label="CPF Representante" outlined
                          v-model="cpfRepresentative"></v-text-field>
          </div>
        </div>
        <div>
          <v-row>
            <v-col style="margin-right: -22px">
              <div class="d-flex justify-center align-content-center">
                <v-text-field rounded v-maska data-maska="##/##/####" dense label="Início Desconto" outlined
                            v-model="startDiscount"></v-text-field>
                <v-icon @click="changeModelDate('Início Desconto', 'start_discount')" class="mb-7" size="40"
                        color="#019c50">mdi-calendar-edit
                </v-icon>
              </div>
            </v-col>
            <v-col>
              <div class="d-flex justify-center align-content-center">
                <v-text-field rounded v-maska data-maska="##/##/####" dense label="Final Desconto" outlined
                            v-model="endDiscount"></v-text-field>
                <v-icon @click="changeModelDate('Final Desconto', 'end_discount')" class="mb-7" size="40"
                        color="#019c50">mdi-calendar-edit
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="loanStatus === 'PAGO'">
          <v-row>
            <v-col style="margin-right: -16px">
              <v-text-field prepend-inner-icon="mdi-percent" dense label="Fator" v-model="factor" outlined rounded></v-text-field>
            </v-col>
            <v-col style="margin-right: -16px">
              <v-text-field prepend-inner-icon="mdi-currency-usd" dense label="Rentabilidade" v-model="rentability" outlined rounded></v-text-field>
            </v-col>
            <v-col style="margin-right: -16px">
              <v-select dense label="Promotora" v-model="promoter" :items="promoters" outlined rounded></v-select>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-center align-center" v-if="loanStatus === 'PAGO'">
          <div class="mr-2" style="width: 520px">
            <v-text-field dense label="Tabela" v-model="table_loan" outlined rounded></v-text-field>
          </div>
          <div class="d-flex justify-center align-content-center" style="width: 280px">
            <v-text-field class="mr-2" dense label="Comissão Recebida?" v-model="commission" outlined rounded></v-text-field>
            <v-switch style="margin-top: 5px;" v-model="commissionPayed" color="rgba(1, 156, 80, 1)" inset></v-switch>
          </div>
          <div v-if="loanType !== 'PORTABILIDADE ATAQUE' && coupons && generateCoupons" class="d-flex justify-center align-center" style="width: 280px">
            <v-text-field class="mr-2" dense label="Cupons entregues?" v-model="coupon_delivered" outlined rounded></v-text-field>
            <v-switch style="margin-top: -4px;" v-model="deliveredCoupons" color="rgba(1, 156, 80, 1)" inset></v-switch>
            <div class="coupons-to-delivered">
              <span>{{ coupons }}</span>
            </div>
          </div>
        </div>
      </v-form>
    </div>
    <div class="d-flex justify-center align-center">
      <div style="width: 940px">
        <v-textarea dense label="Observação" v-model="observation" outlined rounded></v-textarea>
      </div>
    </div>
    <div class="d-flex justify-center align-center mb-8">
      <v-btn rounded elevation="4" color="#019c50" style="color: #FFFFFF; text-transform: none" :loading="loading" @click="saveLoanProposal">
        Salvar
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "FieldsProposal",
  props: ['proposal'],
  data: () => ({
    valid: false,
    currencyRules: [
      value => !!value || 'Valor é obrigatório',
      value => /^[0-9]{1,3}(?:\.[0-9]{3})*(?:,[0-9]{2})?$/.test(value) || 'Formato de moeda inválido',
    ],
    fullName: '',
    cpfInfo: '',
    userName: '',
    users: [],
    collectWay: '',
    bankName: '',
    loanType: '',
    loanValue: '',
    netValue: '',
    loanOportunity: '',
    loanAde: '',
    reversion: '',
    situation: '',
    complete90DaysIn: '',
    followUp: '',
    loanTreatment: '',
    loanStatus: '',
    saleDate: '',
    paymentDate: '',
    phoneUsed: '',
    observation: '',
    factor: '',
    promoter: '',
    rentability: '',
    table_loan: '',
    hasInsurance: '',
    insurance: '',
    representative: '',
    isRepresentative: '',
    nameRepresentative: '',
    cpfRepresentative: '',
    grantDate: '',
    loanLock: '',
    species: '',
    portionValue: '',
    portionDeadline: '',
    portionBank: '',
    numberOfPortionPaid: '',
    portionDebitBalance: '',
    startDiscount: '',
    endDiscount: '',
    clientBank: '',
    bankAgency: '',
    bankAccount: '',
    commission: '',
    commissionPayed: '',
    coupon_delivered: '',
    deliveredCoupons: '',
    generateCoupons: '',

    pageLoading: true,
    dialogDelete: false,
    dialogDeleteTitle: '',
    dialogDeleteText: '',
    dialog: false,
    dialogTitle: '',
    dialogBank: false,
    dialogBankTitle: '',
    info: '',
    dataCollectionIterable: null,
    param: '',
    dialogDate: false,
    dialogDateTitle: '',
    picker: new Date().toISOString().substr(0, 10),
    banks: [],
    listUsers: [],
    dialogUpdate: false,
    dialogTitleUpdate: '',
    dialogMessage: '',

    collectWayTypes: [
      'DIGITAL', 'FIGITAL', 'FÍSICO'
    ],
    statusTypes: [],
    situationTypes: [],
    vendido: [],
    digitado: [],
    formalizacaoRecebida: [],
    emAndamento: [],
    pago: [],
    cancelado: [],
    redigitar: [],
    loanTypes: [
      'AUMENTO DE SALÁRIO', 'CARTÃO BENEFÍCIO COM SAQUE', 'CARTÃO BENEFÍCIO SEM SAQUE', 'CARTÃO COM SAQUE',
      'CARTÃO SEM SAQUE', 'CARTÃO DE CRÉDITO', 'CRÉDITO PESSOAL', 'ENERGIA', 'FGTS', 'NOVO', 'OPORTUNIDADE NOVO',
      'OPORTUNIDADE CARTÃO', 'OPORTUNIDADE CARTÃO COM SAQUE', 'PORTABILIDADE+REFIN', 'PORTABILIDADE ATAQUE', 'PORTABILIDADE PURA', 'REFIN DE PORTABILIDADE', 'REFIN DE PORT ZERADO',
      'REFINANCIAMENTO', 'REFINANCIAMENTO+AGREGRAÇÃO', 'REVERSÃO NOVO', 'REVERSÃO CARTÃO', 'REVERSÃO CARTÃO COM SAQUE',
      'SAQUE COMPLEMENTAR', 'SAQUE COMPLEMENTAR BENEFÍCIO', 'SEGURO PRESTAMISTA', 'SEGURO SINDNAPE'
    ],
    cpfTerm: '',
    clientObject: null,
    proposalUpdated: undefined,
    promoters: [],
    loading: false,
    coupons: false
  }),
  computed: {
    statusRules() {
      return [
          v => !!v || 'O status é obrigatório',
          v => {
              if (!this.statusTypes.length) {
                  return false;
              }
              return this.statusTypes.includes(v) || 'Status inválido';
          }
      ];
    },
    situationRules() {
        return [
            v => !!v || 'A situação é obrigatória',
            v => {
                if (!this.situationTypes.length) {
                    return false;
                }
                return this.situationTypes.includes(v) || 'Situação inválida';
            }
        ];
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    deleteProposal() {
      this.dialogDelete = true
      this.dialogDeleteTitle = 'Atenção!'
      this.dialogDeleteText = `Essa ação deleta a proposta: Id ${this.proposal.id} do cliente ${this.proposal.client.name}`
    },
    _deleteProposal() {
      this.pageLoading = true
      this.$http.delete(`propostas/${this.proposal.id}/`).then(val => {
        this.dialogDelete = true
        if (val.data.errormessage) {
          this.dialogDeleteTitle = 'ops!'
          this.dialogDeleteText = val.data.errormessage
          this.pageLoading = false
          return
        }
        if (val.data.message) {
          this.dialogDeleteTitle = 'Sucesso!'
          this.dialogDeleteText = val.data.message
          this.pageLoading = false
        }
      }).catch(val => {
        this.dialogDeleteTitle = "Algo saiu errado!"
        this.dialogDeleteText = `${val.data} - Entre em contato com administrador do sistema.`
        this.pageLoading = false
      })
    },
    getCalculatedCoupons(proposalId, proposalPaymentDay) {
      const promoDate = moment('15/02/2024', 'DD/MM/YYYY')
      const paymentDay = moment(proposalPaymentDay, 'DD/MM/YYYY');
      if (promoDate <= paymentDay) {
          this.$http.get(`propostas/calculated_coupons/?proposal_id=${proposalId}`).then((v) => {
          this.coupons = v.data.coupons
        })
      }
    },
    getPromoters() {
      this.$http.get('promotoras').then((v) => {
        for (let i in v.data) {
          this.promoters.push(v.data[i].promotora)
        }
      })
    },
    fixValues(value) {
      // Separando parte inteira da parte decimal
      let partes = parseFloat(value).toFixed(2).split(".");

      // Formatando a parte inteira com separador de milhar
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      // Retornando o número formatado
      return partes.join(",");
    },
    showProposal(object) {
      this.cpfInfo = object.client.cpf
      this.fullName = object.client.name
      this.userName = object.user.account_identification
      this.collectWay = object.formalization_way
      if (object.bank !== null) {
        this.bankName = `${object.bank.code_number} - ${object.bank.bank_name}`
      }
      this.loanType = object.loan_product
      this.loanValue = this.fixValues(object.loan_value)
      this.netValue = this.fixValues(object.net_value)
      this.loanAde = object.ade
      this.situation = object.situation
      this.loanOportunity = object.oportunity
      if (object.complete_90_days_in === null) {
        this.complete90DaysIn = object.complete_90_days_in
      } else {
        this.complete90DaysIn = this.fixDataTimeInformation(object.complete_90_days_in)
      }
      this.followUp = object.follow_up
      this.loanTreatment = object.treatment
      this.saleDate = object.sale_date
      this.phoneUsed = object.used_phone
      this.observation = object.observation
      this.loanStatus = object.status
      this.paymentDate = object.payment_day
      if (object.factor !== null) {
        this.factor = object.factor.replace('.', ',')
      }
      this.promoter = object.promoter
      if (object.rentability !== null) {
        this.rentability = object.rentability.replace('.', ',')
      }
      this.table_loan = object.table_loan
      this.hasInsurance = object.has_insurance
      this.isRepresentative = object.is_representative
      this.generateCoupons = object.generate_coupons
      this.commission = object.commission === true ? 'Sim' : 'Não'
      if (this.commission === 'Sim') {
        this.commissionPayed = true
      } else {
        this.commissionPayed = false
      }
      this.coupon_delivered = object.coupon_delivered === true ? 'Sim' : 'Não'
      if (this.coupon_delivered === 'Sim') {
        this.deliveredCoupons = true
      } else {
        this.deliveredCoupons = false
      }
      if (object.commom_information !== null) {
        this.nameRepresentative = object.commom_information.name_legal_representative
        this.cpfRepresentative = object.commom_information.cpf_legal_representative
        this.loanLock = object.commom_information.loan_lock
        this.species = object.commom_information.species
        if (object.commom_information.portion_value !== null) {
          this.portionValue = this.fixValues(object.commom_information.portion_value)
        }
        this.portionDeadline = object.commom_information.portion_deadline
        if (object.commom_information.portion_bank !== null) {
          this.portionBank = `${object.commom_information.portion_bank.code_number} - ${object.commom_information.portion_bank.bank_name}`
        }
        if (object.commom_information.client_bank !== null) {
          this.clientBank = `${object.commom_information.client_bank.code_number} - ${object.commom_information.client_bank.bank_name}`
        }
        this.numberOfPortionPaid = object.commom_information.number_of_portion_paid

        if (object.commom_information.portion_debit_balance !== null) {
          this.portionDebitBalance = this.fixValues(object.commom_information.portion_debit_balance)
        }

        this.startDiscount = object.commom_information.start_discount
        this.endDiscount = object.commom_information.end_discount
        this.bankAgency = object.commom_information.bank_agency
        this.bankAccount = object.commom_information.bank_account
      }
      this.pageLoading = false
    },
    patchUser() {
      this.loading = true
      this.$http.post(`propostas/update_proposal_user/?proposal_id=${this.proposal.id}`, {
        user: this.userName
      }).then(val => {
        if (val.data.errormessage) {
          this.loading = false
          this.dialogUpdate = true
          this.dialogTitleUpdate = 'Ops!'
          this.dialogMessage = val.data.errormessage
          return
        }
        if (val.data.message) {
          this.loading = false
          this.dialogUpdate = true
          this.dialogTitleUpdate = 'Sucesso!'
          this.dialogMessage = val.data.message
        }
        this.$http.get(`propostas/get_proposal_after_update/?id=${this.proposal.id}`).then((v) => {
        this.proposalUpdated = v.data.proposal
        if (this.proposalUpdated.sale_date) {
            this.proposalUpdated.sale_date = this.fixDataTimeInformation(this.proposalUpdated.sale_date)
        }
        if (this.proposalUpdated.payment_day !== null) {
          this.proposalUpdated.payment_day = this.fixDataTimeInformation(this.proposalUpdated.payment_day)
        }
        this.showProposal(this.proposalUpdated)
        })
      })
    },
    getUsers() {
      this.$http.get('contaswebcred/').then(val => {
        for (let i in val.data) {
          this.users.push(val.data[i].account_identification)
        }
      })
    },
    getStatusTypes() {
      this.$http.get('status/').then(
          (v) => {
            for (let i in v.data) {
              this.statusTypes.push(v.data[i].status)
              }
            this.getSituationTypes()
          }
      )
    },
    getSituationTypes() {
      this.$http.get(`situacoes/?status=${this.loanStatus}`).then((s) => {
        let situations = []
        for (let i in s.data) {
          situations.push(s.data[i].situation)
        }
        this.situationTypes = situations
      })
    },
    fixDataTimeInformation(dateTimeObj) {
      let day = dateTimeObj.slice(8, 10)
      let month = dateTimeObj.slice(5, 7)
      let year = dateTimeObj.slice(0, 4)
      return day + '/' + month + '/' + year
    },
    async saveLoanProposal() {
      await this.validate()
      if (this.valid) {
        this.loading = true
        let params_proposal = {}
        let params_commom = {}
        params_proposal.id_proposal = this.proposal.id
        params_proposal.client = this.cpfInfo
        params_proposal.user = this.userName
        params_proposal.formalization_way = this.collectWay
        params_proposal.bank = this.bankName
        params_proposal.loan_product = this.loanType
        params_proposal.loan_value = this.loanValue
        params_proposal.net_value = this.netValue
        params_proposal.ade = this.loanAde
        params_proposal.situation = this.situation
        params_proposal.complete_90_days_in = this.complete90DaysIn
        params_proposal.follow_up = this.followUp
        params_proposal.treatment = this.loanTreatment
        params_proposal.status = this.loanStatus
        params_proposal.sale_date = this.saleDate
        params_proposal.payment_day = this.paymentDate
        params_proposal.used_phone = this.phoneUsed
        params_proposal.observation = this.observation
        params_proposal.factor = this.factor
        params_proposal.promoter = this.promoter
        params_proposal.rentability = this.rentability
        params_proposal.table_loan = this.table_loan
        params_proposal.has_insurance = this.hasInsurance
        params_proposal.is_representative = this.isRepresentative
        params_proposal.commission = this.commissionPayed
        params_proposal.coupon_delivered = this.deliveredCoupons
        params_commom.name_legal_representative = this.nameRepresentative
        params_commom.cpf_legal_representative = this.cpfRepresentative
        params_commom.grant_date = this.grantDate
        params_commom.loan_lock = this.loanLock
        params_commom.species = this.species
        params_commom.portion_value = this.portionValue
        params_commom.portion_deadline = this.portionDeadline
        params_commom.portion_bank = this.portionBank
        params_commom.number_of_portion_paid = this.numberOfPortionPaid
        params_commom.portion_debit_balance = this.portionDebitBalance
        params_commom.start_discount = this.startDiscount
        params_commom.end_discount = this.endDiscount
        params_commom.client_bank = this.clientBank
        params_commom.bank_agency = this.bankAgency
        params_commom.bank_account = this.bankAccount
        this.$http.post('propostas/update_proposal/', {
          params_commom,
          params_proposal
        }).then(v => {
          this.loading = false
          this.dialogUpdate = true
          this.dialogMessage = v.data.message
          this.dialogTitleUpdate = this.dialogMessage === 'Dados de proposta atualizado com sucesso!' ? 'Sucesso!' : 'Atenção!'
          if (this.dialogMessage === 'Dados de proposta atualizado com sucesso!') {
            this.$http.get(`propostas/get_proposal_after_update/?id=${this.proposal.id}`).then((v) => {
              this.proposalUpdated = v.data.proposal
              if (this.proposalUpdated.sale_date) {
                this.proposalUpdated.sale_date = this.fixDataTimeInformation(this.proposalUpdated.sale_date)
                if (this.proposalUpdated.payment_day !== null) {
                  this.proposalUpdated.payment_day = this.fixDataTimeInformation(this.proposalUpdated.payment_day)
                }
              }
              this.showProposal(this.proposalUpdated)
            })
          }
        }).catch(v => {
          this.dialogUpdate = true
          this.dialogTitleUpdate = 'Erro!!'
          this.dialogMessage = `${v.response.data.error} Contate o administrador do sistema`
        })
      }
    },
    getBanks() {
      this.$http.get('bancos/').then(v => {
        for (let i in v.data) {
          this.banks.push(`${v.data[i].code_number} - ${v.data[i].bank_name}`)
        }
      })
    },
    changeModel(title, value) {
      if (value === 'formalization') {
        this.dataCollectionIterable = this.collectWayTypes
      }
      if (value === 'status') {
        this.dataCollectionIterable = this.statusTypes
      }
      if (value === 'situation') {
        this.dataCollectionIterable = this.situationTypes
      }
      if (value === 'product') {
        this.dataCollectionIterable = this.loanTypes
      }
      this.dialogTitle = title
      this.info = value
      this.dialog = true
    },
    changeModelBank(title, value) {
      this.info = value
      this.dialogBankTitle = title
      this.dialogBank = true
    },
    changeModelDate(title, value) {
      this.info = value
      this.dialogDateTitle = title
      this.dialogDate = true
    },
    closeDialog(item, value) {
      this.dialog = false
      this.dialogBank = false
      this.dialogDate = false
      if (value === 'formalization') {
        this.collectWay = item
      }
      if (value === 'status') {
        this.loanStatus = item
      }
      if (value === 'situation') {
        this.situation = item
      }
      if (value === 'product') {
        this.loanType = item
      }
      if (value === 'loan_bank') {
        this.bankName = item
      }
      if (value === 'client_bank') {
        this.clientBank = item
      }
      if (value === 'portion_bank') {
        this.portionBank = item
      }
      if (value === 'sale_date') {
        this.saleDate = this.fixDataTimeInformation(item)
      }
      if (value === 'grant_date') {
        this.grantDate = this.fixDataTimeInformation(item)
      }
      if (value === 'sale_nine_o') {
        this.complete90DaysIn = this.fixDataTimeInformation(item)
      }
      if (value === 'payment_date') {
        this.paymentDate = this.fixDataTimeInformation(item)
      }
      if (value === 'start_discount') {
        this.startDiscount = this.fixDataTimeInformation(item)
      }
      if (value === 'end_discount') {
        this.endDiscount = this.fixDataTimeInformation(item)
      }
    }
  },
  beforeMount() {
    this.getBanks()
    this.getUsers()
    this.getPromoters()
  },
  async created() {
    if (this.proposal) {
      if (this.proposal.payment_day) {
        await this.getCalculatedCoupons(this.proposal.id, this.proposal.payment_day)
      }
      await this.showProposal(this.proposal)
      this.getStatusTypes()
    }
  },
  watch: {
    loanStatus() {
      this.getSituationTypes()
    },
    hasInsurance() {
      this.insurance = this.hasInsurance ? 'Sim' : 'Não'
    },
    isRepresentative() {
      this.representative = this.isRepresentative ? 'Sim' : 'Não'
    },
    commissionPayed() {
      this.commission = this.commissionPayed ? 'Sim' : 'Não'
    },
    deliveredCoupons() {
      this.coupon_delivered = this.deliveredCoupons ? 'Sim' : 'Não'
    }
  }
}
</script>

<style scoped>
.title-proposal {
  font-size: 1.3em;
  font-weight: 900;
  background-color: rgb(1, 156, 80);
  height: 50px;
  margin-top: 30px;
  padding-top: 10px;
  padding-left: 19px;
  padding-right: 19px;
}

.card-representative {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}

.coupons-to-delivered {
  background: rgb(1, 156, 80);
  padding: 10px 16px;
  border-radius: 70px;
  color: #FFFFFF;
  margin-top: -23px;
  font-weight: 900;
}

</style>