<template>
  <div>
    <div class="sub-title rounded-xl d-flex justify-center align-center">
      <span>{{subtitle}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['subtitle'],
  name: "SubtitleComponent"
}
</script>

<style scoped>
.sub-title {
  background-color: #019c50;
  font-size: 1.4em;
  color: #FFFFFF;
  font-weight: 900;
  padding: 10px 20px 10px 20px;
}
</style>