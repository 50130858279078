<template>
  <div>
    <div class="mt-5">
      <StandardTitle :labeltitle="'ATENDIMENTOS'"></StandardTitle>
    </div>
    <div class="d-flex justify-center align-center">
      <div class="mt-7  " style="width: 300px;">
        <v-autocomplete :items="usersInstances" v-model="searchServiceUser" label="Filtrar por Usuário" rounded outlined></v-autocomplete>
      </div>
    </div>
    <div class="mb-5" style="display: flex; justify-content: center">
      <v-data-table
          style="width: 1200px"
          dense
          :headers="headers"
          :items="services"
          hide-default-footer
          disable-pagination
          class="elevation-4 rounded-xl pa-2"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "ShowServices",
  components: {StandardTitle},
  data: () => ({
    headers: [
      {text: 'USUÁRIO', value: 'user_webcred.account_identification', sortable: false},
      {text: 'BASE', value: 'client_identification.base', sortable: false},
      {text: 'CLIENTE', value: 'client_identification.name', sortable: false},
      {text: 'CPF', value: 'client_identification.cpf', sortable: false},
      {text: 'LOG', value: 'created_at', sortable: false},
    ],
    searchServiceUser: '',
    services: [],
    usersInstances: []
  }),
  methods: {
    getUsers() {
      this.$http.get('contaswebcred/').then(v => {
        for (let i in v.data) {
          this.usersInstances.push(v.data[i].account_identification)
        }
      })
    },
    getServices() {
      this.$http.get(`atendimentos/?user_webcred=${this.searchServiceUser}`).then(v => {
        this.services = v.data
        this.services.map(v => {
          v.created_at = this.fixDataTimeInformation(v.created_at)
          return v
        })
      })
    },
    fixDataTimeInformation(dateTimeObj) {
      let day = dateTimeObj.slice(8, 10)
      let month = dateTimeObj.slice(5, 7)
      let year = dateTimeObj.slice(0, 4)
      let time = dateTimeObj.slice(11, 16)
      return day + '/' + month + '/' + year + ' às: ' + time
    }
  },
  created() {
    this.getServices()
    this.getUsers()
  },
  watch: {
    searchServiceUser() {
      this.getServices()
    }
  }
}
</script>

<style scoped>

</style>