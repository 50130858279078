import { render, staticRenderFns } from "./client_icon.vue?vue&type=template&id=0c120658&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c120658",
  null
  
)

export default component.exports