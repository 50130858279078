<template>
  <div>
    <v-dialog v-model="dialog" transition="dialog-top-transition" scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialogTitle === 'Sucesso!' ? recallComponent() : dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex justify-end">
      <v-btn class="mr-2 mt-2" color="#019c50" style="color: #FFFFFF; text-transform: none" rounded @click="addMeta">
        <span>Adicionar</span>
      </v-btn>
    </div>
    <div class="mt-5">
      <StandardTitle :labeltitle="'Metas'"></StandardTitle>
    </div>
    <div class="d-flex justify-center" v-for="item in salesTargets" :key="item.id">
      <v-card class="mt-4 elevation-2" rounded="xl" min-width="450">
        <v-card-title class="d-flex justify-center align-center" style="background-color: #019c50; line-height: 1">
          <span style="font-size: 0.7em; color: #FFFFFF">META DO MÊS {{ formatDate(item.target_month) }}</span>
        </v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex justify-center align-center">
            <div class="bloco-vendido">
              <span>Vendido: </span>
              <span>R$ {{ formatValue(item.vendido_no_mes) }}</span>
            </div>
            <v-divider class="ml-5 mr-5" vertical></v-divider>
            <div class="bloco-meta">
              <span>Meta: </span>
              <span>R$ {{ formatValue(item.value) }}</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end align-center" style="background-color: #edf8f3;">
          <div>
            <v-icon class="mr-3" color="#FE0000" @click="deleteMetaObject(item.id)">mdi-delete</v-icon>
            <v-icon class="mr-3" color="#019c50" @click="openMetaObject(item.id)">mdi-pencil-box-outline</v-icon>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "ListMetas",
  components: {StandardTitle},
  data: () => ({
    salesTargets: [],
    dialog: false,
    dialogTitle: '',
    dialogMessage: ''
  }),
  methods: {
    addMeta() {
      this.$router.push({name: 'createmeta'})
    },
    recallComponent() {
      this.dialog = false
      this.getMetas()
    },
    deleteMetaObject(value) {
      this.$http.delete(`metas/${value}`).then(() => {
        this.dialog = true
        this.dialogTitle = 'Sucesso!'
        this.dialogMessage = 'Meta excluída com sucesso!'
      }).catch(() => {
        this.dialog = true
        this.dialogTitle = 'Ops!'
        this.dialogMessage = 'Um erro ocorreu na exclusão da meta, contate o adm.'
      })
    },
    openMetaObject(value) {
      this.$router.push({name: 'meta', params: {id: value}})
    },
    formatValue(value) {
      let partes = parseFloat(value).toFixed(2).split(".");
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return partes.join(",");
    },
    formatDate(date) {
      return moment(date).format("MM/YY")
    },
    getMetas() {
      this.$http.get(`metas/`).then(val => {
        this.salesTargets = val.data
      })
    }
  },
  created() {
    this.getMetas()
  }
}
</script>

<style scoped>
.bloco-meta {
  padding: 10px;
  background: #fe0000;
  color: #FFFFFF;
  border-radius: 21px;
  font-weight: 800;
}
.bloco-vendido {
  padding: 10px;
  background: #2a26af;
  color: #FFFFFF;
  border-radius: 21px;
  font-weight: 800;
}
</style>