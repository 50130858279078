<template>
  <div>
    <StandardTitle class="mt-6" :labeltitle="'Dashboard'"></StandardTitle>
    <div class="d-flex justify-center align-center flex-column mt-6">
      <v-dialog v-model="dialogDate" scrollable width="auto" transition="dialog-top-transition">
        <v-card class="rounded-xl">
          <v-card-title>{{ dialogDateTitle }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <v-date-picker locale="pt-br" class="pa-4" color="#019c50" v-model="picker"></v-date-picker>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="closeDialog(picker, info)">
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogMessage" scrollable width="auto" transition="dialog-top-transition">
        <v-card class="rounded-xl">
          <v-card-title>{{ dialogMessageTitle }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto; margin-top: 17px;">
            {{ dialogMessageContent }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" variant="text" @click="dialogMessage = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <div class="d-flex justify-center align-center mt-4" style="margin-bottom: -10px">
      <div class="d-flex justify-center align-content-center" style="width: 180px">
        <v-text-field dense label="Data Inicial" :value="startDate" @change="updateStartDate" outlined rounded v-maska
                        data-maska="##/##/####"></v-text-field>
        <v-icon @click="changeModelDate('Data Inicial', 'start_date')" class="mb-7" size="40"
                color="#019c50" >mdi-calendar-edit
        </v-icon>
      </div>
      <div class="d-flex justify-center align-content-center" style="width: 180px">
        <v-text-field dense label="Data Final" :value="endDate" @change="updateEndDate" outlined rounded v-maska
                        data-maska="##/##/####"></v-text-field>
        <v-icon @click="changeModelDate('Data Final', 'end_date')" class="mb-7" size="40"
                color="#019c50" >mdi-calendar-edit
        </v-icon>
      </div>
    </div>
    <div class="d-flex justify-center align-start mt-3">
      <div class="mr-2" v-if="Sales.length > 0">
        <SubtitleComponent style="width: 320px" :subtitle="'Ranking de Vendas'"></SubtitleComponent>
        <SalesRanking class="mt-2" :daily-sales="Sales"></SalesRanking>
        <div class="total-sector mt-2" v-if="totalSales > 0">
          <span>Total: </span>
          <span class="ml-5">{{ fixAmountValues(totalSales)}}</span>
        </div>
      </div>
      <div class="mr-2 ml-2" v-if="payedSales.length > 0">
        <SubtitleComponent style="width: 320px" :subtitle="'Ranking de Pagos Bruto'"></SubtitleComponent>
        <SalesRanking class="mt-2" :daily-sales="payedSales"></SalesRanking>
        <div class="total-sector mt-2" v-if="totalPayedSales > 0">
          <span>Total: </span>
          <span class="ml-5">{{ fixAmountValues(totalPayedSales)}}</span>
        </div>
      </div>
      <div class="ml-2" v-if="rentabilitySales.length > 0">
        <SubtitleComponent style="width: 320px" :subtitle="'Ranking de Pagos Rentável'"></SubtitleComponent>
        <SalesRanking class="mt-2" :daily-sales="rentabilitySales"></SalesRanking>
        <div class="total-sector mt-2" v-if="totalRentabilitySales > 0">
          <span>Total: </span>
          <span class="ml-5">{{ fixAmountValues(totalRentabilitySales)}}</span>
        </div>
      </div>
      <div v-if="Sales.length === 0 && payedSales.length === 0 && rentabilitySales.length === 0">
        Sem vendas!
      </div>
    </div>
    <div class="d-flex mt-4">
      <div v-if="series.length > 0" class="mt-3">
        <apexchart width="480" type="donut" :options="options" :series="series"></apexchart>
      </div>
      <div>
        <ProjectionGrafics></ProjectionGrafics>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import SubtitleComponent from "@/components/templates/SubtitleComponent.vue";
import StandardTitle from "@/components/templates/StandardTitle.vue";
import SalesRanking from "@/components/main_menu/SalesRanking.vue";
import ProjectionGrafics from "@/components/main_menu/ProjectionGrafics.vue";

export default {
  name: "DashBoard",
  components: {ProjectionGrafics, SalesRanking, StandardTitle, SubtitleComponent},
  data: () => ({
    options: {
      colors: ['#61ff9e', '#35e66e', '#00b03b',  '#007930', '#005b1d'],
      labels: [],
    },
    series: [],
    Sales: [],
    totalSales: 0,
    payedSales: [],
    totalPayedSales: 0,
    rentabilitySales: [],
    totalRentabilitySales: 0,
    dialogDate: false,
    dialogDateTitle: '',
    startDate: '',
    endDate: '',
    picker: new Date().toISOString().substr(0, 10),
    info: '',
    dialogMessageTitle: '',
    dialogMessageContent: '',
    dialogMessage: false,
    users: []
  }),
  methods: {
    fixAmountValues(value) {
      if (value === 0) {
        return 'R$ 0.00'
      }
      let newValue = String(value)
      newValue = newValue.replace(/\./g, '');
      let lenghValue = newValue.length
      let returnValue = ''
      if (lenghValue === 3) {
        returnValue = `R$ ${String(newValue).slice(0, 1)}.${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 4) {
        returnValue = `R$ ${String(newValue).slice(0, 2)}.${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 5) {
        returnValue = `R$ ${String(newValue).slice(0, 3)}.${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 6) {
        returnValue = `R$ ${String(newValue).slice(0, 1)}.${String(newValue).slice(1, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 7) {
        returnValue = `R$ ${String(newValue).slice(0, 2)}.${String(newValue).slice(2, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 8) {
        returnValue = `R$ ${String(newValue).slice(0, 3)}.${String(newValue).slice(3, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 9) {
        returnValue = `R$ ${String(newValue).slice(0, 1)}.${String(newValue).slice(1, 4)}.${String(newValue).slice(4, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      if (lenghValue === 10) {
        returnValue = `R$ ${String(newValue).slice(0, 2)}.${String(newValue).slice(2, 5)}.${String(newValue).slice(5, lenghValue - 2)},${String(newValue).slice(lenghValue - 2, lenghValue + 1)}`
      }
      return returnValue
    },
    getProposalsDaily() {
      this.$http.post('propostas/get_sales_ranking_daily/', {
        start_date: this.startDate,
        end_date: this.endDate
      }).then((v) => {
        if (v.data.message) {
          this.dialogMessage = true;
          this.dialogMessageTitle = 'Atenção!';
          this.dialogMessageContent = v.data.message;
        }
        if (v.data.sales) {
          this.Sales = v.data.sales

          for (let i in v.data.total_per_user) {
            this.options.labels.push(v.data.total_per_user[i].user__account_identification)
            this.series.push(parseFloat(v.data.total_per_user[i].total_vendas))
          }

          for (let i in this.Sales) {
            this.totalSales = (parseFloat(this.totalSales) + parseFloat(this.Sales[i].amount_value)).toFixed(2)
          }

          this.payedSales = v.data.payed_sales
          for (let i in this.payedSales) {
            this.totalPayedSales = (parseFloat(this.totalPayedSales) + parseFloat(this.payedSales[i].amount_value)).toFixed(2)
          }

          this.rentabilitySales = v.data.rentability_sales
          for (let i in this.rentabilitySales) {
            this.totalRentabilitySales = (parseFloat(this.totalRentabilitySales) + parseFloat(this.rentabilitySales[i].amount_value)).toFixed(2)
          }

          this.Sales.map(s => {
            s.amount_value = this.fixAmountValues(s.amount_value)
            return s
          })
          this.payedSales.map(s => {
            s.amount_value = this.fixAmountValues(s.amount_value)
            return s
          })
          this.rentabilitySales.map(s => {
            s.amount_value = this.fixAmountValues(s.amount_value)
            return s
          })
        }
      })
    },
    changeModelDate(title, value) {
      this.totalSales = 0
      this.totalPayedSales = 0
      this.totalRentabilitySales = 0
      this.options.labels = []
      this.series = []
      this.info = value
      this.dialogDateTitle = title
      this.dialogDate = true
    },
    closeDialog(item, value) {
      this.dialogDate = false
      if (value === 'start_date') {
        this.startDate = this.fixDataTimeInformation(item)
        this.getProposalsDaily()
      }
      if (value === 'end_date') {
        this.endDate = this.fixDataTimeInformation(item)
        this.getProposalsDaily()
      }
    },
    fixDataTimeInformation(dateTimeObj) {
      let day = dateTimeObj.slice(8, 10)
      let month = dateTimeObj.slice(5, 7)
      let year = dateTimeObj.slice(0, 4)
      return day + '/' + month + '/' + year
    },
    updateStartDate(event) {
      this.startDate = event
      this.getProposalsDaily()
    },
    updateEndDate(event) {
      this.endDate = event
      this.getProposalsDaily()
    },
  },
  created() {
    this.getProposalsDaily()
  },
}
</script>

<style scoped>
.total-sector {
  display: flex;
  justify-content: center;
  background: #019c50;
  color: #FFFFFF;
  font-size: 1.3em;
  border-radius: 50px;
  padding: 5px;
  font-weight: 800;
}
</style>