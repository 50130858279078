<template>
  <div>
    <div class="d-flex justify-end align-center">
      <div class="mt-5 mr-2">
        <v-btn color="#019c50" @click="writeProposal" rounded style="color: #FFFFFF; text-transform: none">
          <div>
              <h5>Digitar Proposta Pra esse Cliente</h5>
            </div>
            <div>
              <v-icon class="ml-2" style="font-size: 2em; color: #FFFFFF">mdi-text-box-edit-outline</v-icon>
            </div>
        </v-btn>
      </div>
      <div v-if="clientAllData === false" class="mt-5 mr-2" style="display: flex; justify-content: flex-end">
        <div>
          <v-btn color="#019c50" @click="clientAllData = true" rounded style="color: #FFFFFF; text-transform: none">
            <div>
              <h5>Ver Todas as Informações</h5>
            </div>
            <div>
              <v-icon class="ml-2" style="font-size: 2em; color: #FFFFFF">mdi-format-align-justify</v-icon>
            </div>
          </v-btn>
        </div>
      </div>
      <div v-else class="mt-5 mr-2" style="display: flex; justify-content: flex-end">
        <div>
          <v-btn color="#019c50" @click="clientAllData = false" rounded style="color: #FFFFFF; text-transform: none">
            <div>
              <h5>Ver Informações Resumidas</h5>
            </div>
            <div>
              <v-icon class="ml-2" style="font-size: 2em; color: #FFFFFF">mdi-format-list-bulleted</v-icon>
            </div>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mt-10 mb-10">
      <StandardTitle :labeltitle="'Visualizar Cliente'" ></StandardTitle>
    </div>

    <div v-if="clientAllData === false">
      <FieldsClientes :client_info="_client"></FieldsClientes>
    </div>
    <div v-else>
      <AllFieldsClientes :client_info="_client"></AllFieldsClientes>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";
import FieldsClientes from "@/components/clients/FieldsClientes.vue";
import AllFieldsClientes from "@/components/clients/AllFieldsClientes.vue";
export default {
  name: "ShowClientToConsultant",
  props: ['client'],
  data: () => ({
    clientAllData: false
  }),
  methods: {
    writeProposal() {
      this.$router.push({name: 'writeproposal', params: {'cliente': this.client}})
    }
  },
  computed: {
    _client: {
      get() {
        return JSON.parse(localStorage.getItem('client'));
      },
      set(val) {
        localStorage.setItem('client', JSON.stringify(val));
      }
    }
  },
  components: {FieldsClientes, StandardTitle, AllFieldsClientes, },
  created() {
    if (this.client) {
      this._client = this.client
    }
  }
}
</script>

<style scoped>

</style>