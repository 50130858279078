import { render, staticRenderFns } from "./ViewLogs.vue?vue&type=template&id=08ba009c&scoped=true&"
import script from "./ViewLogs.vue?vue&type=script&lang=js&"
export * from "./ViewLogs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08ba009c",
  null
  
)

export default component.exports