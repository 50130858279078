<template>
  <div>
    <div class="mt-6">
      <StandardTitle :labeltitle="'LOGS'"></StandardTitle>
    </div>
    <div class="d-flex justify-center align-center">
      <div class="log-id mt-6">
        <span>Log id: {{ log.id }}</span>
      </div>
    </div>
    <div class="pa-10">
      <div>
        <v-text-field dense rounded outlined v-model="userName" label="Usuário"></v-text-field>
      </div>
      <div>
        <v-text-field dense rounded outlined v-model="modelReference" label="Modelo"></v-text-field>
      </div>
      <div>
        <v-text-field dense rounded outlined v-model="action" label="Ação"></v-text-field>
      </div>
      <div>
        <v-text-field dense rounded outlined v-model="objectID" label="Id do Objeto"></v-text-field>
      </div>
      <div>
        <v-textarea dense rounded outlined v-model="originObject" label="Objeto de Origem"></v-textarea>
      </div>
      <div>
        <v-textarea dense rounded outlined v-model="chagedObject" label="Objeto de Novo"></v-textarea>
      </div>
      <div>
        <v-textarea dense rounded outlined v-model="observation" label="Campos Alterados"></v-textarea>
      </div>
      <div>
        <v-text-field dense rounded outlined v-model="createDate" label="Data de Criação"></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "FieldsLogs",
  components: {StandardTitle},
  data: () => ({
    log: undefined,
    userName: '',
    modelReference: '',
    action: '',
    objectID: '',
    originObject: '',
    chagedObject: '',
    observation: '',
    createDate: ''
  }),
  methods: {
    async getLog() {
      await this.$http.get(`logs/get_log?id=${this.$attrs.id}`).then((v) => {
        this.log = v.data.log
        this.userName = this.log.user.account_identification
        this.modelReference = this.log.model_reference
        this.action = this.log.action
        this.objectID = this.log.object_id
        this.originObject = this.log.origin_object
        this.chagedObject = this.log.changed_object
        this.observation = this.log.observation
        this.createDate = this.fixDataTimeInformation(this.log.created_at)
      })
    },
    fixDataTimeInformation(dateTimeObj) {
      let day = dateTimeObj.slice(8, 10)
      let month = dateTimeObj.slice(5, 7)
      let year = dateTimeObj.slice(0, 4)
      let time = dateTimeObj.slice(11, 16)
      return day + '/' + month + '/' + year + ' às: ' + time
    },
  },
  activated() {
    this.getLog()
  }
}
</script>

<style scoped>
.log-id {
  background: #019c50;
  padding: 10px;
  border-radius: 44px;
  color: #FFFFFF;
  font-size: 1.2em;
  font-weight: 900;
}
</style>