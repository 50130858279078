<template>
  <div>
    <div class="mt-6">
      <StandardTitle :labeltitle="'Informações da Proposta'"></StandardTitle>
    </div>
    <div>
      <FieldsProposal :proposal="_proposal"></FieldsProposal>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";
import FieldsProposal from "@/components/proposals/FieldsProposal.vue";

export default {
  name: "EditProposals",
  components: {FieldsProposal, StandardTitle},
  props: ['proposal'],
  computed: {
    _proposal: {
      get() {
        return JSON.parse(localStorage.getItem('proposal'));
      },
      set(val) {
        localStorage.setItem('proposal', JSON.stringify(val));
      }
    }
  },
  created() {
    if (this.proposal) {
      this._proposal = this.proposal
    }
  },
  deactivated() {
    this.$destroy()
  }
}
</script>

<style scoped>

</style>