import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import {vMaska} from "maska"
import VueWorker from 'vue-worker'
import VueApexCharts from 'vue-apexcharts'
import money from 'v-money'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueWorker)
Vue.use(money, {precision: 4})

Vue.filter('fixDate', (value) => {
        if (value !== undefined && value !== null) {
            if (value !== undefined && value !== null) {
                const [year, month, day] = value.substring(0, 10).split('-');
                return `${day}/${month}/${year}`
            }
        }
    }
);

Vue.filter('shortDate', (value) => {
        if (value !== undefined && value !== null) {
            if (value !== undefined && value !== null) {
                const [year, month] = value.substring(0, 10).split('-');
                return `${month}/${year}`
            }
        }
    }
);

Vue.filter('fixValues', (value) => {
    let partes = parseFloat(value).toFixed(2).split(".");
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return partes.join(",");
})

Vue.config.productionTip = false
Vue.directive("maska", vMaska)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

