<template>
    <router-view/>
</template>

<script>
export default {
  name: "ViewClients"
}
</script>

<style scoped>

</style>