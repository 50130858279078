<template>
  <v-app>
   <v-main>
     <div id="app">
       <HeaderApplication v-if="$route.fullPath !== '/'"></HeaderApplication>
       <router-view/>
     </div>
    </v-main>
  </v-app>
</template>

<script>
import HeaderApplication from "@/components/templates/HeaderApplication";

export default {
  name: 'App',
  components: {
    HeaderApplication,
  },
  beforeCreate() {
    this.$store.dispatch('setUser')
  },
  data: () => ({
    //
  }),
  created() {
  }
};

</script>

<style scoped>

</style>
