<template>
  <keep-alive max="3">
    <router-view/>
  </keep-alive>
</template>

<script>
export default {
  name: "ViewLogs"
}
</script>

<style scoped>

</style>