<template>
  <div>
    <div class="buttons-add d-flex justify-end">
      <v-btn color="#019c50" @click="addWebcredUser" style="color: #FFFFFF; text-transform: none" rounded>
        <div>
          <span>Adicionar</span>
        </div>
        <div>
          <v-icon class="ml-2" style="font-size: 2em; color: #FFFFFF">mdi-account-plus-outline</v-icon>
        </div>
      </v-btn>
      </div>
    <div class="mt-2 mb-5">
      <StandardTitle :labeltitle="'Usuários'"></StandardTitle>
    </div>
    <div class="d-flex justify-center align-center">
      <v-data-table
        :headers="headers"
        :items="users"
        hide-default-footer
        item-key="id"
        @click:row="openUser"
        class="elevation-4 rounded-xl px-4 py-1">
      </v-data-table>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "ListWebcredUser",
  components: {StandardTitle},
  data: () => ({
    headers: [
      { text: 'USUÁRIO', value: 'system_user.username', sortable: false },
      { text: 'NOME DA CONTA', value: 'account_identification', sortable: false },
      { text: 'CATEGORIA', value: 'user_category', sortable: false },
      { text: 'EMPRESA', value: 'system_partner.partner', sortable: false }
    ],
    users: []
  }),
  methods: {
    addWebcredUser() {
      this.$router.push({name: 'createuser'})
    },
    openUser(value) {
      this.$router.push({name: 'user', params: {id: value.id}})
    },
    getUsers() {
      this.$http.get('contaswebcred').then(val => {
        this.users = val.data
      })
    }
  },
  activated() {
    this.getUsers()
  }
}
</script>

<style scoped>
.buttons-add {
  padding: 10px;
}
</style>