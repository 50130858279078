<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
    <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else>
    <div class="mt-5">
      <StandardTitle :labeltitle="'Fatura'"></StandardTitle>
    </div>
    <div class="d-flex justify-center align-center">
      <div class="title-fatura rounded-pill">
        <span>Fatura: {{$attrs.id}} </span>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div style="width: 50%" class="mt-5" @click="downloadFatura">
        <v-img :src="faturaImage"></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "EditInvoice",
  components: {StandardTitle},
  data: () => ({
    pageLoading: true,
    faturaImage: undefined
  }),
  methods: {
    downloadFatura() {
      const link = document.createElement('a');
      link.href = this.faturaImage;
      link.download = 'fatura.jpg';
      link.click();
    },
    getFatura() {
      this.$http.get(`faturas/${this.$attrs.id}`).then(val => {
        this.faturaImage = val.data.invoice_image
        this.pageLoading = false
      }).catch(() => {
        this.pageLoading = false
      })
    }
  },
  created() {
    this.getFatura()
  }
}
</script>

<style scoped>
.title-fatura {
  font-size: 1.3em;
  font-weight: 900;
  background-color: rgb(1, 156, 80);
  height: 50px;
  margin-top: 30px;
  padding-top: 10px;
  padding-left: 19px;
  padding-right: 19px;
  color: #FFFFFF;
}
</style>