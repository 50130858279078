<template>
  <div v-if="pageLoading" style="position: absolute; inset: 47%">
     <v-progress-circular color="#019c50" width="20" indeterminate size="100"></v-progress-circular>
  </div>
  <div v-else>
    <v-dialog v-model="dialog" transition="dialog-top-transition" persistent scrollable width="auto">
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="display: flex; justify-content: center; text-align: justify;">
              <div style="font-size: 1.2em; padding: 10px; margin-top: 15px; width: 400px; text-align-last: center;">
                {{ dialogMessage }}
              </div>
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="dialogTitle === 'Sucesso!' ? $router.push({name: 'users'}) : dialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="mt-5 mb-5">
      <StandardTitle :labeltitle="'Criar Usuário'"></StandardTitle>
    </div>
    <div class="d-flex justify-center align-center mt-10">
      <div style="width: 350px">
        <div>
          <v-form v-model="formValid" ref="form" @submit.prevent :lazy-validation="false">
            <div class="d-flex">
              <v-text-field dense rounded outlined v-model="userName" :rules="userNameRules" hint="suaconta" label="Usuário"></v-text-field>
              <span style="opacity: 50%; font-size: 0.8em; margin-top: 8px; margin-left: 3px">@webcred.com.br</span>
            </div>
            <v-text-field v-model="password" :rules="passwordRules" dense rounded outlined label="Senha" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>
            <v-text-field v-model="confirmPassword" :rules="[...passwordRules, v => v === password || 'As senhas não correspondem']" dense rounded outlined label="Confirmar Senha" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showConfirmPassword ? 'text' : 'password'" @click:append="showConfirmPassword = !showConfirmPassword"></v-text-field>
            <v-select dense rounded outlined v-model="category" :items="categories" :rules="categoryRules" label="Perfil"></v-select>
            <v-text-field dense rounded outlined v-model="email" :rules="emailRules" label="Email"></v-text-field>
            <v-text-field dense rounded outlined v-model="firstName" :rules="firstNameRules" label="Nome da Conta"></v-text-field>
            <v-file-input v dense rounded outlined label="Foto" v-model="photo"></v-file-input>
          </v-form>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center align-center">
      <v-btn color="#019c50" @click="createWebcredUser" style="color: #FFFFFF; text-transform: none" rounded :loading="loading">Salvar</v-btn>
    </div>
  </div>
</template>

<script>
import StandardTitle from "@/components/templates/StandardTitle.vue";

export default {
  name: "CreateWebcredUser",
  components: {StandardTitle},
  data: () => ({
    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    pageLoading: true,
    loading: false,
    formValid: false,
    userName: '',
    userNameRules: [
       v => !!v || 'O usuário é obrigatório',
    ],
    email: '',
    emailRules: [
        v => !!v || 'O email é obrigatório',
        v => /.+@.+\..+/.test(v) || 'O email deve ser válido',
      ],
    category: '',
    categoryRules: [
        v => !!v || 'A categoria do usuário é obrigatória.',
    ],
    categories: ['Administrador', 'Supervisor', 'Consultor'],
    firstName: '',
    firstNameRules: [
       v => !!v || 'Nome e sobrenome são obrigatórios',
    ],
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
    passwordRules: [
      v => !!v || 'As senhas são obrigatórias',
      v => (v && v.length >= 8) || 'A senha deve ter pelo menos 8 caracteres',
      v => /[!@#$%^&*(),.?":{}|<>]/.test(v) || 'A senha deve conter pelo menos um caractere especial',
      v => /[a-z]/.test(v) && /[A-Z]/.test(v) || 'A senha deve conter pelo menos uma letra maiúscula e uma letra minúscula',
      v => /\d/.test(v) || 'A senha deve conter pelo menos um número',
      v => !/\s/.test(v) || 'A senha não pode conter espaços em branco',
    ],
    photo: null
  }),
  methods: {
    createWebcredUser() {
      this.pageLoading = true
      this.$http.post('contaswebcred/', {
        username: this.userName,
        password: this.password,
        confirm_password: this.confirmPassword,
        user_category: this.category,
        email: this.email,
        first_name: this.firstName,
        user_photo: this.photo
      }).then(val => {
        console.log(val)
        this.pageLoading = false
        this.dialog = true
        if (val.data.errormessage) {
          this.dialogTitle = "Ops!"
          this.dialogMessage = val.data.errormessage
          return
        }
        if (val.data.message) {
          this.dialogTitle = "Sucesso!"
          this.dialogMessage = val.data.message
        }
      }).catch(val => {
        this.dialog = true
        this.dialogTitle = "Algo saiu errado!"
        this.dialogMessage = `${val.data} - Entre em contato com administrador do sistema.`
      })
    }
  },
  created() {
    this.pageLoading = false
  }
}
</script>

<style scoped>

</style>