<template>
  <div>
    <v-dialog v-model="dialog"
    transition="dialog-top-transition"
    scrollable width="auto"
    persistent
    >
      <v-card class="rounded-xl">
        <v-card-title>{{ dialogTitle }} </v-card-title>
        <v-divider></v-divider>
          <v-card-text style="height: auto; width: auto">
            <div style="font-size: 1.2em; padding: 10px; margin-top: 15px">
              {{ dialogMessage }}
            </div>
          </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center align-center pa-5">
          <v-btn style="color: #FFFFFF; text-transform: none" small rounded color="#019c50" :loading="loading" variant="text" @click="dialog = false; loading = true">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex justify-center align-center">
      <div style="width: 50%">
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Base: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="baseName" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-4">
              <h3>Cliente Siape? </h3>
            </v-col>
            <v-col cols="4" md="7">
              <div class="mb-3" style="margin-top: -15px">
                <v-radio-group rounded outlined dense v-model="isSiape">
                  <v-radio
                      label="Sim"
                      :value="true"
                  ></v-radio>
                  <v-radio
                      label="Não"
                      :value="false"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="fullName" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>CPF: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="###.###.###-##"
                            dense v-model="clientCpf"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Logradouro: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientAdress" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Numero: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="#"
                            data-maska-tokens="#:[0-9]:multiple"
                            dense v-model="clientAdressNumber" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Bairro: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientNeighbor" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Complemento: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientComplement" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Cidade: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientCity" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>UF: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientState" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>CEP: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="##.###-###"
                            dense v-model="clientPostCode"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Data de Nascimento: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined
                            v-maska
                            data-maska="##/##/####"
                            dense v-model="birthday"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome do Pai: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="fatherName" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Nome da Mãe: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="motherName" @input="convertToUpperCase"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>RG: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="registrationId"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Telefones: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-textarea height="100" rounded outlined dense v-model="phoneNumbers"></v-textarea>
            </v-col>
          </v-row>
        </div>
        <v-divider class="mb-2"></v-divider>
        <div class="space-between-fields">
          <v-row>
            <v-col class="mt-1">
              <h3>Email: </h3>
            </v-col>
            <v-col cols="4" md="7">
              <v-text-field rounded outlined dense v-model="clientEmail"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-center align-center mt-3 mb-4">
          <div>
            <v-btn rounded color="#019c50" @click="saveClient" :loading="btnLoading" style="color: #FFFFFF; text-transform: none">Salvar</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateClient",
  data: () => ({
    baseName: '',
    fullName: '',
    clientCpf: '',
    clientAdress: '',
    clientAdressNumber: '',
    clientNeighbor: '',
    clientComplement: '',
    clientCity: '',
    clientState: '',
    clientPostCode: '',
    birthday: '',
    fatherName: '',
    motherName: '',
    registrationId: '',
    phoneNumbers: '',
    clientEmail: '',
    isSiape: false,

    dialog: false,
    dialogTitle: '',
    dialogMessage: '',
    loading: false,
    btnLoading: false
  }),
  watch: {
    dialog() {
      if (!this.dialog) {
        if (this.dialogTitle === 'Sucesso!') {
           location.reload()
        }
        this.loading = false
      }
    }
  },
  methods: {
    convertToUpperCase() {
      this.baseName = this.baseName.toUpperCase();
      this.fullName = this.fullName.toUpperCase();
      this.clientAdress = this.clientAdress.toUpperCase();
      this.clientNeighbor = this.clientNeighbor.toUpperCase();
      this.clientComplement = this.clientComplement.toUpperCase();
      this.clientCity = this.clientCity.toUpperCase();
      this.clientState = this.clientState.toUpperCase();
      this.fatherName = this.fatherName.toUpperCase();
      this.motherName = this.motherName.toUpperCase();
    },
    saveClient() {
      this.btnLoading = true
      this.$http.post('clientes/create_new_client/', {
        base: this.baseName,
        name: this.fullName,
        cpf: this.clientCpf,
        birthday: this.birthday,
        father_name: this.fatherName,
        mother_name: this.motherName,
        registry: this.registrationId,
        street_avenue_platter: this.clientAdress,
        number: this.clientAdressNumber,
        neighbor: this.clientNeighbor,
        complement: this.clientComplement,
        city: this.clientCity,
        state: this.clientState,
        post_code: this.clientPostCode,
        email: this.clientEmail,
        phone: this.phoneNumbers,
        is_siape: this.isSiape
      }).then(v => {
        this.dialog = true
        this.dialogTitle = 'Atenção!'
        this.dialogMessage = v.data.message
        if (this.dialogMessage === 'Cliente adicionado com sucesso!') {
          this.dialogTitle = 'Sucesso!'
        }
      }).catch((v) => {
        this.dialog = true
        this.dialogTitle = 'Erro!'
        this.dialogMessage = `${v.response.data.error} Contate o administrador do sistema`
      })
      this.btnLoading = false
    }
  },
}
</script>

<style scoped>
.space-between-fields {
  margin-bottom: -28px;
}
</style>