import { render, staticRenderFns } from "./ViewBenefits.vue?vue&type=template&id=f7e2dfba&scoped=true&"
import script from "./ViewBenefits.vue?vue&type=script&lang=js&"
export * from "./ViewBenefits.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7e2dfba",
  null
  
)

export default component.exports